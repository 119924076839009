import React, { useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker, SelectPicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { colors, Slider } from "@mui/material";
import useGoogleMapsScript from "../components/useGoogleMapsScript";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faBraille,
  faClock,
  faEnvelope,
  faLocationDot,
  faMoneyBill1Wave,
  faPhone,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";
import { BookingForm } from "../components/BookingForm";
import Fancybox from "../components/fancybox-library/Fancybox";
import {
  Drawer,
  RadioGroup,
  Radio,
  ButtonToolbar,
  Button,
  IconButton,
  Placeholder,
} from "rsuite";
import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";
import AngleLeftIcon from "@rsuite/icons/legacy/AngleLeft";
import AngleDownIcon from "@rsuite/icons/legacy/AngleDown";
import AngleUpIcon from "@rsuite/icons/legacy/AngleUp";
const CruisesDetails = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const contentRef = useRef(null);
  const RadioLabel = ({ children }) => (
    <label style={{ padding: 7 }}>{children}</label>
  );
  useEffect(() => {
    if (contentRef.current.scrollHeight > contentRef.current.clientHeight) {
      setShowButton(true); // If the text is more than 2 lines, show the button
    }
  }, []);

  const toggleText = () => {
    setIsExpanded((prev) => !prev);
  };

  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState("right");

  const handleOpen = () => {
    setOpen(true);
  };

  const [selectedValue, setSelectedValue] = useState(null);

  const data = [
    { label: "1 (US$ 3,120)", value: 1 },
    { label: "2 (US$ 6,120)", value: 2 },
    { label: "3 (US$ 9,120)", value: 3 },
  ];

  const handleSelectChange = (value) => {
    setSelectedValue(value); // Store the selected value
  };
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const accordionData = [
    {
      title: "Day One",
      description: "Stop: 60 minutes - included",
      image: "/titsolutions/img/lists/tour/single/2.png",
      content:
        "Our first stop is Windsor Castle, the ancestral home of the British Royal family for more than 900 years, and the largest, continuously occupied castle in Europe.",
    },
    {
      title: "Day Two",
      description: "Stop: 60 minutes - included",
      image: "/titsolutions/img/lists/tour/single/2.png",
      content:
        "St. George's Chapel is one of the most beautiful religious structures in England, known for its gothic architecture.",
    },
    {
      title: "Day Three",
      description: "Stop: 60 minutes - included",
      image: "/titsolutions/img/lists/tour/single/2.png",
      content:
        "Explore the fascinating history of The Roman Baths, an ancient thermal spa built by the Romans nearly 2,000 years ago.",
    },
    {
      title: "Day Four",
      description: "Stop: 60 minutes - included",
      image: "/titsolutions/img/lists/tour/single/2.png",
      content:
        "Visit the prehistoric monument Stonehenge, which has mystified visitors for centuries with its massive standing stones.",
    },
  ];
  // Store which accordion item is currently open
  const [activeFaq, setActiveFaq] = useState(null);

  // Function to toggle accordion item
  const toggleAccordion = (index) => {
    setActiveFaq(activeFaq === index ? null : index);
  };
  const faqItems = [
    {
      question: "What do I need to hire a car?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "How old do I have to be to rent a car?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Can I book a rental car for someone else?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "How do I find the cheapest car hire deal?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "What should I look for when choosing a car hire?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];
  return (
    <>
      {" "}
      <Helmet>
        <title>Cruise Details</title>{" "}
        <style>
          {`
            .header .header-menu .menu li a{
            color:#051036;
            }
            .header .header-menu .menu .subnav>li>a {
            color:#051036 !important;
            }
            .is-sticky.bg-dark-1{
            background-color:#fff !important;
            }
            // .is-sticky .menu li a{
            // color:#fff !important;
            // }
            .ratio-15\:9::before{
            padding-bottom:0 !important;}
            `}
        </style>
      </Helmet>
      {/*banner bg-light-2 */}
      <section className="d-flex items-center  mt-90  ">
        <div className="container  mt-40 py-20 border-top-light ">
          <div className="row y-gap-10 items-center justify-between">
            <div className="col-auto">
              <div className="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div className="col-auto">
                  <div className="">
                    <Link to="/">Home</Link>{" "}
                  </div>
                </div>
                <div className="col-auto">
                  <div className="">{">"} </div>
                </div>
                <div className="col-auto">
                  <div className="">
                    <Link to="/booking-cruises">Cruises</Link>{" "}
                  </div>
                </div>
                <div className="col-auto">
                  <div className="">{">"} </div>
                </div>
                <div className="col-auto">
                  <div className="">Cruise Details </div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              Quick Contact:{" "}
              <Link to="tel:+201000972210" className="text-14 text-light-1">
                +(20) 10 00972210{" "}
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* booking engine  */}
      <div className="py-20 bg-light-2">
        <div className="container">
          {" "}
          <BookingForm />
        </div>
      </div>
      <section className="pt-40">
        <div className="container">
          <div className="row y-gap-15 justify-between items-end">
            <div className="col-auto">
              <h1 className="text-30 fw-600">Suntimes Nile Cruise </h1>

              <div className="row x-gap-20 y-gap-20 items-center pt-10">
                <div className="col-auto">
                  <div className="d-flex items-center">
                    <div className="d-flex x-gap-5 items-center">
                      <i className="icon-star text-10 text-yellow-1"></i>

                      <i className="icon-star text-10 text-yellow-1"></i>

                      <i className="icon-star text-10 text-yellow-1"></i>

                      <i className="icon-star text-10 text-yellow-1"></i>

                      <i className="icon-star text-10 text-yellow-1"></i>
                    </div>

                    <div className="text-14 text-light-1 ml-10">
                      3,014 reviews{" "}
                    </div>
                  </div>
                </div>

                <div className="col-auto">
                  <div className="row x-gap-10 items-center">
                    <div className="col-auto">
                      <div className="d-flex x-gap-5 items-center">
                        <i className="icon-placeholder text-16 text-light-1"></i>
                        <div className="text-15 text-light-1">
                          Aswan - Luxor{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="row x-gap-10 items-center">
                    <div className="col-auto">
                      <div className="d-flex x-gap-5 items-center">
                        <FontAwesomeIcon icon={faUsersViewfinder} />
                        <div className="text-15 text-light-1">
                          20 cabin 10 suites{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              <div className="row x-gap-10 y-gap-10">
                <div className="col-auto">
                  <button className="button px-15 py-10 -blue-1">
                    <i className="icon-share mr-10"></i>
                    Share
                  </button>
                </div>

                <div className="col-auto">
                  <button className="button px-15 py-10 -blue-1 bg-light-2">
                    <i className="icon-heart mr-10"></i>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-40 js-pin-container">
        <div className="container">
          <div className="row y-gap-30">
            <div className="col-lg-8">
              <div className="relative d-flex justify-center overflow-hidden gallery">
                <Swiper
                  modules={[Navigation]}
                  slidesPerView={1} // Equivalent to `data-slider-cols="base-1"`
                  navigation={{
                    prevEl: ".js-img-prev",
                    nextEl: ".js-img-next",
                  }}
                  className="swiper-wrapper"
                >
                  {/* Swiper slides */}
                  <SwiperSlide>
                    <img
                      src="/titsolutions/img/lists/tour/single/1.png"
                      alt="image"
                      className="rounded-4 col-12 h-full object-cover"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/titsolutions/img/gallery/1/2.png"
                      alt="image"
                      className="rounded-4 col-12 h-full object-cover"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/titsolutions/img/gallery/1/3.png"
                      alt="image"
                      className="rounded-4 col-12 h-full object-cover"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/titsolutions/img/gallery/1/4.png"
                      alt="image"
                      className="rounded-4 col-12 h-full object-cover"
                    />
                  </SwiperSlide>
                </Swiper>

                {/* Navigation Buttons */}
                <div className="absolute h-100 col-11">
                  <button className="section-slider-nav -prev flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-img-prev">
                    <i className="icon icon-chevron-left text-12"></i>
                  </button>
                  <button className="section-slider-nav -next flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-img-next">
                    <i className="icon icon-chevron-right text-12"></i>
                  </button>
                </div>

                {/* Gallery Link */}
                <div className="absolute h-full col-12 z-2 px-20 py-20 d-flex justify-end items-end">
                  <Fancybox>
                    <a
                      href="/titsolutions/img/lists/tour/single/1.png"
                      className="button -blue-1 px-24 py-15 bg-white text-dark-1"
                      data-fancybox="gallery2"
                    >
                      See All Photos
                    </a>
                    {/* Hidden links for other gallery images */}
                    <a
                      href="/titsolutions/img/gallery/1/2.png"
                      className="d-none"
                      data-fancybox="gallery2"
                    ></a>
                    <a
                      href="/titsolutions/img/gallery/1/3.png"
                      className="d-none"
                      data-fancybox="gallery2"
                    ></a>
                    <a
                      href="/titsolutions/img/gallery/1/4.png"
                      className="d-none"
                      data-fancybox="gallery2"
                    ></a>
                    <a
                      href="/titsolutions/img/gallery/1/5.png"
                      className="d-none"
                      data-fancybox="gallery2"
                    ></a>
                  </Fancybox>
                </div>
              </div>

              <h3 className="text-22 fw-500 mt-30">Highlights</h3>

              <div className="row y-gap-30 justify-between pt-20">
                <div className="col-md-auto col-6">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon={faClock}
                      className="text-22 text-blue-1 mr-10"
                    />
                    <div className="text-15 lh-15">
                      Duration: <br /> 8 Days / 7 Nights
                    </div>
                  </div>
                </div>

                <div className="col-md-auto col-6">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon={faBraille}
                      className="text-22 text-blue-1 mr-10"
                    />
                    <div className="text-15 lh-15">
                      Decks: <br /> 5 Luxurious Decks
                    </div>
                  </div>
                </div>

                <div className="col-md-auto col-6">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon={faMoneyBill1Wave}
                      className="text-22 text-blue-1 mr-10"
                    />
                    <div className="text-15 lh-15">
                      Price: <br /> From 80$
                    </div>
                  </div>
                </div>

                <div className="col-md-auto col-6">
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon={faBan}
                      className="text-22 text-blue-1 mr-10"
                    />
                    <div className="text-15 lh-15 ">
                      Free cancellation <br />
                      <Link to="#." className="text-blue-1 underline">
                        Learn more{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-top-light mt-40 mb-40"></div>

              <div className="row x-gap-40 y-gap-40">
                <div className="col-12">
                  <h3 className="text-22 fw-500">Overview </h3>

                  {/* Text content */}
                  <p
                    className={`text-dark-1 text-15 mt-20 ${
                      isExpanded ? "expanded" : "collapsed"
                    }`}
                    ref={contentRef}
                    style={{
                      maxHeight: isExpanded ? "none" : "3em", // 2 lines height (adjust based on line height)
                      overflow: "hidden",
                      lineHeight: "1.5em", // Adjust based on your design's line height
                    }}
                  >
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Quidem iste deleniti impedit repellendus dolore dolorem
                    soluta consequuntur animi dignissimos nemo veniam,
                    asperiores et sequi omnis suscipit doloremque quae eius
                    vitae! Lorem ipsum dolor sit amet consectetur, adipisicing
                    elit. Dolore debitis magnam rem dolor at commodi ducimus
                    inventore harum quis nesciunt saepe, aspernatur repellendus
                    incidunt voluptatem voluptates ea officia, laborum modi?
                  </p>

                  {/* Show More / Show Less button */}
                  {showButton && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleText();
                      }}
                      className="d-block text-14 text-blue-1 fw-500 underline mt-10"
                    >
                      {isExpanded ? "Show Less" : "Show More"}
                    </button>
                  )}
                </div>

                <div className="col-md-6">
                  <h5 className="text-16 fw-500">Available languages </h5>
                  <div className="text-15 mt-10">
                    German, Chinese, Portuguese, Japanese, Arabic, Italian,
                    Chinese, French, Spanish{" "}
                  </div>
                </div>

                <div className="col-md-6">
                  <h5 className="text-16 fw-500">Cancellation policy </h5>
                  <div className="text-15 mt-10">
                    For a full refund, at least 24 hours advance of the start of
                    the experience.{" "}
                  </div>
                </div>

                <div className="col-12">
                  <h5 className="text-16 fw-500">Notes </h5>
                  <ul className="list-disc text-15 mt-10">
                    <li>
                      Travel between the UNESCO Heritage sites aboard a coach{" "}
                    </li>
                    <li>Explore with a guide delve deeper into the </li>
                    <li>
                      Great for history buffs travelers with limited time{" "}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="mt-40 border-top-light">
                <div className="row x-gap-40 y-gap-40 pt-40">
                  <div className="col-12">
                    <h3 className="text-22 fw-500">What's Included </h3>

                    <div className="row x-gap-40 y-gap-40 pt-20">
                      <div className="col-md-6">
                        <div className="text-dark-1 text-15">
                          <i className="icon-check text-green-2 text-10 mr-10"></i>{" "}
                          Entry ticket to Potter Warner Bros Studio London
                        </div>
                        <div className="text-dark-1  text-15">
                          <i className="icon-check text-green-2  text-10 mr-10"></i>{" "}
                          Return transfers in - coach
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="text-dark-1 text-15">
                          <i className="icon-close text-red-1 text-10 mr-10"></i>{" "}
                          Food and drinks
                        </div>
                        <div className="text-dark-1 text-15">
                          <i className="icon-close text-red-1 text-10 mr-10"></i>{" "}
                          Gratuities
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="d-flex justify-end js-pin-content">
                <div className="w-360 lg:w-full d-flex flex-column items-center">
                  <div className="px-30 py-30 rounded-4 border-light bg-white shadow-4">
                    <div className="text-14 text-light-1">
                      From{" "}
                      <span className="text-20 fw-500 text-dark-1 ml-5">
                        US$72{" "}
                      </span>
                    </div>

                    <div className="row y-gap-20 pt-30">
                      <div className="col-12">
                        <div className="searchMenu-date px-20 py-10 border-light rounded-4 -right js-form-dd js-calendar js-calendar-el">
                          <div data-x-dd-click="searchMenu-date">
                            <h4 className="text-15 fw-500 ls-2 lh-16">Date </h4>

                            <div className="capitalize text-15 text-light-1 ls-2 lh-16">
                              <span className="js-first-date">Wed 2 Mar </span>-
                              <span className="js-last-date">Fri 11 Apr </span>
                            </div>
                          </div>

                          <div
                            className="searchMenu-date__field shadow-2"
                            data-x-dd="searchMenu-date"
                            data-x-dd-toggle="-is-active"
                          >
                            <div className="bg-white px-30 py-30 rounded-4">
                              <div className="elCalendar js-calendar-el-calendar"></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="searchMenu-guests px-20 py-10 border-light rounded-4 js-form-dd js-form-counters">
                          <div data-x-dd-click="searchMenu-guests">
                            <h4 className="text-15 fw-500 ls-2 lh-16">
                              Number of travelers{" "}
                            </h4>

                            <div className="text-15 text-light-1 ls-2 lh-16">
                              <span className="js-count-adult">2 </span> adults
                              -<span className="js-count-child">1 </span>{" "}
                              childeren -
                              <span className="js-count-room">1 </span> room
                            </div>
                          </div>

                          <div
                            className="searchMenu-guests__field shadow-2"
                            data-x-dd="searchMenu-guests"
                            data-x-dd-toggle="-is-active"
                          >
                            <div className="bg-white px-30 py-30 rounded-4">
                              <div className="row y-gap-10 justify-between items-center">
                                <div className="col-auto">
                                  <div className="text-15 fw-500">Adults </div>
                                </div>

                                <div className="col-auto">
                                  <div
                                    className="d-flex items-center js-counter"
                                    data-value-change=".js-count-adult"
                                  >
                                    <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down">
                                      <i className="icon-minus text-12"></i>
                                    </button>

                                    <div className="flex-center size-20 ml-15 mr-15">
                                      <div className="text-15 js-count">2 </div>
                                    </div>

                                    <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up">
                                      <i className="icon-plus text-12"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="border-top-light mt-24 mb-24"></div>

                              <div className="row y-gap-10 justify-between items-center">
                                <div className="col-auto">
                                  <div className="text-15 lh-12 fw-500">
                                    Children{" "}
                                  </div>
                                  <div className="text-14 lh-12 text-light-1 mt-5">
                                    Ages 0 - 17{" "}
                                  </div>
                                </div>

                                <div className="col-auto">
                                  <div
                                    className="d-flex items-center js-counter"
                                    data-value-change=".js-count-child"
                                  >
                                    <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down">
                                      <i className="icon-minus text-12"></i>
                                    </button>

                                    <div className="flex-center size-20 ml-15 mr-15">
                                      <div className="text-15 js-count">1 </div>
                                    </div>

                                    <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up">
                                      <i className="icon-plus text-12"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="border-top-light mt-24 mb-24"></div>

                              <div className="row y-gap-10 justify-between items-center">
                                <div className="col-auto">
                                  <div className="text-15 fw-500">Rooms </div>
                                </div>

                                <div className="col-auto">
                                  <div
                                    className="d-flex items-center js-counter"
                                    data-value-change=".js-count-room"
                                  >
                                    <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down">
                                      <i className="icon-minus text-12"></i>
                                    </button>

                                    <div className="flex-center size-20 ml-15 mr-15">
                                      <div className="text-15 js-count">1 </div>
                                    </div>

                                    <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up">
                                      <i className="icon-plus text-12"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-12">
                        <button className="button -dark-1 py-15 px-35 h-60 col-12 rounded-4 bg-blue-1 text-white">
                          ____ Now
                        </button>
                      </div> */}
                    </div>

                    <div className="d-flex items-center pt-20">
                      <div className="size-40 flex-center bg-light-2 rounded-full">
                        <i className="icon-heart text-16 text-green-2"></i>
                      </div>
                      <div className="text-14 lh-16 ml-10">
                        94% of travelers recommend ____ experience{" "}
                      </div>
                    </div>
                  </div>

                  <div className="px-30">
                    <div className="text-14 text-light-1 mt-30">
                      Not sure? You can ______ this reservation up to 24 hours
                      in advance for _ full refund.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="rooms" class="pt-30">
        <div class="container">
          <div class="row pb-20">
            <div class="col-auto">
              <h3 class="text-22 fw-500">Available Rooms </h3>
            </div>
          </div>

          <div class="bg-blue-2 rounded-4 px-30 py-30 sm:px-20 sm:py-20">
            <div class="row y-gap-30">
              <div class="col-xl-auto">
                <div class="ratio ratio-1:1 col-12 col-md-4 col-xl-12">
                  <img
                    src="/titsolutions/img/backgrounds/1.png"
                    alt=""
                    class="img-ratio rounded-4"
                  />
                </div>

                <div class="">
                  <div class="text-18 fw-500 mt-10">Standard Twin Room </div>

                  <div class="y-gap-5 pt-5">
                    <div class="d-flex items-center">
                      <i class="icon-no-smoke text-20 mr-10"></i>
                      <div class="text-15">Non-smoking rooms </div>
                    </div>

                    <div class="d-flex items-center">
                      <i class="icon-wifi text-20 mr-10"></i>
                      <div class="text-15">Free WiFi </div>
                    </div>

                    <div class="d-flex items-center">
                      <i class="icon-parking text-20 mr-10"></i>
                      <div class="text-15">Parking </div>
                    </div>

                    <div class="d-flex items-center">
                      <i class="icon-kitchen text-20 mr-10"></i>
                      <div class="text-15">Kitchen </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={handleOpen}
                    class="d-block text-15 fw-500 underline text-blue-1 mt-15"
                  >
                    Show Room Information{" "}
                  </button>
                </div>
              </div>

              <Drawer
                size="full"
                placement={placement}
                open={open}
                onClose={() => setOpen(false)}
              >
                <Drawer.Header>
                  {/* <Drawer.Actions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={() => setOpen(false)} appearance="primary">
                      Confirm
                    </Button>
                  </Drawer.Actions> */}
                  <Drawer.Title>Cabin Details</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                  {/* <Placeholder.Paragraph rows={8} /> */}
                  <section className="pt-40">
                    <div className="container">
                      <div className="row y-gap-15 justify-between items-end">
                        <div className="col-lg-10 mx-auto">
                          <h1 className="text-30 fw-600">Double Bed Cabin </h1>

                          <div className="row x-gap-20 y-gap-20 items-center pt-10">
                            <div className="col-auto">
                              <div className="d-flex items-center">
                                <div className="text-14 text-light-1 ml-10">
                                  22 m² | Max 3 Adults
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="pt-40 js-pin-container">
                    <div className="container">
                      <div className="row y-gap-30">
                        <div className="col-lg-10 mx-auto">
                          <div className="relative d-flex justify-center overflow-hidden gallery">
                            <Swiper
                              modules={[Navigation]}
                              slidesPerView={1} // Equivalent to `data-slider-cols="base-1"`
                              navigation={{
                                prevEl: ".js-img-prev",
                                nextEl: ".js-img-next",
                              }}
                              className="swiper-wrapper"
                            >
                              {/* Swiper slides */}
                              <SwiperSlide>
                                <img
                                  src="/titsolutions/img/lists/tour/single/1.png"
                                  alt="image"
                                  className="rounded-4 col-12 h-full object-cover"
                                />
                              </SwiperSlide>
                              <SwiperSlide>
                                <img
                                  src="/titsolutions/img/gallery/1/2.png"
                                  alt="image"
                                  className="rounded-4 col-12 h-full object-cover"
                                />
                              </SwiperSlide>
                              <SwiperSlide>
                                <img
                                  src="/titsolutions/img/gallery/1/3.png"
                                  alt="image"
                                  className="rounded-4 col-12 h-full object-cover"
                                />
                              </SwiperSlide>
                              <SwiperSlide>
                                <img
                                  src="/titsolutions/img/gallery/1/4.png"
                                  alt="image"
                                  className="rounded-4 col-12 h-full object-cover"
                                />
                              </SwiperSlide>
                            </Swiper>

                            {/* Navigation Buttons */}
                            <div className="absolute h-100 col-11">
                              <button className="section-slider-nav -prev flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-img-prev">
                                <i className="icon icon-chevron-left text-12"></i>
                              </button>
                              <button className="section-slider-nav -next flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-img-next">
                                <i className="icon icon-chevron-right text-12"></i>
                              </button>
                            </div>

                            {/* Gallery Link */}
                            <div className="absolute h-full col-12 z-2 px-20 py-20 d-flex justify-end items-end">
                              <Fancybox>
                                <a
                                  href="/titsolutions/img/lists/tour/single/1.png"
                                  className="button -blue-1 px-24 py-15 bg-white text-dark-1"
                                  data-fancybox="gallery2"
                                >
                                  See All Photos
                                </a>
                                {/* Hidden links for other gallery images */}
                                <a
                                  href="/titsolutions/img/gallery/1/2.png"
                                  className="d-none"
                                  data-fancybox="gallery2"
                                ></a>
                                <a
                                  href="/titsolutions/img/gallery/1/3.png"
                                  className="d-none"
                                  data-fancybox="gallery2"
                                ></a>
                                <a
                                  href="/titsolutions/img/gallery/1/4.png"
                                  className="d-none"
                                  data-fancybox="gallery2"
                                ></a>
                                <a
                                  href="/titsolutions/img/gallery/1/5.png"
                                  className="d-none"
                                  data-fancybox="gallery2"
                                ></a>
                              </Fancybox>
                            </div>
                          </div>

                          <div className="border-top-light mt-40 mb-40"></div>

                          <div className="row x-gap-40 y-gap-40">
                            <div className="col-12">
                              <h3 className="text-22 fw-500">Overview </h3>

                              {/* Text content */}
                              <p
                                className={`text-dark-1 text-15 mt-20 ${
                                  isExpanded ? "expanded" : "collapsed"
                                }`}
                                ref={contentRef}
                                style={{
                                  maxHeight: isExpanded ? "none" : "3em", // 2 lines height (adjust based on line height)
                                  overflow: "hidden",
                                  lineHeight: "1.5em", // Adjust based on your design's line height
                                }}
                              >
                                Lorem ipsum dolor sit amet consectetur,
                                adipisicing elit. Quidem iste deleniti impedit
                                repellendus dolore dolorem soluta consequuntur
                                animi dignissimos nemo veniam, asperiores et
                                sequi omnis suscipit doloremque quae eius vitae!
                                Lorem ipsum dolor sit amet consectetur,
                                adipisicing elit. Dolore debitis magnam rem
                                dolor at commodi ducimus inventore harum quis
                                nesciunt saepe, aspernatur repellendus incidunt
                                voluptatem voluptates ea officia, laborum modi?
                              </p>

                              {/* Show More / Show Less button */}
                              {showButton && (
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    toggleText();
                                  }}
                                  className="d-block text-14 text-blue-1 fw-500 underline mt-10"
                                >
                                  {isExpanded ? "Show Less" : "Show More"}
                                </button>
                              )}
                            </div>
                            <div className="col-12">
                              <h5 className="text-16 fw-500">Amenities </h5>
                              <ul className="list-disc text-15 mt-10">
                                <li>
                                  Travel between the UNESCO Heritage sites
                                  aboard a coach{" "}
                                </li>
                                <li>
                                  Explore with a guide delve deeper into the{" "}
                                </li>
                                <li>
                                  Great for history buffs travelers with limited
                                  time{" "}
                                </li>
                              </ul>
                            </div>
                            <div className="col-12">
                              <h3 className="text-22 fw-500">
                                What's Included{" "}
                              </h3>

                              <div className="row x-gap-40 y-gap-40 pt-20">
                                <div className="col-md-6">
                                  <div className="text-dark-1 text-15">
                                    <i className="icon-check text-green-2 text-10 mr-10"></i>{" "}
                                    Entry ticket to Potter Warner Bros Studio
                                    London
                                  </div>
                                  <div className="text-dark-1  text-15">
                                    <i className="icon-check text-green-2  text-10 mr-10"></i>{" "}
                                    Return transfers in - coach
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="text-dark-1 text-15">
                                    <i className="icon-close text-red-1 text-10 mr-10"></i>{" "}
                                    Food and drinks
                                  </div>
                                  <div className="text-dark-1 text-15">
                                    <i className="icon-close text-red-1 text-10 mr-10"></i>{" "}
                                    Gratuities
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mt-40 border-top-light">
                            <div className="row x-gap-40 y-gap-40 pt-40">
                              <div className="col-12">
                                <h5 className="text-16 fw-500">
                                  Cancellation policy{" "}
                                </h5>
                                <div className="text-15 mt-10">
                                  For a full refund, at least 24 hours advance
                                  of the start of the experience.{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </Drawer.Body>
              </Drawer>
              <div class="col-xl">
                <div class="bg-white rounded-4 px-30 py-30">
                  <div class="row y-gap-30">
                    <div class="col-lg col-md-6">
                      <div class="text-15 fw-500 mb-10">
                        Your price includes:{" "}
                      </div>

                      <div class="y-gap-5">
                        <div class="d-flex items-center text-green-2">
                          <i class="icon-check text-12 mr-10"></i>
                          <div class="text-15">Pay at the hotel </div>
                        </div>

                        <div class="d-flex items-center text-green-2">
                          <i class="icon-check text-12 mr-10"></i>
                          <div class="text-15">
                            Pay nothing until March 30, 2022{" "}
                          </div>
                        </div>

                        <div class="d-flex items-center text-green-2">
                          <i class="icon-check text-12 mr-10"></i>
                          <div class="text-15">
                            Free cancellation before April 1, 2022{" "}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-auto col-md-6 border-left-light lg:border-none">
                      <div class="px-40 lg:px-0">
                        <div class="text-15 fw-500 mb-20">Sleeps </div>

                        <div class="d-flex items-center text-light-1">
                          <div class="icon-man text-24"></div>
                          <div class="icon-man text-24"></div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-auto col-md-6 border-left-light lg:border-none">
                      <div class="px-40 lg:px-0">
                        <div class="text-15 fw-500 mb-20">Select Rooms </div>
                        <Stack
                          spacing={10}
                          direction="column"
                          alignItems="flex-start"
                        >
                          <SelectPicker
                            data={data}
                            value={selectedValue}
                            onChange={handleSelectChange}
                            valueKey="value"
                            labelKey="label"
                            searchable={false}
                            style={{ width: 140 }}
                            size="md"
                          />
                        </Stack>
                      </div>
                    </div>

                    <div class="col-lg-auto col-md-6 border-left-light lg:border-none text-right lg:text-left">
                      <div class="pl-40 lg:pl-0">
                        <div class="text-14 lh-14 text-light-1 mb-5">
                          3 rooms for{" "}
                        </div>
                        <div class="text-20 lh-14 fw-500">US$72 </div>

                        <Link
                          to="/add-extra"
                          class="button h-50 px-35 -dark-1 bg-blue-1 text-white mt-10"
                        >
                          Reserve <div class="icon-arrow-top-right ml-15"></div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="bg-white rounded-4 px-30 py-30 mt-20">
                  <div class="row y-gap-30">
                    <div class="col-lg col-md-6">
                      <div class="text-15 fw-500 mb-10">
                        Your price includes:{" "}
                      </div>

                      <div class="y-gap-5">
                        <div class="d-flex items-center text-green-2">
                          <i class="icon-check text-12 mr-10"></i>
                          <div class="text-15">Pay at the hotel </div>
                        </div>

                        <div class="d-flex items-center text-green-2">
                          <i class="icon-check text-12 mr-10"></i>
                          <div class="text-15">
                            Pay nothing until March 30, 2022{" "}
                          </div>
                        </div>

                        <div class="d-flex items-center text-green-2">
                          <i class="icon-check text-12 mr-10"></i>
                          <div class="text-15">
                            Free cancellation before April 1, 2022{" "}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-auto col-md-6 border-left-light lg:border-none">
                      <div class="px-40 lg:px-0">
                        <div class="text-15 fw-500 mb-20">Sleeps </div>

                        <div class="d-flex items-center text-light-1">
                          <div class="icon-man text-24"></div>
                          <div class="icon-man text-24"></div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-auto col-md-6 border-left-light lg:border-none">
                      <div class="px-40 lg:px-0">
                        <div class="text-15 fw-500 mb-20">Select Rooms </div>

                        <Stack
                          spacing={10}
                          direction="column"
                          alignItems="flex-start"
                        >
                          <SelectPicker
                            data={data}
                            value={selectedValue}
                            onChange={handleSelectChange}
                            valueKey="value"
                            labelKey="label"
                            searchable={false}
                            style={{ width: 140 }}
                            size="md"
                          />
                        </Stack>
                      </div>
                    </div>

                    <div class="col-lg-auto col-md-6 border-left-light lg:border-none text-right lg:text-left">
                      <div class="pl-40 lg:pl-0">
                        <div class="text-14 lh-14 text-light-1 mb-5">
                          3 rooms for{" "}
                        </div>
                        <div class="text-20 lh-14 fw-500">US$72 </div>

                        <Link
                          to="/add-extra"
                          class="button h-50 px-35 -dark-1 bg-blue-1 text-white mt-10"
                        >
                          Reserve <div class="icon-arrow-top-right ml-15"></div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="bg-white rounded-4 px-30 py-30 mt-20">
                  <div class="row y-gap-30">
                    <div class="col-lg col-md-6">
                      <div class="text-15 fw-500 mb-10">
                        Your price includes:{" "}
                      </div>

                      <div class="y-gap-5">
                        <div class="d-flex items-center text-green-2">
                          <i class="icon-check text-12 mr-10"></i>
                          <div class="text-15">Pay at the hotel </div>
                        </div>

                        <div class="d-flex items-center text-green-2">
                          <i class="icon-check text-12 mr-10"></i>
                          <div class="text-15">
                            Pay nothing until March 30, 2022{" "}
                          </div>
                        </div>

                        <div class="d-flex items-center text-green-2">
                          <i class="icon-check text-12 mr-10"></i>
                          <div class="text-15">
                            Free cancellation before April 1, 2022{" "}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-auto col-md-6 border-left-light lg:border-none">
                      <div class="px-40 lg:px-0">
                        <div class="text-15 fw-500 mb-20">Sleeps </div>

                        <div class="d-flex items-center text-light-1">
                          <div class="icon-man text-24"></div>
                          <div class="icon-man text-24"></div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-auto col-md-6 border-left-light lg:border-none">
                      <div class="px-40 lg:px-0">
                        <div class="text-15 fw-500 mb-20">Select Rooms </div>

                        <Stack
                          spacing={10}
                          direction="column"
                          alignItems="flex-start"
                        >
                          <SelectPicker
                            data={data}
                            value={selectedValue}
                            onChange={handleSelectChange}
                            valueKey="value"
                            labelKey="label"
                            searchable={false}
                            style={{ width: 140 }}
                            size="md"
                          />
                        </Stack>
                      </div>
                    </div>

                    <div class="col-lg-auto col-md-6 border-left-light lg:border-none text-right lg:text-left">
                      <div class="pl-40 lg:pl-0">
                        <div class="text-14 lh-14 text-light-1 mb-5">
                          3 rooms for{" "}
                        </div>
                        <div class="text-20 lh-14 fw-500">US$72 </div>

                        <Link
                          to="/add-extra"
                          class="button h-50 px-35 -dark-1 bg-blue-1 text-white mt-10"
                        >
                          Reserve <div class="icon-arrow-top-right ml-15"></div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-20">
            <div class="bg-blue-2 rounded-4 px-30 py-30 sm:px-20 sm:py-20">
              <div class="row y-gap-30">
                <div class="col-xl-auto">
                  <div class="ratio ratio-1:1 col-12 col-md-4 col-xl-12">
                    <img
                      src="/titsolutions/img/backgrounds/1.png"
                      alt=""
                      class="img-ratio rounded-4"
                    />
                  </div>

                  <div class="">
                    <div class="text-18 fw-500 mt-10">Standard Twin Room </div>

                    <div class="y-gap-5 pt-5">
                      <div class="d-flex items-center">
                        <i class="icon-no-smoke text-20 mr-10"></i>
                        <div class="text-15">Non-smoking rooms </div>
                      </div>

                      <div class="d-flex items-center">
                        <i class="icon-wifi text-20 mr-10"></i>
                        <div class="text-15">Free WiFi </div>
                      </div>

                      <div class="d-flex items-center">
                        <i class="icon-parking text-20 mr-10"></i>
                        <div class="text-15">Parking </div>
                      </div>

                      <div class="d-flex items-center">
                        <i class="icon-kitchen text-20 mr-10"></i>
                        <div class="text-15">Kitchen </div>
                      </div>
                    </div>

                    <button
                      type="button"
                      onClick={handleOpen}
                      class="d-block text-15 fw-500 underline text-blue-1 mt-15"
                    >
                      Show Room Information{" "}
                    </button>
                  </div>
                </div>

                <div class="col-xl">
                  <div class="bg-white rounded-4 px-30 py-30">
                    <div class="row y-gap-30">
                      <div class="col-lg col-md-6">
                        <div class="text-15 fw-500 mb-10">
                          Your price includes:{" "}
                        </div>

                        <div class="y-gap-5">
                          <div class="d-flex items-center text-green-2">
                            <i class="icon-check text-12 mr-10"></i>
                            <div class="text-15">Pay at the hotel </div>
                          </div>

                          <div class="d-flex items-center text-green-2">
                            <i class="icon-check text-12 mr-10"></i>
                            <div class="text-15">
                              Pay nothing until March 30, 2022{" "}
                            </div>
                          </div>

                          <div class="d-flex items-center text-green-2">
                            <i class="icon-check text-12 mr-10"></i>
                            <div class="text-15">
                              Free cancellation before April 1, 2022{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-auto col-md-6 border-left-light lg:border-none">
                        <div class="px-40 lg:px-0">
                          <div class="text-15 fw-500 mb-20">Sleeps </div>

                          <div class="d-flex items-center text-light-1">
                            <div class="icon-man text-24"></div>
                            <div class="icon-man text-24"></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-auto col-md-6 border-left-light lg:border-none">
                        <div class="px-40 lg:px-0">
                          <div class="text-15 fw-500 mb-20">Select Rooms </div>

                          <Stack
                            spacing={10}
                            direction="column"
                            alignItems="flex-start"
                          >
                            <SelectPicker
                              data={data}
                              value={selectedValue}
                              onChange={handleSelectChange}
                              valueKey="value"
                              labelKey="label"
                              searchable={false}
                              style={{ width: 140 }}
                              size="md"
                            />
                          </Stack>
                        </div>
                      </div>

                      <div class="col-lg-auto col-md-6 border-left-light lg:border-none text-right lg:text-left">
                        <div class="pl-40 lg:pl-0">
                          <div class="text-14 lh-14 text-light-1 mb-5">
                            3 rooms for{" "}
                          </div>
                          <div class="text-20 lh-14 fw-500">US$72 </div>

                          <Link
                            to="/add-extra"
                            class="button h-50 px-35 -dark-1 bg-blue-1 text-white mt-10"
                          >
                            Reserve{" "}
                            <div class="icon-arrow-top-right ml-15"></div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="bg-white rounded-4 px-30 py-30 mt-20">
                    <div class="row y-gap-30">
                      <div class="col-lg col-md-6">
                        <div class="text-15 fw-500 mb-10">
                          Your price includes:{" "}
                        </div>

                        <div class="y-gap-5">
                          <div class="d-flex items-center text-green-2">
                            <i class="icon-check text-12 mr-10"></i>
                            <div class="text-15">Pay at the hotel </div>
                          </div>

                          <div class="d-flex items-center text-green-2">
                            <i class="icon-check text-12 mr-10"></i>
                            <div class="text-15">
                              Pay nothing until March 30, 2022{" "}
                            </div>
                          </div>

                          <div class="d-flex items-center text-green-2">
                            <i class="icon-check text-12 mr-10"></i>
                            <div class="text-15">
                              Free cancellation before April 1, 2022{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-auto col-md-6 border-left-light lg:border-none">
                        <div class="px-40 lg:px-0">
                          <div class="text-15 fw-500 mb-20">Sleeps </div>

                          <div class="d-flex items-center text-light-1">
                            <div class="icon-man text-24"></div>
                            <div class="icon-man text-24"></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-auto col-md-6 border-left-light lg:border-none">
                        <div class="px-40 lg:px-0">
                          <div class="text-15 fw-500 mb-20">Select Rooms </div>

                          <Stack
                            spacing={10}
                            direction="column"
                            alignItems="flex-start"
                          >
                            <SelectPicker
                              data={data}
                              value={selectedValue}
                              onChange={handleSelectChange}
                              valueKey="value"
                              labelKey="label"
                              searchable={false}
                              style={{ width: 140 }}
                              size="md"
                            />
                          </Stack>
                        </div>
                      </div>

                      <div class="col-lg-auto col-md-6 border-left-light lg:border-none text-right lg:text-left">
                        <div class="pl-40 lg:pl-0">
                          <div class="text-14 lh-14 text-light-1 mb-5">
                            3 rooms for{" "}
                          </div>
                          <div class="text-20 lh-14 fw-500">US$72 </div>

                          <Link
                            to="/add-extra"
                            class="button h-50 px-35 -dark-1 bg-blue-1 text-white mt-10"
                          >
                            Reserve{" "}
                            <div class="icon-arrow-top-right ml-15"></div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="container mt-40 mb-40">
        <div class="border-top-light"></div>
      </div>
      <section>
        <div class="container">
          <h3 class="text-22 fw-500 mb-20">Itinerary </h3>

          <div class="row y-gap-30">
            <div class="col-lg-6">
              <div class="relative">
                <div class="border-test"></div>

                <div className="accordion -map row y-gap-20">
                  {accordionData.map((item, index) => (
                    <div className="col-12" key={index}>
                      <div
                        className={`accordion__item is-active${
                          activeIndex === index ? "is-active" : ""
                        }`}
                      >
                        <div className="d-flex">
                          <div className="accordion__icon size-40 flex-center bg-blue-2 text-blue-1 rounded-full">
                            <div className="text-14 fw-500">{index + 1}</div>
                          </div>

                          <div className="ml-20">
                            <div className="text-16 lh-15 fw-500">
                              {item.title}
                            </div>
                            <div className="text-14 lh-15 text-light-1 mt-5">
                              {item.description}
                            </div>

                            {activeIndex === index && (
                              <div
                                className="accordion__content"
                                style={{
                                  maxHeight: "100%",
                                  position: "relative",
                                }}
                              >
                                <div className="pt-15 pb-15">
                                  <img
                                    src={item.image}
                                    alt=""
                                    className="rounded-4 mt-15"
                                  />
                                  <div className="text-14 lh-17 mt-15">
                                    {item.content}
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="accordion__button">
                              <button
                                type="button"
                                className="d-block lh-15 text-14 text-blue-1 underline fw-500 mt-5"
                                onClick={() => handleAccordionToggle(index)}
                              >
                                {activeIndex === index
                                  ? "See less"
                                  : "See More & View photo"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div class="col-lg-6"></div>
          </div>
        </div>
      </section>
      <div class="container mt-40 mb-40">
        <div class="border-top-light"></div>
      </div>
      <div id="facilities"></div>
      <section class="mt-40">
        <div class="container">
          <div class="row x-gap-40 y-gap-40">
            <div class="col-12">
              <h3 class="text-22 fw-500">
                Facilities of Suntimes Nile Cruise{" "}
              </h3>

              <div class="row x-gap-40 y-gap-40 pt-20">
                <div class="col-xl-4">
                  <div class="row y-gap-30">
                    <div class="col-12">
                      <div class="">
                        <div class="d-flex items-center text-16 fw-500">
                          <i class="icon-bathtub text-20 mr-10"></i>
                          Accommodation
                        </div>

                        <ul class="text-15 pt-10">
                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Towels
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Bath or shower
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Private bathroom
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Toilet
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Free toiletries
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Hairdryer
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Bath
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="">
                        <div class="d-flex items-center text-16 fw-500">
                          <i class="icon-bed text-20 mr-10"></i>
                          Sleeping
                        </div>

                        <ul class="text-15 pt-10">
                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Linen
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Wardrobe or closet
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="">
                        <div class="d-flex items-center text-16 fw-500">
                          <i class="icon-bell-ring text-20 mr-10"></i>
                          services
                        </div>

                        <ul class="text-15 pt-10">
                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Invoice provided
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Private check-in/check-out
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Luggage storage
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            24-hour front desk
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4">
                  <div class="row y-gap-30">
                    <div class="col-12">
                      <div class="">
                        <div class="d-flex items-center text-16 fw-500">
                          <i class="icon-tv text-20 mr-10"></i>
                          TV &amp; Technology
                        </div>

                        <ul class="text-15 pt-10">
                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Flat-screen TV
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Satellite channels
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Radio
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Telephone
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            TV
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="">
                        <div class="d-flex items-center text-16 fw-500">
                          <i class="icon-juice text-20 mr-10"></i>
                          Food &amp; Drink
                        </div>

                        <ul class="text-15 pt-10">
                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Kid meals
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Special diet menus ( request)
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Breakfast
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Bar
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Restaurant
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Tea/Coffee maker
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="">
                        <div class="d-flex items-center text-16 fw-500">
                          <i class="icon-washing-machine text-20 mr-10"></i>
                          services
                        </div>

                        <ul class="text-15 pt-10">
                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Daily housekeeping
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Dry cleaning
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Laundry
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4">
                  <div class="row y-gap-30">
                    <div class="col-12">
                      <div class="">
                        <div class="d-flex items-center text-16 fw-500">
                          <i class="icon-shield text-20 mr-10"></i>
                          Privacy &amp; security
                        </div>

                        <ul class="text-15 pt-10">
                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Fire extinguishers
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            CCTV in common
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Smoke alarms
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            24-hour security
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="">
                        <div class="d-flex items-center text-16 fw-500">
                          <i class="icon-city-2 text-20 mr-10"></i>
                          Others
                        </div>

                        <ul class="text-15 pt-10">
                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Hypoallergenic
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Non-smoking throughout
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Wake-up service
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Heating
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Packed lunches
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Carpeted
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Lift
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Fan
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Family rooms
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Facilities for disabled ______
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Ironing facilities
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Non-smoking rooms
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Iron
                          </li>

                          <li class="d-flex items-center">
                            <i class="icon-check text-10 mr-20"></i>
                            Room service
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="container mt-40 mb-40">
        <div class="border-top-light"></div>
      </div>
      <section class="pt-40">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="px-24 py-20 rounded-4 bg-light-2">
                <div class="row x-gap-20 y-gap-20 items-center">
                  <div class="col-auto">
                    <div class="flex-center size-60 rounded-full bg-white">
                      <img
                        src="/titsolutions/img/icons/health.svg"
                        alt="icon"
                      />
                    </div>
                  </div>

                  <div class="col-auto">
                    <h4 class="text-18 lh-15 fw-500">Extra health & safety </h4>
                    <div class="text-15 lh-15">
                      This property has taken health and hygiene measures ensure
                      that your safety their priority{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-40">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h3 class="text-22 fw-500">Hotel surroundings </h3>
            </div>
          </div>

          <div class="row x-gap-50 y-gap-30 pt-20">
            <div class="col-lg-4 col-md-6">
              <div class="d-flex items-center">
                <i class="icon-nearby text-20 mr-10"></i>
                <div class="text-16 fw-500">What's nearby </div>
              </div>

              <div class="row y-gap-10 pt-10">
                <div class="col-12">
                  <div class="row items-center justify-between">
                    <div class="col-auto">
                      <div class="text-15">Royal Pump Room Museum </div>
                    </div>

                    <div class="col-auto">
                      <div class="text-15 text-right">0.1 km </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="border-top-light"></div>
                </div>

                <div class="col-12">
                  <div class="row items-center justify-between">
                    <div class="col-auto">
                      <div class="text-15">Harrogate Turkish Baths </div>
                    </div>

                    <div class="col-auto">
                      <div class="text-15 text-right">0.1 km </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="border-top-light"></div>
                </div>

                <div class="col-12">
                  <div class="row items-center justify-between">
                    <div class="col-auto">
                      <div class="text-15">Royal Hall Theatre </div>
                    </div>

                    <div class="col-auto">
                      <div class="text-15 text-right">0.1 km </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="border-top-light"></div>
                </div>

                <div class="col-12">
                  <div class="row items-center justify-between">
                    <div class="col-auto">
                      <div class="text-15">Harrogate Theatre </div>
                    </div>

                    <div class="col-auto">
                      <div class="text-15 text-right">0.1 km </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="border-top-light"></div>
                </div>

                <div class="col-12">
                  <div class="row items-center justify-between">
                    <div class="col-auto">
                      <div class="text-15">Harrogate Library </div>
                    </div>

                    <div class="col-auto">
                      <div class="text-15 text-right">0.1 km </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="border-top-light"></div>
                </div>

                <div class="col-12">
                  <div class="row items-center justify-between">
                    <div class="col-auto">
                      <div class="text-15">The Valley Gardens </div>
                    </div>

                    <div class="col-auto">
                      <div class="text-15 text-right">0.1 km </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="border-top-light"></div>
                </div>

                <div class="col-12">
                  <div class="row items-center justify-between">
                    <div class="col-auto">
                      <div class="text-15">Harrogate District Hospital </div>
                    </div>

                    <div class="col-auto">
                      <div class="text-15 text-right">0.1 km </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="mb-40 md:mb-30">
                <div class="d-flex items-center">
                  <i class="icon-airplane text-20 mr-10"></i>
                  <div class="text-16 fw-500">Closest airports </div>
                </div>

                <div class="row y-gap-10 pt-10">
                  <div class="col-12">
                    <div class="row items-center justify-between">
                      <div class="col-auto">
                        <div class="text-15">
                          Leeds Bradford International Airport{" "}
                        </div>
                      </div>

                      <div class="col-auto">
                        <div class="text-15 text-right">57.9 km </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="border-top-light"></div>
                  </div>

                  <div class="col-12">
                    <div class="row items-center justify-between">
                      <div class="col-auto">
                        <div class="text-15">Durham Tees Valley Airport </div>
                      </div>

                      <div class="col-auto">
                        <div class="text-15 text-right">57.9 km </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="border-top-light"></div>
                  </div>

                  <div class="col-12">
                    <div class="row items-center justify-between">
                      <div class="col-auto">
                        <div class="text-15">Doncaster Sheffield Airport </div>
                      </div>

                      <div class="col-auto">
                        <div class="text-15 text-right">57.9 km </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="">
                <div class="d-flex items-center">
                  <i class="icon-food text-20 mr-10"></i>
                  <div class="text-16 fw-500">Restaurants & cafes </div>
                </div>

                <div class="row y-gap-10 pt-10">
                  <div class="col-12">
                    <div class="row items-center justify-between">
                      <div class="col-auto">
                        <div class="text-15">
                          Cafe/bar Bettys Café Tea _____{" "}
                        </div>
                      </div>

                      <div class="col-auto">
                        <div class="text-15 text-right">57.9 km </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="border-top-light"></div>
                  </div>

                  <div class="col-12">
                    <div class="row items-center justify-between">
                      <div class="col-auto">
                        <div class="text-15">
                          Cafe/bar Bettys Café Tea _____{" "}
                        </div>
                      </div>

                      <div class="col-auto">
                        <div class="text-15 text-right">57.9 km </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="">
                <div class="d-flex items-center">
                  <i class="icon-ticket text-20 mr-10"></i>
                  <div class="text-16 fw-500">Top attractions </div>
                </div>

                <div class="row y-gap-10 pt-10">
                  <div class="col-12">
                    <div class="row items-center justify-between">
                      <div class="col-auto">
                        <div class="text-15">Ripley Castle </div>
                      </div>

                      <div class="col-auto">
                        <div class="text-15 text-right">57.9 km </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="border-top-light"></div>
                  </div>

                  <div class="col-12">
                    <div class="row items-center justify-between">
                      <div class="col-auto">
                        <div class="text-15">Roundhay Park </div>
                      </div>

                      <div class="col-auto">
                        <div class="text-15 text-right">57.9 km </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="border-top-light"></div>
                  </div>

                  <div class="col-12">
                    <div class="row items-center justify-between">
                      <div class="col-auto">
                        <div class="text-15">Bramham Park </div>
                      </div>

                      <div class="col-auto">
                        <div class="text-15 text-right">57.9 km </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-40">
        <div class="container">
          <div class="pt-40 border-top-light">
            <div class="row">
              <div class="col-12">
                <h3 class="text-22 fw-500">Some helpful facts </h3>
              </div>
            </div>

            <div class="row x-gap-50 y-gap-30 pt-20">
              <div class="col-lg-4 col-md-6">
                <div class="">
                  <div class="d-flex items-center">
                    <i class="icon-calendar text-20 mr-10"></i>
                    <div class="text-16 fw-500">Check-in/Check-out </div>
                  </div>

                  <div class="row x-gap-50 y-gap-5 pt-10">
                    <div class="col-12">
                      <div class="text-15">Check-in from: 16:00 </div>
                    </div>

                    <div class="col-12">
                      <div class="text-15">Check-out until: 12:00 </div>
                    </div>

                    <div class="col-12">
                      <div class="text-15">Reception open until: 00:00 </div>
                    </div>
                  </div>
                </div>

                <div class="mt-30">
                  <div class="d-flex items-center">
                    <i class="icon-location-pin text-20 mr-10"></i>
                    <div class="text-16 fw-500">Getting around </div>
                  </div>

                  <div class="row x-gap-50 y-gap-5 pt-10">
                    <div class="col-12">
                      <div class="text-15">Airport transfer fee: 60 ___ </div>
                    </div>

                    <div class="col-12">
                      <div class="text-15">
                        Distance from city center: 2 km{" "}
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="text-15">
                        Travel time to airport (_______): 45{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="">
                  <div class="d-flex items-center">
                    <i class="icon-ticket text-20 mr-10"></i>
                    <div class="text-16 fw-500">Extras </div>
                  </div>

                  <div class="row x-gap-50 y-gap-5 pt-10">
                    <div class="col-12">
                      <div class="text-15">
                        Breakfast charge (unless included __ room price): 25 USD{" "}
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="text-15">
                        Daily Internet/Wi-Fi fee: 10 ___{" "}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-30">
                  <div class="d-flex items-center">
                    <i class="icon-parking text-20 mr-10"></i>
                    <div class="text-16 fw-500">Parking </div>
                  </div>

                  <div class="row x-gap-50 y-gap-5 pt-10">
                    <div class="col-12">
                      <div class="text-15">Daily parking fee: 65 ___ </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="">
                  <div class="d-flex items-center">
                    <i class="icon-plans text-20 mr-10"></i>
                    <div class="text-16 fw-500">The property </div>
                  </div>

                  <div class="row x-gap-50 y-gap-5 pt-10">
                    <div class="col-12">
                      <div class="text-15">Non-smoking rooms/floors: Yes </div>
                    </div>

                    <div class="col-12">
                      <div class="text-15">Number of bars/lounges: 1 </div>
                    </div>

                    <div class="col-12">
                      <div class="text-15">Number of floors: 26 </div>
                    </div>

                    <div class="col-12">
                      <div class="text-15">Number of restaurants: 1 </div>
                    </div>

                    <div class="col-12">
                      <div class="text-15">Number of rooms : 443 </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="faq" className="pt-40 layout-pb-md">
        <div className="container">
          <div className="pt-40 border-top-light">
            <div className="row y-gap-20">
              <div className="col-lg-4">
                <h2 className="text-22 fw-500">
                  FAQs about <br /> The Crown Hotel
                </h2>
              </div>

              <div className="col-lg-8">
                <div className="accordion -simple row y-gap-20">
                  {faqItems.map((item, index) => (
                    <div className="col-12" key={index}>
                      <div className="accordion__item px-20 py-20 border-light rounded-4 ">
                        <div
                          className="accordion__button d-flex items-center "
                          onClick={() => toggleAccordion(index)}
                        >
                          <div className="accordion__icon size-40 flex-center bg-light-2 rounded-full mr-20">
                            <i
                              className={
                                activeFaq === index ? "icon-minus" : "icon-plus"
                              }
                            ></i>
                          </div>
                          <div className="button text-dark-1">
                            {item.question}
                          </div>
                        </div>
                        {activeFaq === index && (
                          <div
                            className="accordion__content"
                            style={{ maxHeight: "100%" }}
                          >
                            <div className="pt-20 pl-60">
                              <p className="text-15">{item.answer}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="layout-pt-md layout-pb-lg">
        <div class="container">
          <div class="row justify-center text-center">
            <div class="col-auto">
              <div class="sectionTitle -md">
                <h2 class="sectionTitle__title">
                  Popular properties similar to Suntimes Nile Cruise{" "}
                </h2>
                <p class=" sectionTitle__text mt-5 sm:mt-0">
                  Interdum et malesuada fames ante ipsum{" "}
                </p>
              </div>
            </div>
          </div>

          <div class="row y-gap-30 pt-40 sm:pt-20">
            <div class="col-xl-3 col-lg-3 col-sm-6">
              <Link to="#." class="hotelsCard -type-1 ">
                <div class="hotelsCard__image">
                  <div class="cardImage ratio ratio-1:1">
                    <div class="cardImage__content">
                      <img
                        class="rounded-4 col-12"
                        src="/titsolutions/img/hotels/1.png"
                        alt="image"
                      />
                    </div>

                    <div class="cardImage__wishlist">
                      <button class="button -blue-1 bg-white size-30 rounded-full shadow-2">
                        <i class="icon-heart text-12"></i>
                      </button>
                    </div>

                    <div class="cardImage__leftBadge">
                      <div class="py-5 px-15 rounded-right-4 text-12 lh-16 fw-500 uppercase bg-dark-1 text-white">
                        Breakfast included
                      </div>
                    </div>
                  </div>
                </div>

                <div class="hotelsCard__content mt-10">
                  <h4 class="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>The Montcalm At Brewery City </span>
                  </h4>

                  <p class="text-light-1 lh-14 text-14 mt-5">
                    Westminster Borough, London{" "}
                  </p>

                  <div class="d-flex items-center mt-20">
                    <div class="flex-center bg-blue-1 rounded-4 size-30 text-12 fw-600 text-white">
                      4.8{" "}
                    </div>
                    <div class="text-14 text-dark-1 fw-500 ml-10">
                      Exceptional{" "}
                    </div>
                    <div class="text-14 text-light-1 ml-10">3,014 reviews </div>
                  </div>

                  <div class="mt-5">
                    <div class="fw-500">
                      Starting from <span class="text-blue-1">US$72 </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-6">
              <Link to="#." class="hotelsCard -type-1 ">
                <div class="hotelsCard__image">
                  <div class="cardImage ratio ratio-1:1">
                    <div class="cardImage__content">
                      <img
                        class="rounded-4 col-12"
                        src="/titsolutions/img/hotels/4.png"
                        alt="image"
                      />
                    </div>

                    <div class="cardImage__wishlist">
                      <button class="button -blue-1 bg-white size-30 rounded-full shadow-2">
                        <i class="icon-heart text-12"></i>
                      </button>
                    </div>

                    <div class="cardImage__leftBadge">
                      <div class="py-5 px-15 rounded-right-4 text-12 lh-16 fw-500 uppercase bg-yellow-1 text-dark-1">
                        Top Rated
                      </div>
                    </div>
                  </div>
                </div>

                <div class="hotelsCard__content mt-10">
                  <h4 class="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>
                      DoubleTree by Hilton Hotel York Times Square West{" "}
                    </span>
                  </h4>

                  <p class="text-light-1 lh-14 text-14 mt-5">
                    Vaticano Prati, Rome{" "}
                  </p>

                  <div class="d-flex items-center mt-20">
                    <div class="flex-center bg-blue-1 rounded-4 size-30 text-12 fw-600 text-white">
                      4.8{" "}
                    </div>
                    <div class="text-14 text-dark-1 fw-500 ml-10">
                      Exceptional{" "}
                    </div>
                    <div class="text-14 text-light-1 ml-10">3,014 reviews </div>
                  </div>

                  <div class="mt-5">
                    <div class="fw-500">
                      Starting from <span class="text-blue-1">US$72 </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div class="col-xl-3 col-lg-3 col-sm-6">
              <Link to="#." class="hotelsCard -type-1 ">
                <div class="hotelsCard__image">
                  <div class="cardImage ratio ratio-1:1">
                    <div class="cardImage__content">
                      <img
                        class="rounded-4 col-12"
                        src="/titsolutions/img/hotels/3.png"
                        alt="image"
                      />
                    </div>

                    <div class="cardImage__wishlist">
                      <button class="button -blue-1 bg-white size-30 rounded-full shadow-2">
                        <i class="icon-heart text-12"></i>
                      </button>
                    </div>

                    <div class="cardImage__leftBadge">
                      <div class="py-5 px-15 rounded-right-4 text-12 lh-16 fw-500 uppercase bg-blue-1 text-white">
                        Best Seller
                      </div>
                    </div>
                  </div>
                </div>

                <div class="hotelsCard__content mt-10">
                  <h4 class="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>The Westin New York Times Square </span>
                  </h4>

                  <p class="text-light-1 lh-14 text-14 mt-5">
                    Manhattan, New York{" "}
                  </p>

                  <div class="d-flex items-center mt-20">
                    <div class="flex-center bg-blue-1 rounded-4 size-30 text-12 fw-600 text-white">
                      4.8{" "}
                    </div>
                    <div class="text-14 text-dark-1 fw-500 ml-10">
                      Exceptional{" "}
                    </div>
                    <div class="text-14 text-light-1 ml-10">3,014 reviews </div>
                  </div>

                  <div class="mt-5">
                    <div class="fw-500">
                      Starting from <span class="text-blue-1">US$72 </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div class="col-xl-3 col-lg-3 col-sm-6">
              <Link to="#." class="hotelsCard -type-1 ">
                <div class="hotelsCard__image">
                  <div class="cardImage ratio ratio-1:1">
                    <div class="cardImage__content">
                      <img
                        class="rounded-4 col-12"
                        src="/titsolutions/img/hotels/4.png"
                        alt="image"
                      />
                    </div>

                    <div class="cardImage__wishlist">
                      <button class="button -blue-1 bg-white size-30 rounded-full shadow-2">
                        <i class="icon-heart text-12"></i>
                      </button>
                    </div>

                    <div class="cardImage__leftBadge">
                      <div class="py-5 px-15 rounded-right-4 text-12 lh-16 fw-500 uppercase bg-yellow-1 text-dark-1">
                        Top Rated
                      </div>
                    </div>
                  </div>
                </div>

                <div class="hotelsCard__content mt-10">
                  <h4 class="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>
                      DoubleTree by Hilton Hotel York Times Square West{" "}
                    </span>
                  </h4>

                  <p class="text-light-1 lh-14 text-14 mt-5">
                    Vaticano Prati, Rome{" "}
                  </p>

                  <div class="d-flex items-center mt-20">
                    <div class="flex-center bg-blue-1 rounded-4 size-30 text-12 fw-600 text-white">
                      4.8{" "}
                    </div>
                    <div class="text-14 text-dark-1 fw-500 ml-10">
                      Exceptional{" "}
                    </div>
                    <div class="text-14 text-light-1 ml-10">3,014 reviews </div>
                  </div>

                  <div class="mt-5">
                    <div class="fw-500">
                      Starting from <span class="text-blue-1">US$72 </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CruisesDetails;
