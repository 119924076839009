import React, { useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
export const BookingForm = () => {
  const navigate = useNavigate();
  const locations = [
    { name: "4 Days - 3 Nights  ", description: "Start From Aswan To Luxor" },
    { name: "5 Days - 4 Nights ", description: "Start From Luxor To  Aswan" },
    {
      name: "8 Days - 7 Nights ",
      description: "Start From Luxor To  Aswan & Return To Luxor",
    },
  ];
  const [isActiveDestination, setIsActiveDestination] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const searchMenuRef = useRef(null);

  // Toggle the active state on click
  const handleDestinationClick = () => {
    setIsActiveDestination(!isActiveDestination);
  };

  // Handle blur event
  const handleBlur = (event) => {
    if (
      searchMenuRef.current &&
      !searchMenuRef.current.contains(event.relatedTarget)
    ) {
      setIsActiveDestination(false);
    }
  };

  // Handle selection of a location
  const handleLocationSelect = (location) => {
    setSelectedLocation(location.name); // Set the selected location
    setSearchTerm(""); // Clear the search term
    setIsActiveDestination(false); // Close the dropdown after selection
  };

  // Filter locations based on search term
  const filteredLocations = locations.filter((location) =>
    location.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [value, setValue] = useState([null, null]);

  const handleChange = (dates) => {
    setValue(dates);
  };

  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(1);
  const [rooms, setRooms] = useState(1);

  const handleIncrement = (setter) => {
    setter((prev) => prev + 1);
  };

  const handleDecrement = (setter) => {
    setter((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const [isActiveGuest, setIsActiveGuest] = useState(false);

  const GuestMenuRef = useRef(null);

  // Toggle the active state on click
  const handleGuestClick = () => {
    setIsActiveGuest(!isActiveGuest);
  };
  const handleGuestBlur = (event) => {
    if (
      GuestMenuRef.current &&
      !GuestMenuRef.current.contains(event.relatedTarget)
    ) {
      setIsActiveGuest(false);
    }
  };
  const formatDate = (date) => {
    if (!date) return "";
    return format(date, "EEE d MMM"); // e.g., "Wed 2 Mar"
  };
  const disablePastDates = (date) => {
    const today = new Date();
    return date < today.setHours(0, 0, 0, 0); // Disable past dates
  };

  return (
    <div className="tabs -underline mt-60 js-tabs is-in-view">
      <div className="tabs__content mt-30 md:mt-20 js-tabs-content">
        <div className="tabs__pane -tab-item-1 is-tab-el-active">
          <div className="mainSearch -w-1000 bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 rounded-100">
            <div className="button-grid items-center">
              <div
                ref={searchMenuRef}
                className={`searchMenu-loc px-30 lg:py-20 lg:px-0 js-form-dd js-liverSearch  ${
                  isActiveDestination ? "-is-dd-wrap-active" : ""
                }`}
                onClick={handleDestinationClick}
              >
                <div onBlur={handleBlur} data-x-dd-click="searchMenu-loc">
                  <h4 className="text-15 fw-500 ls-2 lh-16">
                    Where would like to go?
                  </h4>
                  <div className="text-15 text-light-1 ls-2 lh-16">
                    <input
                      autoComplete="off"
                      type="search"
                      placeholder="Select Itinerary"
                      className="js-search js-dd-focus"
                      value={selectedLocation} // Set the input value to the selected location
                      onChange={(e) => {
                        setSelectedLocation(e.target.value);
                        setSearchTerm(e.target.value); // Update search term to filter results
                      }}
                    />
                  </div>
                </div>

                <div
                  className={`searchMenu-loc__field shadow-2 js-popup-window ${
                    isActiveDestination ? "-is-active" : ""
                  }`}
                  data-x-dd="searchMenu-loc"
                  data-x-dd-toggle="-is-active"
                >
                  <div className="bg-white px-30 py-30 sm:px-0 sm:py-15 rounded-4">
                    <div className="y-gap-5 js-results">
                      {filteredLocations.length > 0 ? (
                        filteredLocations.map((location) => (
                          <div key={location.name}>
                            <button
                              className="-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option"
                              onClick={() => handleLocationSelect(location)}
                            >
                              <div className="d-flex">
                                <div className=" icon-destination text-light-1 text-20 pt-4"></div>

                                <div className="ml-10">
                                  <div className="text-15 lh-12 fw-500">
                                    {location.name}
                                  </div>
                                  <div className="text-14 lh-12 text-light-1 mt-5">
                                    {location.description}
                                  </div>
                                </div>
                              </div>
                            </button>
                          </div>
                        ))
                      ) : (
                        <div className="text-center py-15">
                          No results found
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="searchMenu-date px-10 lg:py-20 lg:px-0 -is-dd-wrap-active">
                <div>
                  <h4 className="text-15 fw-500 ls-2 lh-16">
                    Check in - Check out
                  </h4>
                  <DateRangePicker
                    value={value}
                    onChange={handleChange}
                    placeholder="Select Date Range"
                    className="text-15 text-light-1 ls-2 lh-16"
                    disabledDate={disablePastDates} // Add this prop to disable past dates
                    renderValue={(dates) => {
                      if (!dates[0] || !dates[1]) return "Select Date Range";
                      return `${formatDate(dates[0])} - ${formatDate(
                        dates[1]
                      )}`;
                    }}
                  />
                </div>
              </div>

              <div
                ref={GuestMenuRef}
                className={`searchMenu-guests px-30 lg:py-20 lg:px-0 js-form-dd js-form-counters ${
                  isActiveGuest ? "-is-dd-wrap-active" : ""
                }`}
                onBlur={handleGuestBlur}
              >
                <div
                  data-x-dd-click="searchMenu-guests"
                  onClick={handleGuestClick}
                >
                  <h4 className="text-15 fw-500 ls-2 lh-16">Guest</h4>
                  <div className="text-15 text-light-1 ls-2 lh-16">
                    <span className="js-count-adult">{adults}</span> adults -
                    <span className="js-count-child">{children}</span> children
                    -<span className="js-count-room">{rooms}</span> cabin/suit
                    {rooms > 1 ? "s" : ""}
                  </div>
                </div>

                <div
                  className={`searchMenu-guests__field shadow-2  ${
                    isActiveGuest ? "-is-active" : ""
                  }`}
                >
                  <div className="bg-white px-30 py-30 rounded-4">
                    <div className="row y-gap-10 justify-between items-center">
                      <div className="col-auto">
                        <div className="text-15 fw-500">Adults</div>
                      </div>

                      <div className="col-auto">
                        <div className="d-flex items-center js-counter">
                          <button
                            className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                            onClick={() =>
                              adults > 1 && handleDecrement(setAdults)
                            }
                          >
                            <i className="icon-minus text-12"></i>
                          </button>

                          <div className="flex-center size-20 ml-15 mr-15">
                            <div className="text-15">{adults}</div>
                          </div>

                          <button
                            className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                            onClick={() => handleIncrement(setAdults)}
                          >
                            <i className="icon-plus text-12"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="border-top-light mt-24 mb-24"></div>

                    <div className="row y-gap-10 justify-between items-center">
                      <div className="col-auto">
                        <div className="text-15 lh-12 fw-500">Children</div>
                        <div className="text-14 lh-12 text-light-1 mt-5">
                          Ages: 2 to 11.99
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="d-flex items-center js-counter">
                          <button
                            className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                            onClick={() => handleDecrement(setChildren)}
                          >
                            <i className="icon-minus text-12"></i>
                          </button>

                          <div className="flex-center size-20 ml-15 mr-15">
                            <div className="text-15">{children}</div>
                          </div>

                          <button
                            className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                            onClick={() => handleIncrement(setChildren)}
                          >
                            <i className="icon-plus text-12"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="border-top-light mt-24 mb-24"></div>

                    <div className="row y-gap-10 justify-between items-center">
                      <div className="col-auto">
                        <div className="text-15 fw-500">Cabins / Suits</div>
                      </div>

                      <div className="col-auto">
                        <div className="d-flex items-center js-counter">
                          <button
                            className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                            onClick={() =>
                              rooms > 1 && handleDecrement(setRooms)
                            }
                          >
                            <i className="icon-minus text-12"></i>
                          </button>

                          <div className="flex-center size-20 ml-15 mr-15">
                            <div className="text-15">{rooms}</div>
                          </div>

                          <button
                            className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                            onClick={() => handleIncrement(setRooms)}
                          >
                            <i className="icon-plus text-12"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-item">
                <button
                  className="mainSearch__submit button -dark-1 h-60 px-35 col-12 rounded-100 bg-blue-1 text-white"
                  onClick={() => navigate("/booking-cruises")}
                >
                  <i className="icon-search text-20 mr-10"></i>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
