import React, { useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { colors, Slider } from "@mui/material";
import useGoogleMapsScript from "../components/useGoogleMapsScript";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const Error = () => {
  const mapRef = useRef(null);
  const googleMapsApiKey = "AIzaSyAAz77U5XQuEME6TpftaMdX0bBelQxXRlM"; // Replace with your actual API key

  useGoogleMapsScript(googleMapsApiKey); // Load the Google Maps script

  useEffect(() => {
    // Initialize the map once the script has loaded
    if (window.google && mapRef.current) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: -34.397, lng: 150.644 }, // Default center
        zoom: 8,
      });
    }
  }, []);
  return (
    <>
      {" "}
      <Helmet>
        <title>Error</title>{" "}
        <style>
          {`
            .header .header-menu .menu li a{
            color:#051036;
            }
            .header .header-menu .menu .subnav>li>a {
            color:#051036 !important;
            }
            .is-sticky.bg-dark-1{
            background-color:#fff !important;
            }
            // .is-sticky .menu li a{
            // color:#fff !important;
            // }
            .ratio-15\:9::before{
            padding-bottom:0 !important;}
            `}
        </style>
      </Helmet>
      {/*banner */}
      <section class="layout-pt-lg layout-pb-lg">
        <div class="container">
          <div class="row   y-gap-30 justify-between items-center">
            <div class="col-lg-5">
              <img src="titsolutions/img/general/404.svg" alt="" />
            </div>

            <div class="col-lg-5">
              <div class="no-page text-center">
                <div class="no-page__title">
                  <span class="text-red-1">404</span>
                </div>

                <h2 class="text-30 text-center  text-red-1 fw-600">
                  Oops! Page Not Found.{" "}
                </h2>

                <div class="    text-center mt-5">
                  The page you're looking isn't available.
                </div>

                <div class="d-inline-block   mt-40 md:mt-20">
                  <Link to="/" class="button  -md -dark-1 bg-red-1 text-white">
                    Go back to homepage{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Error;
