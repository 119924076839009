import React, { useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { colors, Slider } from "@mui/material";
import useGoogleMapsScript from "../components/useGoogleMapsScript";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const Checkout = () => {
  const navigat = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    navigat("/booking-confirmation");
  };
  return (
    <>
      {" "}
      <Helmet>
        <title>Checkout</title>{" "}
        <style>
          {`
            .header .header-menu .menu li a{
            color:#051036;
            }
            .header .header-menu .menu .subnav>li>a {
            color:#051036 !important;
            }
            .is-sticky.bg-dark-1{
            background-color:#fff !important;
            }
            // .is-sticky .menu li a{
            // color:#fff !important;
            // }
            .ratio-15\:9::before{
            padding-bottom:0 !important;}
            `}
        </style>
      </Helmet>
      {/*banner */}
      <section className="d-flex items-center  mt-90  ">
        <div className="container  mt-40 py-40 bg-light-2 border-top-light">
          <div className="row x-gap-40 y-gap-30 items-center px-50">
            <div className="col-auto px-1">
              <div className="d-flex items-center">
                <div className="size-40 rounded-full flex-center bg-blue-1">
                  <i className="icon-check text-16 text-white"></i>
                </div>
                <div className="text-18 fw-500 ml-10">Choose Cruise </div>
              </div>
            </div>{" "}
            <div className="col px-1">
              <div className="w-full h-1 bg-border"></div>
            </div>
            <div className="col-auto  px-1">
              <div className="d-flex items-center">
                <div className="size-40 rounded-full flex-center bg-blue-1">
                  <i className="icon-check text-16 text-white"></i>
                </div>
                <div className="text-18 fw-500 ml-10">Choose Cabin </div>
              </div>
            </div>
            <div className="col px-1">
              <div className="w-full h-1 bg-border"></div>
            </div>
            <div className="col-auto px-1">
              <div className="d-flex items-center">
                <div className="size-40 rounded-full flex-center bg-blue-1">
                  <i className="icon-check text-16 text-white"></i>
                </div>
                <div className="text-18 fw-500 ml-10">Extras selection </div>
              </div>
            </div>
            <div className="col px-1">
              <div className="w-full h-1 bg-border"></div>
            </div>
            <div className="col-auto px-1">
              <div className="d-flex items-center">
                <div className="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 fw-500">
                  4{" "}
                </div>
                <div className="text-18 fw-500 ml-10">Checkout </div>
              </div>
            </div>
            <div className="col px-1">
              <div className="w-full h-1 bg-border"></div>
            </div>
            <div className="col-auto px-1">
              <div className="d-flex items-center">
                <div className="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 fw-500">
                  5{" "}
                </div>
                <div className="text-18 fw-500 ml-10">Booking Completed! </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-40 layout-pb-md">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8">
              <h2 className="text-22 fw-500  ">Your Personal Information</h2>

              <form onSubmit={handleSubmit}>
                {" "}
                <div className="row x-gap-20 y-gap-20 pt-20">
                  <div className="col-12">
                    <div className="form-input ">
                      <input type="text" className="form-control" required="" />
                      <label className="lh-1 text-16 text-light-1">
                        Full Name{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-input ">
                      <input type="text" required="" />
                      <label className="lh-1 text-16 text-light-1">
                        Email{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-input ">
                      <input type="text" required="" />
                      <label className="lh-1 text-16 text-light-1">
                        Phone Number{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input ">
                      <input type="text" required="" />
                      <label className="lh-1 text-16 text-light-1">
                        Address line 1{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input ">
                      <input type="text" required="" />
                      <label className="lh-1 text-16 text-light-1">
                        Address line 2{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-input ">
                      <input type="text" required="" />
                      <label className="lh-1 text-16 text-light-1">
                        State/Province/Region{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-input ">
                      <input type="text" required="" />
                      <label className="lh-1 text-16 text-light-1">
                        ZIP code/Postal code{" "}
                      </label>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-input ">
                      <textarea required="" rows="6"></textarea>
                      <label className="lh-1 text-16 text-light-1">
                        Special Requests{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full h-1 bg-border mt-40 mb-40"></div>
                <div className="row y-gap-30  0">
                  <div className="col-12">
                    <div className="px-24 py-20 rounded-4 bg-green-1">
                      <div className="row x-gap-20 y-gap-20 items-center">
                        <div className="col-auto">
                          <div className="flex-center size-60 rounded-full bg-white">
                            <i className="icon-star text-yellow-1 text-30"></i>
                          </div>
                        </div>

                        <div className="col-auto">
                          <h4 className="text-18 lh-15 fw-500">
                            This property is in demand!{" "}
                          </h4>
                          <div className="text-15 lh-15">
                            7 travelers have booked .{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row y-gap-30 pt-10">
                  <div className="col-12">
                    <div className="px-24 py-20 rounded-4 bg-light-2">
                      <div className="row x-gap-20 y-gap-20 items-center">
                        <div className="col-auto">
                          <div className="flex-center size-60 rounded-full bg-white">
                            <i className="icon-star text-yellow-1 text-30"></i>
                          </div>
                        </div>

                        <div className="col-auto">
                          <h4 className="text-18 lh-15 fw-500">
                            Limited supply in London your dates:{" "}
                          </h4>
                          <div className="text-15 lh-15">
                            27 four-star hotels like are already unavailable on
                            site{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full h-1 bg-border mt-40 mb-40"></div>
                <div className="mt-40">
                  <h3 className="text-22 fw-500 mb-20">
                    How do you want to pay?{" "}
                  </h3>

                  <div className="row y-gap-20 x-gap-20">
                    <div className="col-auto">
                      <button className="button -dark-1 bg-blue-1 text-white px-20 py-15">
                        Credit/Debit Card{" "}
                      </button>
                    </div>

                    <div className="col-auto">
                      <button className="button -blue-1 bg-light-2 px-20 py-15">
                        Digital Payment{" "}
                      </button>
                    </div>
                  </div>

                  <div className="row x-gap-20 y-gap-20 pt-20">
                    <div className="col-12">
                      <div className="form-input ">
                        <input type="text" required="" />
                        <label className="lh-1 text-16 text-light-1">
                          Select payment method *{" "}
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-input ">
                        <input type="text" required="" />
                        <label className="lh-1 text-16 text-light-1">
                          Card holder name *{" "}
                        </label>
                      </div>

                      <div className="form-input mt-20">
                        <input type="text" required="" />
                        <label className="lh-1 text-16 text-light-1">
                          Credit/debit card number *{" "}
                        </label>
                      </div>

                      <div className="row x-gap-20 y-gap-20 pt-20">
                        <div className="col-md-6">
                          <div className="form-input ">
                            <input type="text" required="" />
                            <label className="lh-1 text-16 text-light-1">
                              Expiry date *{" "}
                            </label>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-input ">
                            <input type="text" required="" />
                            <label className="lh-1 text-16 text-light-1">
                              CVC/CVV *{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <img
                        src="titsolutions/img/booking-pages/card.png"
                        alt=""
                        className="h-full"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full h-1 bg-border mt-40 mb-40"></div>
                <div className="col-12">
                  <div className="row y-gap-20 items-center justify-between">
                    {" "}
                    <div className="form-checkbox d-flex align-items-center">
                      <input type="checkbox" name="name" id="terms" />
                      <div className="form-checkbox__mark">
                        <div className="form-checkbox__icon icon-check"></div>
                      </div>
                      <div className="col-auto ms-2">
                        <label htmlFor="terms" className="text-14 text-light-1">
                          By proceeding this booking, I agree Booking Nile{" "}
                          <Link to="#.">Terms & Conditions</Link>.
                        </label>
                      </div>
                    </div>
                    <div className="col-auto">
                      <button
                        type="submit"
                        className="button h-60 px-24 -dark-1 bg-blue-1 text-white"
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-xl-5 col-lg-4">
              <div className="ml-80 lg:ml-40 md:ml-0">
                <div className="px-30 py-30 border-light rounded-4">
                  <div className="text-20 fw-500 mb-30">
                    Your booking details{" "}
                  </div>

                  <div className="row x-gap-15 y-gap-20">
                    <div className="col-auto">
                      <img
                        src="/titsolutions/img/backgrounds/1.png"
                        alt=""
                        className="size-140 rounded-4 object-cover"
                      />
                    </div>

                    <div className="col">
                      <div className="d-flex x-gap-5 pb-10">
                        <i className="icon-star text-yellow-1 text-10"></i>

                        <i className="icon-star text-yellow-1 text-10"></i>

                        <i className="icon-star text-yellow-1 text-10"></i>

                        <i className="icon-star text-yellow-1 text-10"></i>

                        <i className="icon-star text-yellow-1 text-10"></i>
                      </div>

                      <div className="lh-17 fw-500">
                        Great Northern Hotel, a Portfolio Hotel, London{" "}
                      </div>
                      <div className="text-14 lh-15 mt-5">
                        Westminster Borough, London{" "}
                      </div>

                      <div className="row x-gap-10 y-gap-10 items-center pt-10">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="size-30 flex-center bg-blue-1 rounded-4">
                              <div className="text-12 fw-600 text-white">
                                4.8{" "}
                              </div>
                            </div>

                            <div className="text-14 fw-500 ml-10">
                              Exceptional{" "}
                            </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-14">3,014 reviews </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-top-light mt-30 mb-20"></div>

                  <div className="row y-gap-20 justify-between">
                    <div className="col-auto">
                      <div className="text-15">Check-in </div>
                      <div className="fw-500">Thu 21 Apr 2022 </div>
                      <div className="text-15 text-light-1">15:00 – 23:00 </div>
                    </div>

                    <div className="col-auto md:d-none">
                      <div className="h-full w-1 bg-border"></div>
                    </div>

                    <div className="col-auto text-right md:text-left">
                      <div className="text-15">Check-out </div>
                      <div className="fw-500">Sat 30 Apr 2022 </div>
                      <div className="text-15 text-light-1">01:00 – 11:00 </div>
                    </div>
                  </div>

                  <div className="border-top-light mt-30 mb-20"></div>

                  <div className="">
                    <div className="text-15">Total length of stay: </div>
                    <div className="fw-500">9 nights </div>
                    <Link to="#." className="text-15 text-blue-1 underline">
                      Travelling on different dates?{" "}
                    </Link>
                  </div>

                  <div className="border-top-light mt-30 mb-20"></div>

                  <div className="row y-gap-20 justify-between items-center">
                    <div className="col-auto">
                      <div className="text-15">You selected: </div>
                      <div className="fw-500">Superior Double Studio </div>
                      <Link to="#." className="text-15 text-blue-1 underline">
                        Change your selection{" "}
                      </Link>
                    </div>

                    <div className="col-auto">
                      <div className="text-15">1 room, 4 adult </div>
                    </div>
                  </div>
                </div>

                <div className="px-30 py-30 border-light rounded-4 mt-30">
                  <div className="text-20 fw-500 mb-20">
                    Your price summary{" "}
                  </div>

                  <div className="row y-gap-5 justify-between">
                    <div className="col-auto">
                      <div className="text-15">Superior Twin </div>
                    </div>
                    <div className="col-auto">
                      <div className="text-15">US$3,372.34 </div>
                    </div>
                  </div>

                  <div className="row y-gap-5 justify-between pt-5">
                    <div className="col-auto">
                      <div className="text-15">Taxes and fees </div>
                    </div>
                    <div className="col-auto">
                      <div className="text-15">US$674.47 </div>
                    </div>
                  </div>

                  <div className="row y-gap-5 justify-between pt-5">
                    <div className="col-auto">
                      <div className="text-15">Booking fees </div>
                    </div>
                    <div className="col-auto">
                      <div className="text-15">FREE </div>
                    </div>
                  </div>

                  <div className="px-20 py-20 bg-blue-2 rounded-4 mt-20">
                    <div className="row y-gap-5 justify-between">
                      <div className="col-auto">
                        <div className="text-18 lh-13 fw-500">Price </div>
                      </div>
                      <div className="col-auto">
                        <div className="text-18 lh-13 fw-500">US$4,046.81 </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="px-30 py-30 border-light rounded-4 mt-30">
                  <div className="text-20 fw-500 mb-20">
                    Your payment schedule{" "}
                  </div>

                  <div className="row y-gap-5 justify-between">
                    <div className="col-auto">
                      <div className="text-15">Before you stay you'll ___ </div>
                    </div>
                    <div className="col-auto">
                      <div className="text-15">US$4,047 </div>
                    </div>
                  </div>
                </div> */}

                <div className="px-30 py-30 border-light rounded-4 mt-30">
                  <div className="text-20 fw-500 mb-15">
                    Do you have a promo code?{" "}
                  </div>

                  <div className="form-input ">
                    <input type="text" required="" />
                    <label className="lh-1 text-16 text-light-1">
                      Enter promo code{" "}
                    </label>
                  </div>

                  <button className="button -outline-blue-1 text-blue-1 px-30 py-15 mt-20">
                    Apply{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Checkout;
