import React, { useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { colors, Slider } from "@mui/material";
import useGoogleMapsScript from "../components/useGoogleMapsScript";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const BookingConfirmation = () => {
  return (
    <>
      {" "}
      <Helmet>
        <title>Booking Confirmation</title>{" "}
        <style>
          {`
            .header .header-menu .menu li a{
            color:#051036;
            }
            .header .header-menu .menu .subnav>li>a {
            color:#051036 !important;
            }
            .is-sticky.bg-dark-1{
            background-color:#fff !important;
            }
            // .is-sticky .menu li a{
            // color:#fff !important;
            // }
            .ratio-15\:9::before{
            padding-bottom:0 !important;}
            `}
        </style>
      </Helmet>
      {/*banner */}
      <section className="d-flex items-center  mt-90  ">
        <div className="container  mt-40 py-40 bg-light-2 border-top-light">
          <div className="row x-gap-40 y-gap-30 items-center px-50">
            <div className="col-auto px-1">
              <div className="d-flex items-center">
                <div className="size-40 rounded-full flex-center bg-blue-1">
                  <i className="icon-check text-16 text-white"></i>
                </div>
                <div className="text-18 fw-500 ml-10">Choose Cruise </div>
              </div>
            </div>{" "}
            <div className="col px-1">
              <div className="w-full h-1 bg-border"></div>
            </div>
            <div className="col-auto  px-1">
              <div className="d-flex items-center">
                <div className="size-40 rounded-full flex-center bg-blue-1">
                  <i className="icon-check text-16 text-white"></i>
                </div>
                <div className="text-18 fw-500 ml-10">Choose Cabin </div>
              </div>
            </div>
            <div className="col px-1">
              <div className="w-full h-1 bg-border"></div>
            </div>
            <div className="col-auto px-1">
              <div className="d-flex items-center">
                <div className="size-40 rounded-full flex-center bg-blue-1">
                  <i className="icon-check text-16 text-white"></i>
                </div>
                <div className="text-18 fw-500 ml-10">Extras selection </div>
              </div>
            </div>
            <div className="col px-1">
              <div className="w-full h-1 bg-border"></div>
            </div>
            <div className="col-auto px-1">
              <div className="d-flex items-center">
                <div className="size-40 rounded-full flex-center bg-blue-1">
                  <i className="icon-check text-16 text-white"></i>
                </div>
                <div className="text-18 fw-500 ml-10">Checkout </div>
              </div>
            </div>
            <div className="col px-1">
              <div className="w-full h-1 bg-border"></div>
            </div>
            <div className="col-auto px-1">
              <div className="d-flex items-center">
                <div className="size-40 rounded-full flex-center bg-blue-1">
                  <i className="icon-check text-16 text-white"></i>
                </div>
                <div className="text-18 fw-500 ml-10">Thank You </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" layout-pb-md">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex flex-column items-center mt-60 lg:md-40 sm:mt-24">
                <div className="size-80 flex-center rounded-full bg-dark-3">
                  <i className="icon-check text-30 text-white"></i>
                </div>

                <div className="text-30 lh-1 fw-600 mt-20  ">
                  Payment Successfully Paid!{" "}
                </div>
                <div className="text-15 text-light-1 mt-10 col-lg-6 text-center">
                  Thank you for your payment. Your transaction has been
                  successfully completed. Booking details has been sent to:
                  useremail@bookingcore.test{" "}
                </div>
              </div>

              <div className="border-type-1 rounded-8 px-50 py-35 mt-40">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="text-15 lh-12">Order Number </div>
                    <div className="text-15 lh-12 fw-500 text-blue-1 mt-10">
                      13119{" "}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="text-15 lh-12">Date </div>
                    <div className="text-15 lh-12 fw-500 text-blue-1 mt-10">
                      27/07/2021{" "}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="text-15 lh-12">Total </div>
                    <div className="text-15 lh-12 fw-500 text-blue-1 mt-10">
                      $40.10{" "}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="text-15 lh-12">Payment Method </div>
                    <div className="text-15 lh-12 fw-500 text-blue-1 mt-10">
                      Direct Bank Transfer{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-light rounded-8 px-50 py-40 mt-40">
                <h4 className="text-20 fw-500 mb-30">Your Information </h4>

                <div className="row y-gap-10">
                  <div className="col-12">
                    <div className="d-flex justify-between ">
                      <div className="text-15 lh-16">First name </div>
                      <div className="text-15 lh-16 fw-500 text-blue-1">
                        System{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="d-flex justify-between border-top-light pt-10">
                      <div className="text-15 lh-16">Last name </div>
                      <div className="text-15 lh-16 fw-500 text-blue-1">
                        Admin{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="d-flex justify-between border-top-light pt-10">
                      <div className="text-15 lh-16">Email </div>
                      <div className="text-15 lh-16 fw-500 text-blue-1">
                        admin@bookingcore.test{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="d-flex justify-between border-top-light pt-10">
                      <div className="text-15 lh-16">Phone </div>
                      <div className="text-15 lh-16 fw-500 text-blue-1">
                        112 666 888{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="d-flex justify-between border-top-light pt-10">
                      <div className="text-15 lh-16">Address </div>
                      <div className="text-15 lh-16 fw-500 text-blue-1"></div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="d-flex justify-between border-top-light pt-10">
                      <div className="text-15 lh-16">City </div>
                      <div className="text-15 lh-16 fw-500 text-blue-1">
                        New York{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="d-flex justify-between border-top-light pt-10">
                      <div className="text-15 lh-16">
                        State/Province/Region{" "}
                      </div>
                      <div className="text-15 lh-16 fw-500 text-blue-1"></div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="d-flex justify-between border-top-light pt-10">
                      <div className="text-15 lh-16">ZIP code/Postal code </div>
                      <div className="text-15 lh-16 fw-500 text-blue-1"></div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="d-flex justify-between border-top-light pt-10">
                      <div className="text-15 lh-16">Country </div>
                      <div className="text-15 lh-16 fw-500 text-blue-1">
                        United States{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="d-flex justify-between border-top-light pt-10">
                      <div className="text-15 lh-16">Special Requirements </div>
                      <div className="text-15 lh-16 fw-500 text-blue-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookingConfirmation;
