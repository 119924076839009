import React, { useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { colors, Slider } from "@mui/material";
import useGoogleMapsScript from "../components/useGoogleMapsScript";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import Counter from "../components/Counter";
import Fancybox from "../components/fancybox-library/Fancybox";

const PrivacyPolicy = () => {
  return (
    <>
      {" "}
      <Helmet>
        <title>Privacy Policy</title>
        <style>
          {`.layout-pt-lg{
                padding-top: 250px;
                    padding-bottom: 180px;
          }
          `}
        </style>
      </Helmet>
      {/*banner */}
      <section className="section-bg layout-pt-lg layout-pb-lg">
        <div className="section-bg__item overlay col-12">
          <img src="bg.jpg" alt="banner" />
        </div>

        <div className="container">
          <div className="row justify-left text-start">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <h1 className="text-40 md:text-25 fw-600 text-white">
                Privacy Policy
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="d-flex items-center">
        <div className="container   py-20 border-bottom-light">
          <div className="row y-gap-10 items-center justify-between">
            <div className="col-auto">
              <div className="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div className="col-auto">
                  <div className="">
                    <Link to="/">Home</Link>{" "}
                  </div>
                </div>
                <div className="col-auto">
                  <div className="">{">"} </div>
                </div>
                <div className="col-auto">
                  <div className=""> Privacy Policy </div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              Quick Contact:{" "}
              <Link to="tel:+201000972210" className="text-14 text-light-1">
                +(20) 10 00972210{" "}
              </Link>
            </div>
          </div>
        </div>
      </section>{" "}
      <section class="layout-pt-md layout-pb-lg">
        <div class="container">
          <div class="tabs js-tabs">
            <div class="row y-gap-30">
              <div class="col-lg-12">
                <div class="tabs__content js-tabs-content">
                  <div class="tabs__pane -tab-item-1 is-tab-el-active">
                    <h1 class="text-30 fw-600 mb-15">Privacy Policy </h1>

                    <h2 class="text-16 fw-500">1. Your Agreement </h2>
                    <p class="text-15 text-dark-1 mt-5">
                      Lorem Ipsum is simply text of the printing typesetting
                      industry. Lorem Ipsum been the industry's standard text
                      ever since the 1500_, when an unknown printer _ a galley
                      of type scrambled it to make _ type specimen book. It
                      survived not only five _, but also the leap _ electronic
                      typesetting, remaining essentially _.
                      <br />
                      <br />
                      It was popularised in 1960s with the release __ Letraset
                      sheets containing Lorem passages, and more recently _
                      desktop publishing software like PageMaker including
                      versions of Ipsum.
                    </p>

                    <h2 class="text-16 fw-500 mt-35">
                      2. Change of Terms __ Use{" "}
                    </h2>
                    <p class="text-15 text-dark-1 mt-5">
                      Lorem Ipsum is simply text of the printing typesetting
                      industry. Lorem Ipsum been the industry's standard text
                      ever since the 1500_, when an unknown printer _ a galley
                      of type scrambled it to make _ type specimen book. It
                      survived not only five _, but also the leap _ electronic
                      typesetting, remaining essentially _.
                      <br />
                      <br />
                      It was popularised in 1960s with the release __ Letraset
                      sheets containing Lorem passages, and more recently _
                      desktop publishing software like PageMaker including
                      versions of Ipsum.
                    </p>

                    <h2 class="text-16 fw-500 mt-35">
                      3. Access and Use __ the Services{" "}
                    </h2>
                    <p class="text-15 text-dark-1 mt-5">
                      Lorem Ipsum is simply text of the printing typesetting
                      industry. Lorem Ipsum been the industry's standard text
                      ever since the 1500_, when an unknown printer _ a galley
                      of type scrambled it to make _ type specimen book. It
                      survived not only five _, but also the leap _ electronic
                      typesetting, remaining essentially _.
                      <br />
                      <br />
                      It was popularised in 1960s with the release __ Letraset
                      sheets containing Lorem passages, and more recently _
                      desktop publishing software like PageMaker including
                      versions of Ipsum.
                    </p>
                  </div>

                  <div class="tabs__pane -tab-item-2 ">
                    <h1 class="text-30 fw-600 mb-15">Terms And Conditions </h1>

                    <h2 class="text-16 fw-500">1. Your Agreement </h2>
                    <p class="text-15 text-dark-1 mt-5">
                      Lorem Ipsum is simply text of the printing typesetting
                      industry. Lorem Ipsum been the industry's standard text
                      ever since the 1500_, when an unknown printer _ a galley
                      of type scrambled it to make _ type specimen book. It
                      survived not only five _, but also the leap _ electronic
                      typesetting, remaining essentially _.
                      <br />
                      <br />
                      It was popularised in 1960s with the release __ Letraset
                      sheets containing Lorem passages, and more recently _
                      desktop publishing software like PageMaker including
                      versions of Ipsum.
                    </p>

                    <h2 class="text-16 fw-500 mt-35">
                      2. Change of Terms __ Use{" "}
                    </h2>
                    <p class="text-15 text-dark-1 mt-5">
                      Lorem Ipsum is simply text of the printing typesetting
                      industry. Lorem Ipsum been the industry's standard text
                      ever since the 1500_, when an unknown printer _ a galley
                      of type scrambled it to make _ type specimen book. It
                      survived not only five _, but also the leap _ electronic
                      typesetting, remaining essentially _.
                      <br />
                      <br />
                      It was popularised in 1960s with the release __ Letraset
                      sheets containing Lorem passages, and more recently _
                      desktop publishing software like PageMaker including
                      versions of Ipsum.
                    </p>

                    <h2 class="text-16 fw-500 mt-35">
                      3. Access and Use __ the Services{" "}
                    </h2>
                    <p class="text-15 text-dark-1 mt-5">
                      Lorem Ipsum is simply text of the printing typesetting
                      industry. Lorem Ipsum been the industry's standard text
                      ever since the 1500_, when an unknown printer _ a galley
                      of type scrambled it to make _ type specimen book. It
                      survived not only five _, but also the leap _ electronic
                      typesetting, remaining essentially _.
                      <br />
                      <br />
                      It was popularised in 1960s with the release __ Letraset
                      sheets containing Lorem passages, and more recently _
                      desktop publishing software like PageMaker including
                      versions of Ipsum.
                    </p>
                  </div>

                  <div class="tabs__pane -tab-item-3 ">
                    <h1 class="text-30 fw-600 mb-15">Terms And Conditions </h1>

                    <h2 class="text-16 fw-500">1. Your Agreement </h2>
                    <p class="text-15 text-dark-1 mt-5">
                      Lorem Ipsum is simply text of the printing typesetting
                      industry. Lorem Ipsum been the industry's standard text
                      ever since the 1500_, when an unknown printer _ a galley
                      of type scrambled it to make _ type specimen book. It
                      survived not only five _, but also the leap _ electronic
                      typesetting, remaining essentially _.
                      <br />
                      <br />
                      It was popularised in 1960s with the release __ Letraset
                      sheets containing Lorem passages, and more recently _
                      desktop publishing software like PageMaker including
                      versions of Ipsum.
                    </p>

                    <h2 class="text-16 fw-500 mt-35">
                      2. Change of Terms __ Use{" "}
                    </h2>
                    <p class="text-15 text-dark-1 mt-5">
                      Lorem Ipsum is simply text of the printing typesetting
                      industry. Lorem Ipsum been the industry's standard text
                      ever since the 1500_, when an unknown printer _ a galley
                      of type scrambled it to make _ type specimen book. It
                      survived not only five _, but also the leap _ electronic
                      typesetting, remaining essentially _.
                      <br />
                      <br />
                      It was popularised in 1960s with the release __ Letraset
                      sheets containing Lorem passages, and more recently _
                      desktop publishing software like PageMaker including
                      versions of Ipsum.
                    </p>

                    <h2 class="text-16 fw-500 mt-35">
                      3. Access and Use __ the Services{" "}
                    </h2>
                    <p class="text-15 text-dark-1 mt-5">
                      Lorem Ipsum is simply text of the printing typesetting
                      industry. Lorem Ipsum been the industry's standard text
                      ever since the 1500_, when an unknown printer _ a galley
                      of type scrambled it to make _ type specimen book. It
                      survived not only five _, but also the leap _ electronic
                      typesetting, remaining essentially _.
                      <br />
                      <br />
                      It was popularised in 1960s with the release __ Letraset
                      sheets containing Lorem passages, and more recently _
                      desktop publishing software like PageMaker including
                      versions of Ipsum.
                    </p>
                  </div>

                  <div class="tabs__pane -tab-item-4 ">
                    <h1 class="text-30 fw-600 mb-15">Terms And Conditions </h1>

                    <h2 class="text-16 fw-500">1. Your Agreement </h2>
                    <p class="text-15 text-dark-1 mt-5">
                      Lorem Ipsum is simply text of the printing typesetting
                      industry. Lorem Ipsum been the industry's standard text
                      ever since the 1500_, when an unknown printer _ a galley
                      of type scrambled it to make _ type specimen book. It
                      survived not only five _, but also the leap _ electronic
                      typesetting, remaining essentially _.
                      <br />
                      <br />
                      It was popularised in 1960s with the release __ Letraset
                      sheets containing Lorem passages, and more recently _
                      desktop publishing software like PageMaker including
                      versions of Ipsum.
                    </p>

                    <h2 class="text-16 fw-500 mt-35">
                      2. Change of Terms __ Use{" "}
                    </h2>
                    <p class="text-15 text-dark-1 mt-5">
                      Lorem Ipsum is simply text of the printing typesetting
                      industry. Lorem Ipsum been the industry's standard text
                      ever since the 1500_, when an unknown printer _ a galley
                      of type scrambled it to make _ type specimen book. It
                      survived not only five _, but also the leap _ electronic
                      typesetting, remaining essentially _.
                      <br />
                      <br />
                      It was popularised in 1960s with the release __ Letraset
                      sheets containing Lorem passages, and more recently _
                      desktop publishing software like PageMaker including
                      versions of Ipsum.
                    </p>

                    <h2 class="text-16 fw-500 mt-35">
                      3. Access and Use __ the Services{" "}
                    </h2>
                    <p class="text-15 text-dark-1 mt-5">
                      Lorem Ipsum is simply text of the printing typesetting
                      industry. Lorem Ipsum been the industry's standard text
                      ever since the 1500_, when an unknown printer _ a galley
                      of type scrambled it to make _ type specimen book. It
                      survived not only five _, but also the leap _ electronic
                      typesetting, remaining essentially _.
                      <br />
                      <br />
                      It was popularised in 1960s with the release __ Letraset
                      sheets containing Lorem passages, and more recently _
                      desktop publishing software like PageMaker including
                      versions of Ipsum.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
