import React, { useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { colors, Slider } from "@mui/material";
import useGoogleMapsScript from "../components/useGoogleMapsScript";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import Counter from "../components/Counter";
import Fancybox from "../components/fancybox-library/Fancybox";

const BecomePartner = () => {
  const [activeFaq, setActiveFaq] = useState(null);

  // Function to toggle accordion item
  const toggleAccordion = (index) => {
    setActiveFaq(activeFaq === index ? null : index);
  };
  const faqItems = [
    {
      question: "What do I need to hire a car?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "How old do I have to be to rent a car?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Can I book a rental car for someone else?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "How do I find the cheapest car hire deal?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "What should I look for when choosing a car hire?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];
  return (
    <>
      {" "}
      <Helmet>
        <title>Become Partner</title>
        <style>
          {`.layout-pt-lg{
                padding-top: 250px;
                    padding-bottom: 250px;
          }
          `}
        </style>
      </Helmet>
      {/*banner */}
      <section className="section-bg layout-pt-lg layout-pb-lg">
        <div className="section-bg__item overlay col-12">
          <img src="about-bg.jpg" alt="banner" />
        </div>

        {/* <div className="container">
          <div className="row justify-left text-start">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <h1 className="text-40 md:text-25 fw-600 text-white">
                Looking To Be Partner?{" "}
              </h1>
              <div className="col-lg-3 mt-3">
                <Link
                  to="#."
                  className="button px-24 h-50 -dark-1 bg-blue-1 text-white"
                >
                  Join Us <div className="icon-arrow-top-right ps-2"></div>
                </Link>
              </div>
            </div>
          </div>
        </div> */}
      </section>
      <section className="d-flex items-center">
        <div className="container   py-20 border-bottom-light">
          <div className="row y-gap-10 items-center justify-between">
            <div className="col-auto">
              <div className="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div className="col-auto">
                  <div className="">
                    <Link to="/">Home</Link>{" "}
                  </div>
                </div>
                <div className="col-auto">
                  <div className="">{">"} </div>
                </div>
                <div className="col-auto">
                  <div className="">Become Partner </div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              Quick Contact:{" "}
              <Link to="tel:+201000972210" className="text-14 text-light-1">
                +(20) 10 00972210{" "}
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-60 layout-pb-lg">
        <div className="container">
          <div className="row y-gap-20 justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">How does it work? </h2>
                <p className=" sectionTitle__text mt-5 sm:mt-0">
                  These popular destinations have _ lot to offer{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="row y-gap-30 justify-between pt-40">
            <div className="col-xl-2 col-lg-3 col-md-6">
              <div className="d-flex flex-column items-center text-center">
                <div className="relative size-120 flex-center rounded-full bg-green-1">
                  <img
                    src="titsolutions/img/pages/become-expert/icons/1.svg"
                    alt="image"
                  />

                  <div className="side-badge">
                    <div className="size-40 flex-center rounded-full bg-yellow-1">
                      <span className="text-15 fw-500">01 </span>
                    </div>
                  </div>
                </div>
                <div className="text-18 fw-500 mt-30 sm:mt-20">Sign up </div>
              </div>
            </div>

            <div className="col-auto xl:d-none">
              <div className="pt-30">
                <img
                  src="titsolutions/img/pages/become-expert/lines/1.svg"
                  alt="icon"
                />
              </div>
            </div>

            <div className="col-xl-2 col-lg-3 col-md-6">
              <div className="d-flex flex-column items-center text-center">
                <div className="relative size-120 flex-center rounded-full bg-green-1">
                  <img
                    src="titsolutions/img/pages/become-expert/icons/2.svg"
                    alt="image"
                  />

                  <div className="side-badge">
                    <div className="size-40 flex-center rounded-full bg-yellow-1">
                      <span className="text-15 fw-500">02 </span>
                    </div>
                  </div>
                </div>
                <div className="text-18 fw-500 mt-30 sm:mt-20">
                  Add your services{" "}
                </div>
              </div>
            </div>

            <div className="col-auto xl:d-none">
              <div className="pt-30">
                <img
                  src="titsolutions/img/pages/become-expert/lines/2.svg"
                  alt="icon"
                />
              </div>
            </div>

            <div className="col-xl-2 col-lg-3 col-md-6">
              <div className="d-flex flex-column items-center text-center">
                <div className="relative size-120 flex-center rounded-full bg-green-1">
                  <img
                    src="titsolutions/img/pages/become-expert/icons/3.svg"
                    alt="image"
                  />

                  <div className="side-badge">
                    <div className="size-40 flex-center rounded-full bg-yellow-1">
                      <span className="text-15 fw-500">03 </span>
                    </div>
                  </div>
                </div>
                <div className="text-18 fw-500 mt-30 sm:mt-20">
                  Get bookings{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-bg layout-pt-lg layout-pb-lg md:pt-0 md:pb-60 sm:pb-40 bg-blue-2">
        <div className="section-bg__item -right -image col-5 md:mb-60 sm:mb-40 d-flex z-2">
          <img src="titsolutions/img/backgrounds/10.png" alt="image" />

          <div className="absolute col-12 h-full flex-center z-1">
            <Fancybox>
              <a
                href="https://www.youtube.com/watch?v=ANYfx4-jyqY"
                className="d-flex items-center js-gallery"
                data-fancybox="gallery1"
                style={{ textDecoration: "none" }}
              >
                <span className="button -outline-white text-white size-50 rounded-full flex-center">
                  <i className="icon-play text-16"></i>
                </span>
                <span className="fw-500 text-white ml-15">Watch Video </span>
              </a>
            </Fancybox>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-7">
              <h2 className="text-30 fw-600">Why be a Local ______ </h2>
              <p className="mt-5">
                These popular destinations have _ lot to offer{" "}
              </p>

              <div className="row y-gap-30 pt-60 md:pt-40">
                <div className="col-12">
                  <div className="d-flex pr-30">
                    <img
                      className="size-50"
                      src="titsolutions/img/featureIcons/1/1.svg"
                      alt="image"
                    />

                    <div className="ml-15">
                      <h4 className="text-18 fw-500">Best Price Guarantee </h4>
                      <p className="text-15 mt-10">
                        Lorem ipsum dolor sit ____, consectetur adipiscing elit.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="d-flex pr-30">
                    <img
                      className="size-50"
                      src="titsolutions/img/featureIcons/1/2.svg"
                      alt="image"
                    />

                    <div className="ml-15">
                      <h4 className="text-18 fw-500">Easy & Quick Booking </h4>
                      <p className="text-15 mt-10">
                        Lorem ipsum dolor sit ____, consectetur adipiscing elit.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="d-flex pr-30">
                    <img
                      className="size-50"
                      src="titsolutions/img/featureIcons/1/3.svg"
                      alt="image"
                    />

                    <div className="ml-15">
                      <h4 className="text-18 fw-500">Customer Care 24/7 </h4>
                      <p className="text-15 mt-10">
                        Lorem ipsum dolor sit ____, consectetur adipiscing elit.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="faq" className="pt-40 layout-pb-lg">
        <div className="container">
          <div className="pt-40  ">
            <div class="row justify-center text-center">
              <div class="col-auto">
                <div class="sectionTitle -md">
                  <h2 class="sectionTitle__title">
                    Frequently Asked Questions{" "}
                  </h2>
                  <p class=" sectionTitle__text mt-5 sm:mt-0">
                    Interdum et malesuada fames{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row y-gap-30 justify-center pt-40 sm:pt-20">
              <div className="col-xl-8 col-lg-10">
                <div className="accordion -simple row y-gap-20">
                  {faqItems.map((item, index) => (
                    <div className="col-12" key={index}>
                      <div className="accordion__item px-20 py-20 border-light rounded-4 ">
                        <div
                          className="accordion__button d-flex items-center "
                          onClick={() => toggleAccordion(index)}
                        >
                          <div className="accordion__icon size-40 flex-center bg-light-2 rounded-full mr-20">
                            <i
                              className={
                                activeFaq === index ? "icon-minus" : "icon-plus"
                              }
                            ></i>
                          </div>
                          <div className="button text-dark-1">
                            {item.question}
                          </div>
                        </div>
                        {activeFaq === index && (
                          <div
                            className="accordion__content"
                            style={{ maxHeight: "100%" }}
                          >
                            <div className="pt-20 pl-60">
                              <p className="text-15">{item.answer}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BecomePartner;
