import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./ScrollToTop.jsx";
import Layout from "./components/Layout";
import PageTracker from "./components/PageTracker.js";
import "./App.css";
import { setLanguage } from "./data/languageSlice.js";
import Home from "./pages/Home.jsx";
import ListingCruises from "./pages/ListingCruises.jsx";
import ContactUs from "./pages/ContactUs.jsx";
import CruisesDetails from "./pages/CruisesDetails.jsx";
import Checkout from "./pages/Checkout.jsx";
import BookingConfirmation from "./pages/BookingConfirmation.jsx";
import AddExtra from "./pages/AddExtra.jsx";
import AboutUs from "./pages/AboutUs.jsx";
import BecomePartner from "./pages/BecomePartner.jsx";
import HelpCenter from "./pages/HelpCenter.jsx";
import TermsAndConditions from "./pages/TermsAndConditions.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx";
import HowWeWork from "./pages/HowWeWork.jsx";
import Error from "./pages/Error.jsx";
import Signup from "./pages/Signup.jsx";
import Signin from "./pages/Signin.jsx";
import Test from "./pages/Test.jsx";
import MagazineViewer from "./pages/Test2.jsx";

// import CruiseBooking from "./components/cruiseBooking/CruiseBooking.jsx";
// import CabinBooking from "./components/cruiseBooking/CabinBooking.jsx";
// import InsertCstData from "./components/cruiseBooking/InsertCstData.jsx";
// import ThanksPage from "./components/cabinsBooking/ThanksPage.jsx";
// import BookingSearch from "./pages/BookingSearch.jsx";
// import AddExtra from "./components/cruiseBooking/AddExtra.jsx";

function App() {
  const language = useSelector((state) => state.language.language);
  const [basename, setBasename] = useState("");

  const dispatch = useDispatch();
  // useEffect(() => {
  //   const currentUrl = new URL(window.location.href);
  //   const pathSegments = currentUrl.pathname.split("/").filter(Boolean); // Split and remove empty segments

  //   if (
  //     pathSegments.length > 0 &&
  //     (pathSegments[0] === "en" || pathSegments[0] === "ar")
  //   ) {
  //     dispatch(setLanguage(pathSegments[0]));
  //     setBasename(pathSegments[0]);
  //   } else {
  //     window.location.href = `${currentUrl.origin}/en${currentUrl.pathname}${currentUrl.search}`;
  //   }
  //   const head = document.head;
  //   const existingLink = document.getElementById("rtl-stylesheet");

  //   if (language === "ar") {
  //     if (!existingLink) {
  //       const link = document.createElement("link");
  //       link.id = "rtl-stylesheet"; // Assign an ID for easy access
  //       link.type = "text/css";
  //       link.rel = "stylesheet";
  //       link.href = "bootstrap-rtl.min.css";
  //       head.appendChild(link);
  //     }
  //   } else {
  //     if (existingLink) {
  //       head.removeChild(existingLink);
  //     }
  //   }
  // }, [language, dispatch, setBasename]);
  return (
    <HelmetProvider>
      <Router basename="/demo">
        <PageTracker />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="booking-cruises" element={<ListingCruises />} />
            <Route path="cruises-details" element={<CruisesDetails />} />
            <Route path="add-extra" element={<AddExtra />} />
            <Route path="checkout" element={<Checkout />} />
            <Route
              path="booking-confirmation"
              element={<BookingConfirmation />}
            />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="become-partner" element={<BecomePartner />} />
            <Route path="help-center" element={<HelpCenter />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route
              path="terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="how-we-work" element={<HowWeWork />} />
            <Route path="signup" element={<Signup />} />
            <Route path="signin" element={<Signin />} />
          </Route>
          {/* <Route path="*" element={<Navigate to="/" />} /> */}
          <Route path="*" element={<Error />} />
          <Route path="test" element={<Test />} />
          <Route path="test2" element={<MagazineViewer />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;

/*<Route path="booking-cruises" element={<CruiseBooking />} />
<Route path="booking-cabins" element={<CabinBooking />} />
<Route path="add-extra" element={<AddExtra />} />
<Route path="booking-information" element={<InsertCstData />} />
<Route path="booking-completed" element={<ThanksPage />} />*/
