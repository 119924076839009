import React, { Fragment, useEffect, useState } from "react";
import { Outlet, Link, NavLink, useLocation } from "react-router-dom";

import { Zoom } from "react-awesome-reveal";
import { subscribeNewsletter } from "../data/newsLetterSlice";
import { useDispatch, useSelector } from "react-redux";
import { error } from "jquery";
import { Alert, Snackbar } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faGlobeAfrica,
  faHeadset,
  faHome,
  faLocationDot,
  faMapLocationDot,
  faPhone,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { setLanguage } from "../data/languageSlice";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faSquareInstagram,
  faSquareXTwitter,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Layout = () => {
  const currentYear = new Date().getFullYear();
  const [showButton, setShowButton] = useState(false);
  const language = useSelector((state) => state.language.language);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      // Convert 70vh to pixels
      const triggerHeight = window.innerHeight * 0.2;
      const triggerHeight2 = window.innerHeight * 0.1;

      if (window.scrollY > triggerHeight) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
      if (window.scrollY > triggerHeight2) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    // Add event listener
    window.addEventListener("scroll", checkScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);
  const [isVisible, setIsVisible] = useState(false);
  const [view1, setView1] = useState(false);
  const [view2, setView2] = useState(false);
  const [view3, setView3] = useState(false);
  // Function to handle the scroll to top action
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Function to check scroll position
  const checkScrollTop = () => {
    if (!isVisible && window.pageYOffset > 400) {
      setIsVisible(true);
    } else if (isVisible && window.pageYOffset <= 400) {
      setIsVisible(false);
    }
  };

  // Listen for scroll events
  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  });
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { loadingNewsletter, errorNewsletter } = useSelector(
    (state) => state.newsLetter
  );
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the menu open and closed
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(true);
    dispatch(subscribeNewsletter(email));
  };
  const toggleLanguage = () => {
    const newLanguage = language === "en" ? "ar" : "en";
    dispatch(setLanguage(newLanguage));
    window.location.pathname = `/${newLanguage}${window.location.pathname.substring(
      3
    )}`;
  };
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    // Check if the window's scroll position is greater than a certain value (e.g., 50px)
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [viewCurrency, setViewCurrency] = useState(true);
  const [viewLanguage, setViewLanguage] = useState(true);
  return (
    <>
      {" "}
      <section className="header-banner py-5 bg-blue-1 z-9">
        <div className="container">
          <div className="row items-center justify-between">
            <div className="col-auto">
              <div className="row x-gap-15 y-gap-15 items-center">
                <div className="col-auto md:d-none">
                  <Link to="tel:+201000972210" className="text-12 text-white">
                    +(20) 10 00972210{" "}
                  </Link>
                </div>

                <div className="col-auto md:d-none">
                  <div className="w-1 h-20 bg-white-20"></div>
                </div>

                <div className="col-auto">
                  <Link
                    to="mailto:info@bookingnile.com"
                    className="text-12 text-white"
                  >
                    info@bookingnile.com{" "}
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-auto">
              <div className="row x-gap-15 items-center jusify-between">
                <div className="col-auto">
                  <div className="row x-gap-20 items-center xxl:d-none">
                    <div className="col-auto">
                      <button
                        className="d-flex items-center text-14 text-white"
                        data-x-click="currency"
                        onClick={() => setViewCurrency(!viewCurrency)}
                      >
                        <span className="js-currencyMenu-mainTitle">USD </span>
                        <i className="icon-chevron-sm-down text-7 ml-10"></i>
                      </button>
                    </div>

                    <div className="col-auto">
                      <div className="w-1 h-20 bg-white-20"></div>
                    </div>

                    <div className="col-auto">
                      <button
                        className="d-flex items-center text-14 text-white"
                        onClick={() => setViewLanguage(!viewLanguage)}
                      >
                        <img
                          src="/titsolutions/img/general/lang.png"
                          alt="image"
                          className="rounded-full mr-10"
                        />
                        <span className="js-language-mainTitle">
                          United Kingdom{" "}
                        </span>
                        <i className="icon-chevron-sm-down text-7 ml-15"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-auto xxl:d-none">
                  <div className="w-1 h-20 bg-white-20"></div>
                </div>

                <div className="col-auto md:d-none">
                  <Link to="/become-partner" className="text-12 text-white">
                    Become Partner{" "}
                  </Link>
                </div>

                <div className="col-auto md:d-none">
                  <div className="w-1 h-20 bg-white-20"></div>
                </div>

                <div className="col-auto">
                  <Link to="/signup" className="text-12 text-white">
                    Sign In / Register{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className={`currencyMenu  js-currencyMenu ${
          viewCurrency && "is-hidden"
        }`}
      >
        <div
          className="currencyMenu__bg"
          data-x-click="currency"
          onClick={() => setViewCurrency(true)}
        ></div>

        <div className="currencyMenu__content bg-white rounded-4">
          <div className="d-flex items-center justify-between px-30 py-20 sm:px-15 border-bottom-light">
            <div className="text-20 fw-500 lh-15">Select your currency </div>
            <button className="pointer" onClick={() => setViewCurrency(true)}>
              <i className="icon-close"></i>
            </button>
          </div>

          <div className="modalGrid px-30 py-30 sm:px-15 sm:py-15">
            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  United States dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">USD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Australian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">AUD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Brazilian real{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BRL </span>- R$
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Bulgarian lev{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BGN </span>- лв.
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Canadian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">CAD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  United States dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">USD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Australian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">AUD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Brazilian real{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BRL </span>- R$
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Bulgarian lev{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BGN </span>- лв.
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Canadian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">CAD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  United States dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">USD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Australian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">AUD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Brazilian real{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BRL </span>- R$
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Bulgarian lev{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BGN </span>- лв.
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Canadian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">CAD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  United States dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">USD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Australian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">AUD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Brazilian real{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BRL </span>- R$
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Bulgarian lev{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BGN </span>- лв.
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Canadian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">CAD </span>- $
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`langMenu  js-langMenu ${viewLanguage && "is-hidden"}`}>
        <div
          className="langMenu__bg"
          onClick={() => setViewLanguage(true)}
        ></div>

        <div className="langMenu__content bg-white rounded-4">
          <div className="d-flex items-center justify-between px-30 py-20 sm:px-15 border-bottom-light">
            <div className="text-20 fw-500 lh-15">Select your language </div>
            <button className="pointer" onClick={() => setViewLanguage(true)}>
              <i className="icon-close"></i>
            </button>
          </div>

          <div className="modalGrid px-30 py-30 sm:px-15 sm:py-15">
            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">English </div>
                <div className="text-14 lh-15 mt-5 js-title">
                  United States{" "}
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Türkçe </div>
                <div className="text-14 lh-15 mt-5 js-title">Turkey </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Español </div>
                <div className="text-14 lh-15 mt-5 js-title">España </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Français{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">France </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Italiano{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">Italia </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">English </div>
                <div className="text-14 lh-15 mt-5 js-title">
                  United States{" "}
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Türkçe </div>
                <div className="text-14 lh-15 mt-5 js-title">Turkey </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Español </div>
                <div className="text-14 lh-15 mt-5 js-title">España </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Français{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">France </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Italiano{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">Italia </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">English </div>
                <div className="text-14 lh-15 mt-5 js-title">
                  United States{" "}
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Türkçe </div>
                <div className="text-14 lh-15 mt-5 js-title">Turkey </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Español </div>
                <div className="text-14 lh-15 mt-5 js-title">España </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Français{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">France </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Italiano{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">Italia </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">English </div>
                <div className="text-14 lh-15 mt-5 js-title">
                  United States{" "}
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Türkçe </div>
                <div className="text-14 lh-15 mt-5 js-title">Turkey </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Español </div>
                <div className="text-14 lh-15 mt-5 js-title">España </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Français{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">France </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Italiano{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">Italia </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header
        className={`header mt-40 -type-9 js-header ${
          isSticky ? "bg-dark-1 is-sticky" : ""
        }`}
      >
        <div className="header__container container">
          <div className="row justify-between items-center">
            <div className="col-auto">
              <div className="d-flex items-center">
                <Link
                  to="/"
                  className="header-logo mr-20"
                  data-x="header-logo"
                  data-x-toggle="is-logo-dark"
                >
                  {window.location.pathname.includes("/booking") ||
                  window.location.pathname.includes("/contact-us") ||
                  window.location.pathname.includes("/checkout") ||
                  window.location.pathname.includes("/add-extra") ||
                  window.location.pathname.includes("/help-center") ||
                  window.location.pathname.includes("/signup") ||
                  window.location.pathname.includes("/signin") ||
                  window.location.pathname.includes("/cruises-details") ? (
                    <img src="bookingnile.png" alt="logo icon" />
                  ) : (
                    <img src="bookingnilewhite.png" alt="logo icon" />
                  )}
                </Link>
              </div>
            </div>

            <div className="col-auto">
              <div className="d-flex items-center">
                <div
                  className="header-menu "
                  data-x="mobile-menu"
                  data-x-toggle="is-menu-active"
                >
                  <div className="mobile-overlay"></div>

                  <div className="header-menu__content">
                    <div className="mobile-bg js-mobile-bg"></div>

                    <div className="menu js-navList">
                      <ul className="menu__nav text-white -is-active">
                        {/* <li className="menu-item-has-children">
                          <a data-barba="" href="home-9.html">
                            <span className="mr-10">Home </span>
                            <i className="icon icon-chevron-sm-down"></i>
                          </a>

                          <ul className="subnav">
                            <li className="subnav__backBtn js-nav-list-back">
                              <a href="#">
                                <i className="icon icon-chevron-sm-down"></i>{" "}
                                Home{" "}
                              </a>
                            </li>

                            <li>
                              <a href="index.html">Home 1 </a>
                            </li>

                            <li>
                              <a href="home-2.html">Home 2 </a>
                            </li>

                            <li>
                              <a href="home-3.html">Home 3 </a>
                            </li>

                            <li>
                              <a href="home-4.html">Home 4 </a>
                            </li>

                            <li>
                              <a href="home-5.html">Home 5 </a>
                            </li>

                            <li>
                              <a href="home-6.html">Home 6 </a>
                            </li>

                            <li>
                              <a href="home-7.html">Home 7 </a>
                            </li>

                            <li>
                              <a href="home-8.html">Home 8 </a>
                            </li>

                            <li>
                              <a href="home-9.html">Home 9 </a>
                            </li>

                            <li>
                              <a href="home-10.html">Home 10 </a>
                            </li>
                          </ul>
                        </li>

                        <li className="menu-item-has-children -has-mega-menu">
                          <a data-barba="" href="#">
                            <span className="mr-10">Categories </span>
                            <i className="icon icon-chevron-sm-down"></i>
                          </a>

                          <div className="mega">
                            <div className="tabs -underline-2 js-tabs">
                              <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 pb-30 js-tabs-controls">
                                <div className="col-auto">
                                  <button
                                    className="tabs__button text-light-1 fw-500 js-tabs-button is-tab-el-active"
                                    data-tab-target=".-tab-item-1"
                                  >
                                    Hotel{" "}
                                  </button>
                                </div>

                                <div className="col-auto">
                                  <button
                                    className="tabs__button text-light-1 fw-500 js-tabs-button "
                                    data-tab-target=".-tab-item-2"
                                  >
                                    Tour{" "}
                                  </button>
                                </div>

                                <div className="col-auto">
                                  <button
                                    className="tabs__button text-light-1 fw-500 js-tabs-button "
                                    data-tab-target=".-tab-item-3"
                                  >
                                    Activity{" "}
                                  </button>
                                </div>

                                <div className="col-auto">
                                  <button
                                    className="tabs__button text-light-1 fw-500 js-tabs-button "
                                    data-tab-target=".-tab-item-4"
                                  >
                                    Holiday Rentals{" "}
                                  </button>
                                </div>

                                <div className="col-auto">
                                  <button
                                    className="tabs__button text-light-1 fw-500 js-tabs-button "
                                    data-tab-target=".-tab-item-5"
                                  >
                                    Car{" "}
                                  </button>
                                </div>

                                <div className="col-auto">
                                  <button
                                    className="tabs__button text-light-1 fw-500 js-tabs-button "
                                    data-tab-target=".-tab-item-6"
                                  >
                                    Cruise{" "}
                                  </button>
                                </div>

                                <div className="col-auto">
                                  <button
                                    className="tabs__button text-light-1 fw-500 js-tabs-button "
                                    data-tab-target=".-tab-item-7"
                                  >
                                    Flights{" "}
                                  </button>
                                </div>
                              </div>

                              <div className="tabs__content js-tabs-content">
                                <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                  <div className="mega__content">
                                    <div className="mega__grid">
                                      <div className="mega__item">
                                        <div className="text-15 fw-500">
                                          Hotel List{" "}
                                        </div>
                                        <div className="y-gap-5 text-15 pt-5">
                                          <div>
                                            <a href="hotel-list-1.html">
                                              Hotel List v1{" "}
                                            </a>
                                          </div>

                                          <div>
                                            <a href="hotel-list-2.html">
                                              Hotel List v2{" "}
                                            </a>
                                          </div>

                                          <div>
                                            <a href="hotel-half-map.html">
                                              Hotel List v3{" "}
                                            </a>
                                          </div>

                                          <div>
                                            <a href="hotel-grid-1.html">
                                              Hotel List v4{" "}
                                            </a>
                                          </div>

                                          <div>
                                            <a href="hotel-grid-2.html">
                                              Hotel List v5{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mega__item">
                                        <div className="text-15 fw-500">
                                          Hotel Single{" "}
                                        </div>
                                        <div className="y-gap-5 text-15 pt-5">
                                          <div>
                                            <a href="hotel-single-1.html">
                                              Hotel Single v1{" "}
                                            </a>
                                          </div>

                                          <div>
                                            <a href="hotel-single-2.html">
                                              Hotel Single v2{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mega__item">
                                        <div className="text-15 fw-500">
                                          Hotel Booking{" "}
                                        </div>
                                        <div className="y-gap-5 text-15 pt-5">
                                          <div>
                                            <a href="booking-pages.html">
                                              Booking Page{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="mega__image d-flex relative">
                                      <img
                                        src="#"
                                        data-src="/titsolutions/img/backgrounds/7.png"
                                        alt="image"
                                        className="rounded-4 js-lazy"
                                      />

                                      <div className="absolute w-full h-full px-30 py-24">
                                        <div className="text-22 fw-500 lh-15 text-white">
                                          Things to do on <br /> your trip{" "}
                                        </div>
                                        <button className="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">
                                          Experinces{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="tabs__pane -tab-item-2">
                                  <div className="mega__content">
                                    <div className="mega__grid">
                                      <div className="mega__item">
                                        <div className="text-15 fw-500">
                                          Tour List{" "}
                                        </div>
                                        <div className="y-gap-5 text-15 pt-5">
                                          <div>
                                            <a href="tour-list-1.html">
                                              Tour List v1{" "}
                                            </a>
                                          </div>

                                          <div>
                                            <a href="tour-grid-1.html">
                                              Tour List v2{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mega__item">
                                        <div className="text-15 fw-500">
                                          Tour Pages{" "}
                                        </div>
                                        <div className="y-gap-5 text-15 pt-5">
                                          <div>
                                            <a href="tour-map.html">
                                              Tour Map{" "}
                                            </a>
                                          </div>

                                          <div>
                                            <a href="tour-single.html">
                                              Tour Single{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="mega__image d-flex relative">
                                      <img
                                        src="/titsolutions/img/backgrounds/7.png"
                                        alt="image"
                                        className="rounded-4"
                                      />

                                      <div className="absolute w-full h-full px-30 py-24">
                                        <div className="text-22 fw-500 lh-15 text-white">
                                          Things to do on <br /> your trip{" "}
                                        </div>
                                        <button className="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">
                                          Experinces{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="tabs__pane -tab-item-3">
                                  <div className="mega__content">
                                    <div className="mega__grid">
                                      <div className="mega__item">
                                        <div className="text-15 fw-500">
                                          Activity List{" "}
                                        </div>
                                        <div className="y-gap-5 text-15 pt-5">
                                          <div>
                                            <a href="activity-list-1.html">
                                              Activity List v1{" "}
                                            </a>
                                          </div>

                                          <div>
                                            <a href="activity-grid-1.html">
                                              Activity List v2{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mega__item">
                                        <div className="text-15 fw-500">
                                          Activity Pages{" "}
                                        </div>
                                        <div className="y-gap-5 text-15 pt-5">
                                          <div>
                                            <a href="activity-map.html">
                                              Activity Map{" "}
                                            </a>
                                          </div>

                                          <div>
                                            <a href="activity-single.html">
                                              Activity Single{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="mega__image d-flex relative">
                                      <img
                                        src="/titsolutions/img/backgrounds/7.png"
                                        alt="image"
                                        className="rounded-4"
                                      />

                                      <div className="absolute w-full h-full px-30 py-24">
                                        <div className="text-22 fw-500 lh-15 text-white">
                                          Things to do on <br /> your trip{" "}
                                        </div>
                                        <button className="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">
                                          Experinces{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="tabs__pane -tab-item-4">
                                  <div className="mega__content">
                                    <div className="mega__grid">
                                      <div className="mega__item">
                                        <div className="text-15 fw-500">
                                          Rental List{" "}
                                        </div>
                                        <div className="y-gap-5 text-15 pt-5">
                                          <div>
                                            <a href="rental-list-1.html">
                                              Rental List v1{" "}
                                            </a>
                                          </div>

                                          <div>
                                            <a href="rental-grid-1.html">
                                              Rental List v2{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mega__item">
                                        <div className="text-15 fw-500">
                                          Rental Pages{" "}
                                        </div>
                                        <div className="y-gap-5 text-15 pt-5">
                                          <div>
                                            <a href="rental-map.html">
                                              Rental Map{" "}
                                            </a>
                                          </div>

                                          <div>
                                            <a href="rental-single.html">
                                              Rental Single{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="mega__image d-flex relative">
                                      <img
                                        src="/titsolutions/img/backgrounds/7.png"
                                        alt="image"
                                        className="rounded-4"
                                      />

                                      <div className="absolute w-full h-full px-30 py-24">
                                        <div className="text-22 fw-500 lh-15 text-white">
                                          Things to do on <br /> your trip{" "}
                                        </div>
                                        <button className="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">
                                          Experinces{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="tabs__pane -tab-item-5">
                                  <div className="mega__content">
                                    <div className="mega__grid">
                                      <div className="mega__item">
                                        <div className="text-15 fw-500">
                                          Car List{" "}
                                        </div>
                                        <div className="y-gap-5 text-15 pt-5">
                                          <div>
                                            <a href="car-list-1.html">
                                              Car List v1{" "}
                                            </a>
                                          </div>

                                          <div>
                                            <a href="car-grid-1.html">
                                              Car List v2{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mega__item">
                                        <div className="text-15 fw-500">
                                          Car Pages{" "}
                                        </div>
                                        <div className="y-gap-5 text-15 pt-5">
                                          <div>
                                            <a href="car-map.html">Car Map </a>
                                          </div>

                                          <div>
                                            <a href="car-single.html">
                                              Car Single{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="mega__image d-flex relative">
                                      <img
                                        src="/titsolutions/img/backgrounds/7.png"
                                        alt="image"
                                        className="rounded-4"
                                      />

                                      <div className="absolute w-full h-full px-30 py-24">
                                        <div className="text-22 fw-500 lh-15 text-white">
                                          Things to do on <br /> your trip{" "}
                                        </div>
                                        <button className="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">
                                          Experinces{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="tabs__pane -tab-item-6">
                                  <div className="mega__content">
                                    <div className="mega__grid">
                                      <div className="mega__item">
                                        <div className="text-15 fw-500">
                                          Cruise List{" "}
                                        </div>
                                        <div className="y-gap-5 text-15 pt-5">
                                          <div>
                                            <a href="cruise-list-1.html">
                                              Cruise List v1{" "}
                                            </a>
                                          </div>

                                          <div>
                                            <a href="cruise-grid-1.html">
                                              Cruise List v2{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mega__item">
                                        <div className="text-15 fw-500">
                                          Cruise Pages{" "}
                                        </div>
                                        <div className="y-gap-5 text-15 pt-5">
                                          <div>
                                            <a href="cruise-map.html">
                                              Cruise Map{" "}
                                            </a>
                                          </div>

                                          <div>
                                            <a href="cruise-single.html">
                                              Cruise Single{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="mega__image d-flex relative">
                                      <img
                                        src="/titsolutions/img/backgrounds/7.png"
                                        alt="image"
                                        className="rounded-4"
                                      />

                                      <div className="absolute w-full h-full px-30 py-24">
                                        <div className="text-22 fw-500 lh-15 text-white">
                                          Things to do on <br /> your trip{" "}
                                        </div>
                                        <button className="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">
                                          Experinces{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="tabs__pane -tab-item-7">
                                  <div className="mega__content">
                                    <div className="mega__grid">
                                      <div className="mega__item">
                                        <div className="text-15 fw-500">
                                          Flight List{" "}
                                        </div>
                                        <div className="y-gap-5 text-15 pt-5">
                                          <div>
                                            <a href="flights-list.html">
                                              Flight list v1{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="mega__image d-flex relative">
                                      <img
                                        src="/titsolutions/img/backgrounds/7.png"
                                        alt="image"
                                        className="rounded-4"
                                      />

                                      <div className="absolute w-full h-full px-30 py-24">
                                        <div className="text-22 fw-500 lh-15 text-white">
                                          Things to do on <br /> your trip{" "}
                                        </div>
                                        <button className="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">
                                          Experinces{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <ul className="subnav mega-mobile">
                            <li className="subnav__backBtn js-nav-list-back">
                              <a href="#">
                                <i className="icon icon-chevron-sm-down"></i>{" "}
                                Category{" "}
                              </a>
                            </li>

                            <li className="menu-item-has-children">
                              <a data-barba="" href="#">
                                <span className="mr-10">Hotel </span>
                                <i className="icon icon-chevron-sm-down"></i>
                              </a>

                              <ul className="subnav">
                                <li className="subnav__backBtn js-nav-list-back">
                                  <a href="#">
                                    <i className="icon icon-chevron-sm-down"></i>{" "}
                                    Hotel{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="hotel-list-1.html">Hotel List v1 </a>
                                </li>

                                <li>
                                  <a href="hotel-list-2.html">Hotel List v2 </a>
                                </li>

                                <li>
                                  <a href="hotel-single-1.html">
                                    Hotel Single v1{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="hotel-single-2.html">
                                    Hotel Single v2{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="booking-pages.html">Booking Page </a>
                                </li>
                              </ul>
                            </li>

                            <li className="menu-item-has-children">
                              <a data-barba="" href="#">
                                <span className="mr-10">Tour </span>
                                <i className="icon icon-chevron-sm-down"></i>
                              </a>

                              <ul className="subnav">
                                <li className="subnav__backBtn js-nav-list-back">
                                  <a href="#">
                                    <i className="icon icon-chevron-sm-down"></i>{" "}
                                    Tour{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="tour-list-1.html">Tour List v1 </a>
                                </li>

                                <li>
                                  <a href="tour-grid-1.html">Tour List v2 </a>
                                </li>

                                <li>
                                  <a href="tour-map.html">Tour Map </a>
                                </li>

                                <li>
                                  <a href="tour-single.html">Tour Single </a>
                                </li>
                              </ul>
                            </li>

                            <li className="menu-item-has-children">
                              <a data-barba="" href="#">
                                <span className="mr-10">Activity </span>
                                <i className="icon icon-chevron-sm-down"></i>
                              </a>

                              <ul className="subnav">
                                <li className="subnav__backBtn js-nav-list-back">
                                  <a href="#">
                                    <i className="icon icon-chevron-sm-down"></i>{" "}
                                    Activity{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="activity-list-1.html">
                                    Activity List v1{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="activity-grid-1.html">
                                    Activity List v2{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="activity-map.html">Activity Map </a>
                                </li>

                                <li>
                                  <a href="activity-single.html">
                                    Activity Single{" "}
                                  </a>
                                </li>
                              </ul>
                            </li>

                            <li className="menu-item-has-children">
                              <a data-barba="" href="#">
                                <span className="mr-10">Rental </span>
                                <i className="icon icon-chevron-sm-down"></i>
                              </a>

                              <ul className="subnav">
                                <li className="subnav__backBtn js-nav-list-back">
                                  <a href="#">
                                    <i className="icon icon-chevron-sm-down"></i>{" "}
                                    Rental{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="rental-list-1.html">
                                    Rental List v1{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="rental-grid-1.html">
                                    Rental List v2{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="rental-map.html">Rental Map </a>
                                </li>

                                <li>
                                  <a href="rental-single.html">
                                    Rental Single{" "}
                                  </a>
                                </li>
                              </ul>
                            </li>

                            <li className="menu-item-has-children">
                              <a data-barba="" href="#">
                                <span className="mr-10">Car </span>
                                <i className="icon icon-chevron-sm-down"></i>
                              </a>

                              <ul className="subnav">
                                <li className="subnav__backBtn js-nav-list-back">
                                  <a href="#">
                                    <i className="icon icon-chevron-sm-down"></i>{" "}
                                    Car{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="car-list-1.html">Car List v1 </a>
                                </li>

                                <li>
                                  <a href="car-grid-1.html">Car List v2 </a>
                                </li>

                                <li>
                                  <a href="car-map.html">Car Map </a>
                                </li>

                                <li>
                                  <a href="car-single.html">Car Single </a>
                                </li>
                              </ul>
                            </li>

                            <li className="menu-item-has-children">
                              <a data-barba="" href="#">
                                <span className="mr-10">Cruise </span>
                                <i className="icon icon-chevron-sm-down"></i>
                              </a>

                              <ul className="subnav">
                                <li className="subnav__backBtn js-nav-list-back">
                                  <a href="#">
                                    <i className="icon icon-chevron-sm-down"></i>{" "}
                                    Cruise{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="cruise-list-1.html">
                                    Cruise List v1{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="cruise-grid-1.html">
                                    Cruise List v2{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="cruise-map.html">Cruise Map </a>
                                </li>

                                <li>
                                  <a href="cruise-single.html">
                                    Cruise Single{" "}
                                  </a>
                                </li>
                              </ul>
                            </li>

                            <li className="menu-item-has-children">
                              <a data-barba="" href="#">
                                <span className="mr-10">Flights </span>
                                <i className="icon icon-chevron-sm-down"></i>
                              </a>

                              <ul className="subnav">
                                <li className="subnav__backBtn js-nav-list-back">
                                  <a href="#">
                                    <i className="icon icon-chevron-sm-down"></i>{" "}
                                    Flights{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="flights-list.html">
                                    Flights List v1{" "}
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li> */}

                        <li>
                          <Link to="/">
                            <FontAwesomeIcon icon={faHome} className="me-1" />{" "}
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link to="/about-us">
                            {" "}
                            <FontAwesomeIcon
                              icon={faQuestionCircle}
                              className="me-1"
                            />{" "}
                            About Us
                          </Link>
                        </li>
                        {/* <li>
                          <Link to="#.">
                            {" "}
                            <FontAwesomeIcon
                              icon={faMapLocationDot}
                              className="me-1"
                            />{" "}
                            Destinations
                          </Link>
                        </li> */}

                        <li className="menu-item-has-children">
                          <Link to="#.">
                            {" "}
                            <span className="mr-10">
                              <FontAwesomeIcon
                                icon={faMapLocationDot}
                                className="me-1"
                              />{" "}
                              Destinations
                            </span>{" "}
                            <i className="icon icon-chevron-sm-down"></i>
                          </Link>
                          <ul className="subnav">
                            <li>
                              <Link to="#.">Aswan </Link>
                            </li>

                            <li>
                              <Link to="#.">Luxor </Link>
                            </li>
                          </ul>
                        </li>

                        {/* <li className="menu-item-has-children">
                          <a data-barba="" href="home-9.html">
                            <span className="mr-10">Pages </span>
                            <i className="icon icon-chevron-sm-down"></i>
                          </a>

                          <ul className="subnav">
                            <li className="subnav__backBtn js-nav-list-back">
                              <a href="#">
                                <i className="icon icon-chevron-sm-down"></i>{" "}
                                Pages{" "}
                              </a>
                            </li>

                            <li>
                              <a href="404.html">404 </a>
                            </li>

                            <li>
                              <a href="about.html">About </a>
                            </li>

                            <li>
                              <a href="become-partner.html">Become expert </a>
                            </li>

                            <li>
                              <a href="help-center.html">Help center </a>
                            </li>

                            <li>
                              <a href="login.html">Login </a>
                            </li>

                            <li>
                              <a href="signup.html">Register </a>
                            </li>

                            <li>
                              <a href="terms.html">Terms </a>
                            </li>

                            <li>
                              <a href="invoice.html">Invoice </a>
                            </li>

                            <li>
                              <a href="ui-elements.html">UI elements </a>
                            </li>
                          </ul>
                        </li> */}

                        {/* <li className="menu-item-has-children">
                          <a data-barba="" href="home-9.html">
                            <span className="mr-10">Dashboard </span>
                            <i className="icon icon-chevron-sm-down"></i>
                          </a>

                          <ul className="subnav">
                            <li className="subnav__backBtn js-nav-list-back">
                              <a href="#">
                                <i className="icon icon-chevron-sm-down"></i>{" "}
                                Dashboard{" "}
                              </a>
                            </li>

                            <li>
                              <a href="db-dashboard.html">Dashboard </a>
                            </li>

                            <li>
                              <a href="db-booking.html">Booking </a>
                            </li>

                            <li>
                              <a href="db-settings.html">Settings </a>
                            </li>

                            <li>
                              <a href="db-wishlist.html">Wishlist </a>
                            </li>

                            <li>
                              <a href="db-vendor-dashboard.html">
                                Vendor dashboard{" "}
                              </a>
                            </li>

                            <li>
                              <a href="db-vendor-add-hotel.html">
                                Vendor add hotel{" "}
                              </a>
                            </li>

                            <li>
                              <a href="db-vendor-booking.html">
                                Vendor booking{" "}
                              </a>
                            </li>

                            <li>
                              <a href="db-vendor-hotels.html">Vendor hotels </a>
                            </li>

                            <li>
                              <a href="db-vendor-recovery.html">
                                Vendor recovery{" "}
                              </a>
                            </li>
                          </ul>
                        </li> */}

                        <li>
                          <Link to="/contact-us">
                            {" "}
                            <FontAwesomeIcon
                              icon={faHeadset}
                              className="me-1"
                            />{" "}
                            Contact Us
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="mobile-footer px-20 py-20 border-top-light js-mobile-footer"></div>
                  </div>
                </div>

                {/* <div className="d-flex items-center ml-24 is-menu-opened-hide md:d-none">
                  <a
                    href="#"
                    className="text-26 xl:text-20 text-white icon-search"
                  ></a>
                </div> */}

                <div
                  className="d-none xl:d-flex x-gap-20 items-center pl-20 text-white"
                  data-x="header-mobile-icons"
                  data-x-toggle="text-white"
                >
                  <div>
                    <a
                      href="login.html"
                      className="d-flex items-center icon-user text-inherit text-22"
                    ></a>
                  </div>
                  <div>
                    <button
                      className="d-flex items-center icon-menu text-inherit text-20"
                      data-x-click="html, header, header-logo, header-mobile-icons, mobile-menu"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Outlet />
      <section className="layout-pt-md layout-pb-md bg-blue-1 newsletter">
        <div className="container">
          <div className="row y-gap-30 justify-between items-center ">
            <div className="col-auto">
              <div className="d-flex y-gap-20 flex-wrap items-center">
                <div className="icon-newsletter text-60 sm:text-40 text-white"></div>

                <div className="ml-30">
                  <h4 className="text-26 text-white fw-600">
                    Your BookingNile.com Adventure Awaits!
                  </h4>
                  <div className="text-white">
                    Sign up and we'll sail you to the best deals on
                    unforgettable cruises!
                  </div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              <div className="single-field -w-410 d-flex x-gap-10 y-gap-20">
                <div>
                  <input
                    className="bg-white h-60"
                    type="text"
                    placeholder="Your Email"
                  />
                </div>

                <div>
                  <button className="button -md h-60 bg-yellow-1 text-dark-1">
                    Subscribe{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer
        className="footer -type-1 text-white bg-blue-1"
        style={{ position: "relative", overflow: "hidden" }}
      >
        <img
          src="footer-bg.webp"
          alt=""
          style={{
            position: "absolute",
            right: "0",
            top: "15%",
            height: "100%",
            width: "25%",
            opacity: "0.8",
            transform: "rotate(4deg)",
          }}
        />
        {/* <img
          src="footer-bg.webp"
          alt=""
          style={{
            position: "absolute",
            right: "55%",
            top: "53%",
            height: "auto",
            width: "10%",
            opacity: "0.8",
            transform: "rotate(4deg)",
          }}
        /> */}
        {/* <img
          src="footer-bg.webp"
          alt=""
          style={{
            position: "absolute",
            left: "0%",
            top: "53%",
            height: "auto",
            width: "10%",
            opacity: "0.8",
            transform: "rotate(4deg)",
          }}
        /> */}
        <div className="container">
          <div className="pt-60 pb-60">
            <div className="row y-gap-40 justify-between xl:justify-start">
              <div className="col-xl-2 col-lg-4 col-sm-6">
                <h5 className="text-16 fw-500 mb-30">About Booking Nile </h5>
                <div className="d-flex y-gap-10 flex-column">
                  <Link to="/about-us">About Us </Link>
                  <Link to="/how-we-work">How We Work </Link>
                  <Link to="/become-partner">Become A Partner </Link>
                  <Link to="#.">Blogs </Link>
                  {/* <Link to="#.">Press </Link>
                  <Link to="#.">Gift Cards </Link>
                  <Link to="#.">Magazine </Link> */}
                </div>
              </div>

              <div className="col-xl-2 col-lg-4 col-sm-6">
                <h5 className="text-16 fw-500 mb-30">Support </h5>
                <div className="d-flex y-gap-10 flex-column">
                  <Link to="/contact-us">Contact Us</Link>
                  {/* <Link to="#.">Legal Notice </Link> */}
                  <Link to="privacy-policy">Privacy Policy </Link>
                  <Link to="/terms-and-conditions">Terms and Conditions </Link>
                  <Link to="/help-center">Help Center </Link>
                </div>
              </div>

              <div className="col-xl-2 col-lg-4 col-sm-6">
                <h5 className="text-16 fw-500 mb-30">Other Services </h5>
                <div className="d-flex y-gap-10 flex-column">
                  <Link to="#.">Car hire </Link>
                  <Link to="#.">Activity Finder </Link>
                  <Link to="#.">Tour List </Link>
                  <Link to="#.">Cruise Ticket </Link>
                  {/* <Link to="#.">Flight finder </Link>
                  <Link to="#.">Holiday Rental </Link>
                  <Link to="#.">Travel Agents </Link> */}
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-6">
                <h5 className="text-16 fw-500 mb-30">Contact Us </h5>

                <div className="mt-30 d-flex align-items-start">
                  <div className="text-18 fw-500 mt-5 me-1">
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                  <Link to="tel:+201000972210" className="text-18 fw-500 mt-5">
                    +(20) 10 00972210{" "}
                  </Link>
                </div>

                <div className="mt-10 d-flex align-items-start">
                  <div className="text-18 fw-500 mt-5 me-1">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <Link
                    to="mailto:info@bookingnile.com"
                    className="text-18 fw-500 mt-5"
                  >
                    info@bookingnile.com{" "}
                  </Link>
                </div>
                <div className="mt-10 d-flex align-items-start">
                  <div className="text-18 fw-500 mt-5 me-1">
                    <FontAwesomeIcon icon={faLocationDot} />
                  </div>
                  <Link
                    to="https://maps.app.goo.gl/soCip2vkRJM8wY5EA"
                    target="_blank"
                    className="text-14 fw-400 mt-5"
                  >
                    El-Nasr Street, Building #28. New Maadi. Cairo, Egypt
                  </Link>
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-6">
                <h5 className="text-16 fw-500 mb-30">
                  <h5 class="text-16 fw-500 mb-10">
                    Follow us on social media
                  </h5>{" "}
                </h5>

                <div className=" mt-20 py-10 text-lg-left  ">
                  <div className="row y-gap-10 justify-content-lg-left">
                    <div className="d-flex x-gap-20 items-center justify-content-center">
                      <Link to="#.">
                        <FontAwesomeIcon
                          icon={faFacebookF}
                          className="text-16"
                        />
                      </Link>

                      <Link to="#.">
                        <FontAwesomeIcon
                          icon={faInstagram}
                          className="text-16"
                        />
                      </Link>
                      <Link to="#.">
                        <FontAwesomeIcon
                          icon={faXTwitter}
                          className="text-16"
                        />
                      </Link>
                      <Link to="#.">
                        <FontAwesomeIcon
                          icon={faLinkedinIn}
                          className="text-16"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-20 px-0 border-top-white-15 last-footer">
            <div className="row justify-content-between  ">
              <div className="col-lg-4">
                <div className="d-flex items-start">
                  © 2024 BookingNile.com | All rights reserved.
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex items-center justify-center">
                  <img src="bookingnilewhite.png" width="150" alt="" />
                </div>
              </div>

              <div className="col-lg-4 ">
                <div className="text-right">
                  Powered By{" "}
                  <Link to="https://titegypt.com" target="_blank">
                    T.I.T Solutions
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Layout;
