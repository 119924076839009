import {
  faCompress,
  faExpand,
  faReply,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";

const PageCover = React.forwardRef((props, ref) => {
  return (
    <div className="page page-cover" ref={ref} data-density="hard">
      <div className="page-content">
        <h2>{props.children}</h2>
      </div>
    </div>
  );
});

// Page functional component using forwardRef
const Page = forwardRef((props, ref) => {
  return (
    <div className="page" ref={ref}>
      <div className="page-content">
        <h2 className="page-header">Page header - {props.number}</h2>
        <div className="page-image"></div>
        <div className="page-text">{props.children}</div>
        <div className="page-footer">{props.number + 1}</div>
      </div>
    </div>
  );
});

const Test = () => {
  const flipBook = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0); // Track the current page
  const nextPage = () => {
    if (flipBook.current) {
      flipBook.current.pageFlip().flipNext();
    }
  };

  const prevPage = () => {
    if (flipBook.current) {
      flipBook.current.pageFlip().flipPrev();
    }
  };

  const totalPageCount = 7; // Total number of pages

  // Called when slider changes
  const handleSliderChange = (event) => {
    const pageIndex = parseInt(event.target.value);
    if (flipBook.current) {
      flipBook.current.pageFlip().flip(pageIndex);
    }
    setCurrentPage(pageIndex);
  };

  // Update slider when the flipbook page changes
  const onPageChange = (e) => {
    setCurrentPage(e.data);
  };

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    setIsFullscreen(!isFullscreen);
  };
  return (
    <>
      <div
        style={{
          position: "relative",
          display: "inline-block",
          left: "50%",
          transform: "translateX(-50%)",
        }}
        className="col-lg-8 flipbook-container text-center pt-50 mt-50 mx-auto"
      >
        <button
          onClick={toggleFullscreen}
          style={{
            position: "absolute",
            top: "-3vh",
            right: "-1vw",
            zIndex: 10,

            color: "#333",
            padding: "10px",
            borderRadius: "50%",
            border: "none",
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={isFullscreen ? faCompress : faExpand} />
        </button>
        {/* Fullscreen Toggle Button */}

        <HTMLFlipBook
          ref={flipBook}
          usePortrait={true}
          width={isFullscreen ? 1000 : 750} // Adjust width for fullscreen
          height={isFullscreen ? 1200 : 800} // Adjust height for fullscreen
          mobileScrollSupport={true}
          className="demo-book"
          showCover={true}
          size="stretch"
          getOrientation="portrait"
          style={{ boxShadow: "0 0 5px 1px #ccc" }}
          autoSize={true}
        >
          {/* Cover Image Centered and Full Size */}
          <div
            className="demoPage"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: currentPage === 0 ? "100%" : "auto", // Full width only on cover
              height: currentPage === 0 ? "auto" : "100%", // Auto height on cover
              objectFit: "cover", // Cover the area proportionally
            }}
          >
            <img
              src="https://steigenbergeraldauresort.com/images/uploads/Magazine/LS-01.jpg"
              alt=""
              style={{
                width: currentPage === 0 ? "200%" : "100%", // Full width on the first page
                height: currentPage === 0 ? "100%" : "100%", // Maintain height
                objectFit: currentPage === 0 ? "cover" : "contain", // Cover the area on cover, contain on other pages
              }}
            />
          </div>

          {/* Other Pages */}
          {[
            "LS-02.jpg",
            "LS-03.jpg",
            "LS-04.jpg",
            "LS-05.jpg",
            "LS-10.jpg",
            "LS-11.jpg",
          ].map((img, index) => (
            <div key={index} className="demoPage">
              <img
                src={`https://steigenbergeraldauresort.com/images/uploads/Magazine/${img}`}
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          ))}
        </HTMLFlipBook>

        {/* Left and Right Navigation Arrows */}
        <button
          style={{
            position: "absolute",
            top: "54%",
            left: "-2.6%",
            fontSize: "20px",
            backgroundColor: "#999",
            height: "10%",
            color: "#fff",
            transform: "translateY(-50%)",
            borderRadius: "20px 0 0 20px",
          }}
          onClick={prevPage}
        >
          <FontAwesomeIcon icon={faReply} className="mx-1" />
        </button>

        <button
          style={{
            position: "absolute",
            top: "54%",
            right: "-2.8%",
            fontSize: "20px",
            backgroundColor: "#999",
            height: "10%",
            color: "#fff",
            transform: "translateY(-50%)",
            borderRadius: "0 20px 20px 0",
          }}
          onClick={nextPage}
        >
          <FontAwesomeIcon icon={faShare} className="mx-1" />
        </button>

        {/* Inline CSS */}
        <style jsx>{`
          .flipbook-container {
            position: relative;
            transition: all 0.5s ease-in-out;
          }

          button {
            font-size: 24px;
            opacity: 0;
            transition: opacity 0.3s ease;
            cursor: pointer;
            background: none;
            border: none;
            color: black;
          }

          .flipbook-container:hover button {
            opacity: 0.7;
          }

          button:hover {
            color: gray;
          }
        `}</style>
      </div>
    </>
  );
};

export default Test;
