import React, { useEffect, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
// import { fetchCabins } from "../../data/cabinBookingSlice";
// import Loader from "../../Loader";
// import Slider from "react-slick";
import "./cabins.css";
import { Accordion, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCancel,
  faChevronRight,
  faRemove,
  faSquarePlus,
} from "@fortawesome/free-solid-svg-icons";

// import { InternalBookingForm } from "./InternalBookingForm";
import { useMediaQuery } from "react-responsive";
import {
  fetchGetExtras,
  removeExtra,
  selectExtra,
} from "../data/groupBooking/getExtraSlice";
// import {
//   fectchConfirmationBooking,
//   fetchConfirmationBooking,
// } from "../../data/groupBooking/confirmationBookingSlice";
// import { fetchBookingInfo } from "../data/bookingInfoSlice";

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", fontSize: "20px" }}
      onClick={onClick}
    >
      <i className="bi bi-arrow-left"></i>
    </div>
  );
}

// Custom Arrow for the Next Button
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", fontSize: "20px" }}
      onClick={onClick}
    >
      <i className="bi bi-arrow-right"></i>
    </div>
  );
}
const AddExtra = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const cabinId = localStorage.getItem("cabinId");

  const { extras, selectedExtra, loadingExtras, errorExtras } = useSelector(
    (state) => state.extras
  );

  const storedData = localStorage.getItem("bookingFormData");

  const itemObj = JSON.parse(storedData);
  const cruiseUrl = localStorage.getItem("cruiseUrl");
  // const itinerary_name = JSON.parse(localStorage.getItem("itinerary_name"));
  const itinearyIdGroup = localStorage.getItem("itinearyIdGroup");
  const child1 = localStorage.getItem("child1");
  const child2 = localStorage.getItem("child2");

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        fetchGetExtras({
          LangName: "en",
          itineraryId: 6,
          CruiseUrl: "tower-prestige",
          AdultNumber: 1,
          ChildNumber: 1,
          ChildAge1: 2,
          ChildAge2: 0,
          cabinId: 13,
          Str_Date: "2024,09,25",
        })
        // fetchGetExtras({
        //   LangName: language,
        //   itineraryId: itinearyIdGroup,
        //   CruiseUrl: cruiseUrl,
        //   AdultNumber: itemObj.AdultNumber,
        //   ChildNumber: itemObj.ChildNumber,
        //   ChildAge1: itemObj.ChildAge1,
        //   ChildAge2: itemObj.ChildAge2,
        //   cabinId,
        //   Str_Date: itemObj.Str_Date,
        // })
      );
    }, 100);
  }, [dispatch]);

  // const { cruiseId } = useParams();

  // const itinerary_name = JSON.parse(localStorage.getItem("itinerary_name"));

  const [checkedExtras, setCheckedExtras] = useState({});
  const handleSelectExtra = (extraId, checked) => {
    dispatch(selectExtra({ extraId, checked }));
    setCheckedExtras((prev) => ({
      ...prev,
      [extraId]: checked,
    }));
  };
  const handleRemoveExtra = (extraId) => {
    dispatch(removeExtra(extraId));
    setCheckedExtras((prev) => ({
      ...prev,
      [extraId]: false,
    }));
  };
  const calculateTotalPrice = () => {
    return selectedExtra.reduce(
      (total, extra) => total + extra.totalExtraPrice,
      0
    );
  };
  return (
    <>
      {" "}
      <Helmet>
        <title>Checkout</title>{" "}
        <style>
          {`
            .header .header-menu .menu li a{
            color:#051036;
            }
            .header .header-menu .menu .subnav>li>a {
            color:#051036 !important;
            }
            .is-sticky.bg-dark-1{
            background-color:#fff !important;
            }
            // .is-sticky .menu li a{
            // color:#fff !important;
            // }
            .ratio-15\:9::before{
            padding-bottom:0 !important;}
            
            .form-checkbox__mark{
              width: 25px !important;
    height: 25px !important;
            }
            
            
            
            `}
        </style>
      </Helmet>
      {/*banner */}
      <section className="d-flex items-center  mt-90  ">
        <div className="container  mt-40 py-40 bg-light-2 border-top-light">
          <div className="row x-gap-40 y-gap-30 items-center px-50">
            <div className="col-auto px-1">
              <div className="d-flex items-center">
                <div className="size-40 rounded-full flex-center bg-blue-1">
                  <i className="icon-check text-16 text-white"></i>
                </div>
                <div className="text-18 fw-500 ml-10">Choose Cruise </div>
              </div>
            </div>{" "}
            <div className="col px-1">
              <div className="w-full h-1 bg-border"></div>
            </div>
            <div className="col-auto  px-1">
              <div className="d-flex items-center">
                <div className="size-40 rounded-full flex-center bg-blue-1">
                  <i className="icon-check text-16 text-white"></i>
                </div>
                <div className="text-18 fw-500 ml-10">Choose Cabin </div>
              </div>
            </div>
            <div className="col px-1">
              <div className="w-full h-1 bg-border"></div>
            </div>
            <div className="col-auto px-1">
              <div className="d-flex items-center">
                <div className="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 fw-500">
                  3{" "}
                </div>
                <div className="text-18 fw-500 ml-10">Extras selection </div>
              </div>
            </div>
            <div className="col px-1">
              <div className="w-full h-1 bg-border"></div>
            </div>
            <div className="col-auto px-1">
              <div className="d-flex items-center">
                <div className="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 fw-500">
                  4{" "}
                </div>
                <div className="text-18 fw-500 ml-10">Checkout </div>
              </div>
            </div>
            <div className="col px-1">
              <div className="w-full h-1 bg-border"></div>
            </div>
            <div className="col-auto px-1">
              <div className="d-flex items-center">
                <div className="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 fw-500">
                  5{" "}
                </div>
                <div className="text-18 fw-500 ml-10">Booking Completed! </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* form */}
      <section className="card-area section--padding my-5" id="cabin-details">
        <div className="container">
          <div className="d-flex flex-wrap justify-content-between col-12  mx-auto">
            <div className="col-12 col-lg-7 me-lg-5">
              <h3 className="mb-3">Select extras for your stay</h3>
              {extras.extras?.length > 0 && (
                <div className="col-xs-12 fb-results-accommodation  mb-5 ">
                  <div className="col-xs-12 d-flex justify-content-between align-items-center fb-dark-bg fb-block-header fb-results-acc-title mt-0 ">
                    <span>Extras for your stay </span>
                  </div>
                  {loadingExtras ? (
                    <div className="loading-extra">
                      {/* <Loader /> */}
                      <p>Loading...</p>
                    </div>
                  ) : errorExtras ? (
                    errorExtras
                  ) : (
                    extras.extras?.map((extra) => (
                      <div
                        key={extra.extraId}
                        className="d-flex justify-content-between align-items-start px-3 py-10"
                        style={{ border: "1px solid #ccc" }}
                      >
                        {" "}
                        <div className="col-lg-3">
                          <img
                            src={extra.extraPhoto}
                            alt=""
                            loading="lazy"
                            className="me-2"
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div className="room-content text-left me-auto border-0 pt-0 col-7">
                          <h5 className="fs-4 mt-0 pt-0">{extra.extraName}</h5>

                          <div className="room-amenities-item">
                            <ul className="col-12 d-flex justify-content-between flex-wrap">
                              <p
                                className="my-2"
                                dangerouslySetInnerHTML={{
                                  __html: extra.extraDescription,
                                }}
                              />
                            </ul>
                          </div>

                          <span to="#." style={{ color: "#103682" }}>
                            <span
                              className="me-2  fw-bold col-3  "
                              style={{
                                fontSize: "13px",
                                color: "#000",
                              }}
                            >
                              Total Price:
                            </span>{" "}
                            <b>$ {extra.totalExtraPrice}</b>
                          </span>
                        </div>
                        <div className="   d-flex align-items-center justify-content-between position-relative">
                          <div
                            className="card-btn "
                            style={{ bottom: "unset" }}
                          >
                            <div className="form-checkbox d-flex align-items-center">
                              <input
                                type="checkbox"
                                name="name"
                                id={`select-${extra.extraId}`}
                                checked={checkedExtras[extra.extraId] || false}
                                onChange={(e) =>
                                  handleSelectExtra(
                                    extra.extraId,
                                    e.target.checked
                                  )
                                }
                              />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                              <div className="col-auto ms-2">
                                <label
                                  htmlFor={`select-${extra.extraId}`}
                                  className="text-16 text-light-1"
                                >
                                  Select
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>

            <div className="col-12 col-lg-4">
              <div className="">
                <div className="px-30 py-30 border-light rounded-4">
                  <div className="text-20 fw-500 mb-30">
                    Your booking details{" "}
                  </div>

                  <div className="row x-gap-15 y-gap-20">
                    <div className="col-auto">
                      <img
                        src="/titsolutions/img/backgrounds/1.png"
                        alt=""
                        className="size-140 rounded-4 object-cover"
                      />
                    </div>

                    <div className="col">
                      <div className="d-flex x-gap-5 pb-10">
                        <i className="icon-star text-yellow-1 text-10"></i>

                        <i className="icon-star text-yellow-1 text-10"></i>

                        <i className="icon-star text-yellow-1 text-10"></i>

                        <i className="icon-star text-yellow-1 text-10"></i>

                        <i className="icon-star text-yellow-1 text-10"></i>
                      </div>

                      <div className="lh-17 fw-500">
                        Great Northern Hotel, a Portfolio Hotel, London{" "}
                      </div>
                      <div className="text-14 lh-15 mt-5">
                        Westminster Borough, London{" "}
                      </div>

                      <div className="row x-gap-10 y-gap-10 items-center pt-10">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="size-30 flex-center bg-blue-1 rounded-4">
                              <div className="text-12 fw-600 text-white">
                                4.8{" "}
                              </div>
                            </div>

                            <div className="text-14 fw-500 ml-10">
                              Exceptional{" "}
                            </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-14">3,014 reviews </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-top-light mt-30 mb-20"></div>

                  <div className="row y-gap-20 justify-between">
                    <div className="col-auto">
                      <div className="text-15">Check-in </div>
                      <div className="fw-500">Thu 21 Apr 2022 </div>
                      <div className="text-15 text-light-1">15:00 – 23:00 </div>
                    </div>

                    <div className="col-auto md:d-none">
                      <div className="h-full w-1 bg-border"></div>
                    </div>

                    <div className="col-auto text-right md:text-left">
                      <div className="text-15">Check-out </div>
                      <div className="fw-500">Sat 30 Apr 2022 </div>
                      <div className="text-15 text-light-1">01:00 – 11:00 </div>
                    </div>
                  </div>

                  <div className="border-top-light mt-30 mb-20"></div>

                  <div className="">
                    <div className="text-15">Total length of stay: </div>
                    <div className="fw-500">9 nights </div>
                    <Link to="#." className="text-15 text-blue-1 underline">
                      Travelling on different dates?{" "}
                    </Link>
                  </div>

                  <div className="border-top-light mt-30 mb-20"></div>

                  <div className="row y-gap-20 justify-between items-center">
                    <div className="col-auto">
                      <div className="text-15">You selected: </div>
                      <div className="fw-500">Superior Double Studio </div>
                      <Link to="#." className="text-15 text-blue-1 underline">
                        Change your selection{" "}
                      </Link>
                    </div>

                    <div className="col-auto">
                      <div className="text-15">1 room, 4 adult </div>
                    </div>
                  </div>
                </div>

                <div className="px-30 py-30 border-light rounded-4 mt-30">
                  <div className="text-20 fw-500 mb-20">
                    Your price summary{" "}
                  </div>

                  <div className="row y-gap-5 justify-between">
                    <div className="col-auto">
                      <div className="text-15">Superior Twin </div>
                    </div>
                    <div className="col-auto">
                      <div className="text-15">US$3,372.34 </div>
                    </div>
                  </div>

                  <div className="row y-gap-5 justify-between pt-5">
                    <div className="col-auto">
                      <div className="text-15">Taxes and fees </div>
                    </div>
                    <div className="col-auto">
                      <div className="text-15">US$674.47 </div>
                    </div>
                  </div>

                  <div className="row y-gap-5 justify-between pt-5">
                    <div className="col-auto">
                      <div className="text-15">Booking fees </div>
                    </div>
                    <div className="col-auto">
                      <div className="text-15">FREE </div>
                    </div>
                  </div>

                  <div className="px-20 py-20 bg-blue-2 rounded-4 mt-20">
                    <div className="row y-gap-5 justify-between">
                      <div className="col-auto">
                        <div className="text-18 lh-13 fw-500">Price </div>
                      </div>
                      <div className="col-auto">
                        <div className="text-18 lh-13 fw-500">US$4,046.81 </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
              {selectedExtra?.length > 0 && (
                <div className="form-box booking-detail-form px-30 py-30 border-light rounded-4 mt-30">
                  <div className="form-content">
                    <div className="card-item shadow-none radius-none mb-0">
                      <div className="card-body p-0">
                        <ul className="list-items list-items-2 py-0">
                          <li className="d-flex justify-content-between align-items-center mb-1">
                            <span className="me-2 fw-bold  ">Extras</span>
                            <span className="me-2 fw-bold  ">
                              {" "}
                              $ {calculateTotalPrice()}
                            </span>
                          </li>
                          <div className="w-full h-1 bg-border my-2 "></div>
                          {selectedExtra?.map((extra, index) => (
                            <li
                              key={extra.extraId}
                              className={`row d-flex justify-content-between align-items-center py-2 ${
                                index % 2 === 1 && "bg-light"
                              }`}
                              style={{ borderBottom: "1px solid #ccc" }}
                            >
                              <span
                                className="me-2 col-8   "
                                style={{
                                  fontSize: "13px",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faSquarePlus}
                                  style={{
                                    fontSize: "13px",
                                    color: "#103682",
                                  }}
                                />{" "}
                                {extra.extraName}
                              </span>
                              <span
                                className="me-2  fw-bold col-3 text-center "
                                style={{
                                  fontSize: "13px",
                                  color: "#103682",
                                }}
                              >
                                {" "}
                                $ {extra.totalExtraPrice} <br />
                                <Button
                                  className="py-0 px-1 btn-danger"
                                  style={{
                                    fontSize: "10px",
                                  }}
                                  onClick={() =>
                                    handleRemoveExtra(extra.extraId)
                                  }
                                >
                                  <FontAwesomeIcon icon={faRemove} /> Remove
                                </Button>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="row  mt-3 text-center"
                style={{ bottom: "unset" }}
                // onClick={() => {
                //   localStorage.setItem("cabinId", cabin.cabinId);
                //   localStorage.setItem(
                //     "priceAdultBasic",
                //     cabin?.priceAdultBasic
                //   );
                // }}
              >
                <Link
                  to={`/checkout`}
                  className="button h-60 px-24 -dark-1 bg-blue-1 text-white"
                >
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddExtra;
