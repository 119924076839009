import React, { useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import { BookingForm } from "../components/BookingForm";
const Home = () => {
  const navigate = useNavigate();
  const locations = [
    { name: "London", description: "Greater London, United Kingdom" },
    { name: "New York", description: "New York State, United States" },
    { name: "Paris", description: "France" },
    { name: "Madrid", description: "Spain" },
    { name: "Santorini", description: "Greece" },
    { name: "Egypt", description: "Cairo, Egypt" },
  ];
  const [isActiveDestination, setIsActiveDestination] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const searchMenuRef = useRef(null);

  // Toggle the active state on click
  const handleDestinationClick = () => {
    setIsActiveDestination(!isActiveDestination);
  };

  // Handle blur event
  const handleBlur = (event) => {
    if (
      searchMenuRef.current &&
      !searchMenuRef.current.contains(event.relatedTarget)
    ) {
      setIsActiveDestination(false);
    }
  };

  // Handle selection of a location
  const handleLocationSelect = (location) => {
    setSelectedLocation(location.name); // Set the selected location
    setSearchTerm(""); // Clear the search term
    setIsActiveDestination(false); // Close the dropdown after selection
  };

  // Filter locations based on search term
  const filteredLocations = locations.filter((location) =>
    location.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [value, setValue] = useState([null, null]);

  const handleChange = (dates) => {
    setValue(dates);
  };

  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(1);
  const [rooms, setRooms] = useState(1);

  const handleIncrement = (setter) => {
    setter((prev) => prev + 1);
  };

  const handleDecrement = (setter) => {
    setter((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const [isActiveGuest, setIsActiveGuest] = useState(false);

  const GuestMenuRef = useRef(null);

  // Toggle the active state on click
  const handleGuestClick = () => {
    setIsActiveGuest(!isActiveGuest);
  };
  const handleGuestBlur = (event) => {
    if (
      GuestMenuRef.current &&
      !GuestMenuRef.current.contains(event.relatedTarget)
    ) {
      setIsActiveGuest(false);
    }
  };
  const formatDate = (date) => {
    if (!date) return "";
    return format(date, "EEE d MMM"); // e.g., "Wed 2 Mar"
  };
  const disablePastDates = (date) => {
    const today = new Date();
    return date < today.setHours(0, 0, 0, 0); // Disable past dates
  };
  const destinations = [
    {
      city: "Aswan",
      imgSrc: "titsolutions/img/destinations/3/1.png",
      properties: "4,090 properties",
    },
    {
      city: "Sharm Elshekh",
      imgSrc: "titsolutions/img/destinations/3/2.png",
      properties: "4,090 properties",
    },
    {
      city: "Hurghada",
      imgSrc: "titsolutions/img/destinations/3/3.png",
      properties: "4,090 properties",
    },
    {
      city: "Alexandria",
      imgSrc: "titsolutions/img/destinations/3/4.png",
      properties: "4,090 properties",
    },
    {
      city: "Ain Soughna",
      imgSrc: "titsolutions/img/destinations/3/5.png",
      properties: "4,090 properties",
    },
    {
      city: "Marsa Matrouh",
      imgSrc: "titsolutions/img/destinations/3/6.png",
      properties: "4,090 properties",
    },
  ];
  return (
    <>
      {" "}
      <Helmet>
        <title>Booking Nile</title>
      </Helmet>
      {/*banner */}
      <section className="masthead -type-1 z-5 ">
        <div className="masthead__bg is-in-view">
          <img src="bg.jpg" alt="bg" className="js-lazy loaded  water-effect" />
        </div>

        <div className="container">
          <div className="row justify-center">
            <div className="col-auto">
              <div className="text-center water-text">
                <h1 className="text-60 lg:text-40 md:text-30 text-white is-in-view  ">
                  Discover Your Next Nile Adventure
                </h1>
                <p className="text-white mt-15  is-in-view">
                  Explore breathtaking destinations with exclusive
                  BookingNile.com deals!
                </p>
              </div>
              {/* booking engine  */}
              <BookingForm />
            </div>
          </div>
        </div>
        <div className="masthead -type-9">
          <Link to="#secondSection" className="masthead__scroll">
            <div className="d-flex items-center">
              <div className="text-white lh-15 text-right mr-10">
                <div className="fw-500">Scroll Down </div>
                <div className="text-15">to discover more </div>
              </div>

              <div className="-icon">
                <div></div>
                <div></div>
              </div>
            </div>
          </Link>
        </div>
      </section>
      {/*Popular Destinations */}
      <section
        className="layout-pt-lg layout-pb-md relative z--1"
        id="secondSection"
      >
        <div className="container">
          <div className="row y-gap-20 justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">Top Nile Destinations</h2>
                <p className="sectionTitle__text mt-5 sm:mt-0">
                  Explore the wonders of the Nile and beyond for an
                  unforgettable journey!
                </p>
              </div>
            </div>
          </div>

          <div className="row x-gap-10 y-gap-10 pt-40 sm:pt-20">
            <div className="col-xl col-lg-3 col-6">
              <Link
                to="#."
                className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4"
              >
                <i className="icon-destination text-24"></i>

                <div className="ml-10 sm:ml-0 sm:mt-10">
                  <h4 className="text-16 fw-500">New York </h4>
                  <p className="text-14">4,090 properties </p>
                </div>
              </Link>
            </div>

            <div className="col-xl col-lg-3 col-6">
              <Link
                to="#."
                className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4"
              >
                <i className="icon-destination text-24"></i>

                <div className="ml-10 sm:ml-0 sm:mt-10">
                  <h4 className="text-16 fw-500">London </h4>
                  <p className="text-14">4,090 properties </p>
                </div>
              </Link>
            </div>

            <div className="col-xl col-lg-3 col-6">
              <Link
                to="#."
                className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4"
              >
                <i className="icon-destination text-24"></i>

                <div className="ml-10 sm:ml-0 sm:mt-10">
                  <h4 className="text-16 fw-500">Egypt</h4>
                  <p className="text-14">4,090 properties </p>
                </div>
              </Link>
            </div>

            <div className="col-xl col-lg-3 col-6">
              <Link
                to="#."
                className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4"
              >
                <i className="icon-destination text-24"></i>

                <div className="ml-10 sm:ml-0 sm:mt-10">
                  <h4 className="text-16 fw-500">Paris </h4>
                  <p className="text-14">4,090 properties </p>
                </div>
              </Link>
            </div>

            <div className="col-xl col-lg-3 col-6">
              <Link
                to="#."
                className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4"
              >
                <i className="icon-destination text-24"></i>

                <div className="ml-10 sm:ml-0 sm:mt-10">
                  <h4 className="text-16 fw-500">Istanbul </h4>
                  <p className="text-14">4,090 properties </p>
                </div>
              </Link>
            </div>

            <div className="col-xl col-lg-3 col-6">
              <Link
                to="#."
                className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4"
              >
                <i className="icon-destination text-24"></i>

                <div className="ml-10 sm:ml-0 sm:mt-10">
                  <h4 className="text-16 fw-500">Rome </h4>
                  <p className="text-14">4,090 properties </p>
                </div>
              </Link>
            </div>

            <div className="col-xl col-lg-3 col-6">
              <Link
                to="#."
                className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4"
              >
                <i className="icon-destination text-24"></i>

                <div className="ml-10 sm:ml-0 sm:mt-10">
                  <h4 className="text-16 fw-500">Madrid </h4>
                  <p className="text-14">4,090 properties </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* Featured Cruise Deals */}
      <section className="layout-pt-md layout-pb-lg">
        <div className="container">
          <div className="row y-gap-20 justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <img
                  src="cruise-icon.jpg"
                  alt=""
                  width="80"
                  className="me-2"
                  style={{ filter: "grayscale(0.4)" }}
                />
                <h2 className="sectionTitle__title"> Featured Cruise Deals</h2>
                <p className="sectionTitle__text mt-5 sm:mt-0">
                  Interdum et malesuada fames __ ante ipsum
                </p>
              </div>
            </div>
          </div>

          <div className="row y-gap-30 pt-40 sm:pt-20">
            <div className="col-lg-3 col-sm-6">
              <Link to="#." className="cruiseCard -type-1 rounded-4">
                <div className="cruiseCard__image">
                  <div className="cardImage ratio ratio-6:5">
                    <div className="cardImage__content">
                      <img
                        className="rounded-4 col-12"
                        src="/titsolutions/img/cruises/1.png"
                        alt=""
                      />
                    </div>
                    <div className="cardImage__wishlist">
                      <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                        <i className="icon-heart text-12"></i>
                      </button>
                    </div>
                    <div className="cardImage__leftBadge">
                      <div className="py-5 px-15 rounded-right-4 text-12 lh-16 fw-500 uppercase bg-dark-1 text-white">
                        Cruise only
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cruiseCard__content mt-10">
                  <div className="text-14 lh-14 text-light-1 mb-5">
                    Norwegian Getawayy
                  </div>
                  <h4 className="cruiseCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>7 Night Cruise to Western Mediterranean</span>
                  </h4>
                  <div className="row y-gap-10 justify-between items-center">
                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">
                        Sailing Date
                      </div>
                      <div className="text-14 text-light-1">18/06/2022</div>
                    </div>
                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">Departs</div>
                      <div className="text-14 text-light-1">Southampton</div>
                    </div>
                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">
                        Ports (7)
                      </div>
                      <div className="text-14 text-light-1">
                        Sete, Toulon...
                      </div>
                    </div>
                  </div>
                  <div className="row y-gap-20 justify-between items-center pt-5">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="icon-star text-yellow-1 text-10 mr-5"></div>
                        <div className="text-14 text-light-1">
                          <span className="text-15 text-dark-1 fw-500">
                            4.82
                          </span>{" "}
                          94 reviews
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="text-14 text-light-1">
                        From{" "}
                        <span className="text-16 fw-500 text-dark-1">
                          US$72
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-sm-6">
              <Link to="#." className="cruiseCard -type-1 rounded-4">
                <div className="cruiseCard__image">
                  <div className="cardImage ratio ratio-6:5">
                    <div className="cardImage__content">
                      <Swiper
                        modules={[Pagination, Navigation, Autoplay]}
                        autoplay={{
                          delay: 3000,
                          loop: false,
                          delayTimeout: 1000,
                          disableOnInteraction: false, // Continue autoplay after user interactions
                        }}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation={{
                          prevEl: ".js-prev", // Custom previous button class
                          nextEl: ".js-next", // Custom next button class
                        }} // Enable custom navigation
                        pagination={{
                          clickable: true,
                          el: ".js-pagination", // Target custom pagination class
                          renderBullet: (index, className) => {
                            return `<div className="pagination__item ${className}" tabindex="0" role="button" aria-label="Go to slide ${
                              index + 1
                            }"></div>`;
                          },
                        }}
                      >
                        <SwiperSlide>
                          <img
                            className="col-12"
                            src="/titsolutions/img/cruises/1.png"
                            alt="image"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            className="col-12"
                            src="/titsolutions/img/cruises/2.png"
                            alt="image"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            className="col-12"
                            src="/titsolutions/img/cruises/3.png"
                            alt="image"
                          />
                        </SwiperSlide>
                      </Swiper>
                      {/* Custom Pagination Element */}
                      <div className="cardImage-slider__pagination js-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"></div>

                      {/* Custom Navigation Buttons */}
                      <div className="cardImage-slider__nav -prev">
                        <button
                          className="button -blue-1 bg-white size-30 rounded-full shadow-2 js-prev"
                          tabindex="0"
                          aria-label="Previous slide"
                        >
                          <i className="icon-chevron-left text-10"></i>
                        </button>
                      </div>
                      <div className="cardImage-slider__nav -next">
                        <button
                          className="button -blue-1 bg-white size-30 rounded-full shadow-2 js-next"
                          tabindex="0"
                          aria-label="Next slide"
                        >
                          <i className="icon-chevron-right text-10"></i>
                        </button>
                      </div>
                    </div>
                    <div className="cardImage__wishlist">
                      <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                        <i className="icon-heart text-12"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="cruiseCard__content mt-10">
                  <div className="text-14 lh-14 text-light-1 mb-5">
                    Norwegian Getaway
                  </div>
                  <h4 className="cruiseCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>12 Night Cruise to British Isles & Western __</span>
                  </h4>
                  <div className="row y-gap-10 justify-between items-center">
                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">
                        Sailing Date
                      </div>
                      <div className="text-14 text-light-1">18/06/2022</div>
                    </div>
                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">Departs</div>
                      <div className="text-14 text-light-1">Southampton</div>
                    </div>
                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">
                        Ports (7)
                      </div>
                      <div className="text-14 text-light-1">
                        Sete, Toulon...
                      </div>
                    </div>
                  </div>
                  <div className="row y-gap-20 justify-between items-center pt-5">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="icon-star text-yellow-1 text-10 mr-5"></div>
                        <div className="text-14 text-light-1">
                          <span className="text-15 text-dark-1 fw-500">
                            4.82
                          </span>{" "}
                          94 reviews
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="text-14 text-light-1">
                        From{" "}
                        <span className="text-16 fw-500 text-dark-1">
                          US$72
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-sm-6">
              <Link to="#." className="cruiseCard -type-1 rounded-4 ">
                <div className="cruiseCard__image">
                  <div className="cardImage ratio ratio-6:5">
                    <div className="cardImage__content">
                      <img
                        className="rounded-4 col-12"
                        src="/titsolutions/img/cruises/3.png"
                        alt="image"
                      />
                    </div>

                    <div className="cardImage__wishlist">
                      <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                        <i className="icon-heart text-12"></i>
                      </button>
                    </div>

                    <div className="cardImage__leftBadge">
                      <div className="py-5 px-15 rounded-right-4 text-12 lh-16 fw-500 uppercase bg-blue-1 text-white">
                        Best Seller
                      </div>
                    </div>
                  </div>
                </div>

                <div className="cruiseCard__content mt-10">
                  <div className="text-14 lh-14 text-light-1 mb-5">
                    Norwegian Getaway{" "}
                  </div>

                  <h4 className="cruiseCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>11 Night Cruise to Eastern Mediterranean </span>
                  </h4>

                  <p className="text-light-1 lh-14 text-14 mt-5" />

                  <div className="row y-gap-10 justify-between items-center">
                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">
                        Sailing Date{" "}
                      </div>
                      <div className="text-14 text-light-1">18/06/2022 </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">Departs </div>
                      <div className="text-14 text-light-1">Southampton </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">
                        Ports (7){" "}
                      </div>
                      <div className="text-14 text-light-1">
                        Sete, Toulon...{" "}
                      </div>
                    </div>
                  </div>

                  <div className="row y-gap-20 justify-between items-center pt-5">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="icon-star text-yellow-1 text-10 mr-5"></div>

                        <div className="text-14 text-light-1">
                          <span className="text-15 text-dark-1 fw-500">
                            4.82{" "}
                          </span>
                          94 reviews
                        </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-14 text-light-1">
                        From
                        <span className="text-16 fw-500 text-dark-1">
                          US$72{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-sm-6">
              <Link to="#." className="cruiseCard -type-1 rounded-4 ">
                <div className="cruiseCard__image">
                  <div className="cardImage ratio ratio-6:5">
                    <div className="cardImage__content">
                      <img
                        className="rounded-4 col-12"
                        src="/titsolutions/img/cruises/4.png"
                        alt="image"
                      />
                    </div>

                    <div className="cardImage__wishlist">
                      <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                        <i className="icon-heart text-12"></i>
                      </button>
                    </div>

                    <div className="cardImage__leftBadge">
                      <div className="py-5 px-15 rounded-right-4 text-12 lh-16 fw-500 uppercase bg-yellow-1 text-dark-1">
                        Top Rated
                      </div>
                    </div>
                  </div>
                </div>

                <div className="cruiseCard__content mt-10">
                  <div className="text-14 lh-14 text-light-1 mb-5">
                    Norwegian Getawayy{" "}
                  </div>

                  <h4 className="cruiseCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>13 Night Cruise to Baltic Sea Western Europe </span>
                  </h4>

                  <p className="text-light-1 lh-14 text-14 mt-5" />

                  <div className="row y-gap-10 justify-between items-center">
                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">
                        Sailing Date{" "}
                      </div>
                      <div className="text-14 text-light-1">18/06/2022 </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">Departs </div>
                      <div className="text-14 text-light-1">Southampton </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">
                        Ports (7){" "}
                      </div>
                      <div className="text-14 text-light-1">
                        Sete, Toulon...{" "}
                      </div>
                    </div>
                  </div>

                  <div className="row y-gap-20 justify-between items-center pt-5">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="icon-star text-yellow-1 text-10 mr-5"></div>

                        <div className="text-14 text-light-1">
                          <span className="text-15 text-dark-1 fw-500">
                            4.82{" "}
                          </span>
                          94 reviews
                        </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-14 text-light-1">
                        From
                        <span className="text-16 fw-500 text-dark-1">
                          US$72{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="section-bg layout-pt-lg layout-pb-lg">
        <div className="section-bg__item -right -w-1165 bg-light-2"></div>

        <div className="section-bg__item -video-left">
          <div className="row y-gap-30">
            <div className="col-sm-6">
              <img src="/titsolutions/img/video/1.png" alt="image" />
            </div>

            <div className="col-sm-6">
              <img src="/titsolutions/img/video/2.png" alt="image" />
            </div>
          </div>
        </div>

        <div className="container lg:mt-30">
          <div className="row">
            <div className="offset-xl-6 col-xl-5 col-lg-6">
              <h2 className="text-30 fw-600">
                BookingNile.com is a World-Class Cruise Booking Platform
              </h2>
              <p className="text-dark-1 mt-40 lg:mt-20 sm:mt-15">
                Experience the finest in luxury travel, with exceptional service
                and unbeatable offers that take you on a journey like no other.
                Enjoy breathtaking views and create unforgettable memories with
                us.
              </p>

              <div className="d-inline-block mt-40 lg:mt-30 sm:mt-20">
                <Link
                  to="#."
                  className="button -md -blue-1 bg-yellow-1 text-dark-1"
                >
                  Learn More <div className="icon-arrow-top-right ml-15"></div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="layout-pt-lg layout-pb-md">
        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">Special Offers </h2>
                <p className=" sectionTitle__text mt-5 sm:mt-0">
                  Promotions, deals and special offers for you
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center y-gap-20 pt-40">
            <div className="col-lg-4 col-sm-6">
              <div className="ctaCard -type-1 rounded-4 ">
                <div className="ctaCard__image ratio ratio-41:45">
                  <img
                    className="img-ratio js-lazy"
                    src="titsolutions/img/backgrounds/1.png"
                    alt="offer"
                  />
                </div>

                <div className="ctaCard__content py-50 px-50 lg:py-30 lg:px-30">
                  <h4 className="text-30 lg:text-24 text-white">
                    Things To Do On <br /> Your Trip{" "}
                  </h4>

                  <div className="text-15 fw-500 text-white mt-10">
                    Save 15% or more when you book and stay before 1 October
                    2024
                  </div>
                  <div className="d-inline-block mt-30">
                    <Link
                      href="#"
                      className="button px-48 py-15 -blue-1 -min-180 bg-white text-dark-1"
                    >
                      Get Offer{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="ctaCard -type-1 rounded-4 ">
                <div className="ctaCard__image ratio ratio-41:45">
                  <img
                    className="img-ratio js-lazy"
                    src="titsolutions/img/backgrounds/3.png"
                    alt="offer"
                  />
                </div>

                <div className="ctaCard__content py-50 px-50 lg:py-30 lg:px-30">
                  <h4 className="text-30 lg:text-24 text-white">
                    Let Your Curiosity <br />
                    Do The Booking{" "}
                  </h4>

                  <div className="d-inline-block mt-30">
                    <a
                      href="#"
                      className="button px-48 py-15 -blue-1 -min-180 bg-white text-dark-1"
                    >
                      Learn More{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="ctaCard -type-1 rounded-4 ">
                <div className="ctaCard__image ratio ratio-41:45">
                  <img
                    className="img-ratio js-lazy"
                    src="titsolutions/img/backgrounds/2.png"
                    alt="offer"
                  />
                </div>

                <div className="ctaCard__content py-50 px-50 lg:py-30 lg:px-30">
                  <div className="text-15 fw-500 text-white mb-10">
                    Enjoy Summer Deals{" "}
                  </div>

                  <h4 className="text-30 lg:text-24 text-white">
                    Up to 70% Discount!{" "}
                  </h4>

                  <div className="d-inline-block mt-30">
                    <a
                      href="#"
                      className="button px-48 py-15 -blue-1 -min-180 bg-white text-dark-1"
                    >
                      Learn More{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="layout-pt-md layout-pb-md">
        <div className="container">
          <div className="row y-gap-20">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">Explore Egypt</h2>
                <p className="sectionTitle__text mt-5 sm:mt-0">
                  These popular destinations have a lot to offer
                </p>
              </div>
            </div>
          </div>

          <div
            className="pt-40 container mx-auto overflow-hidden"
            style={{ position: "relative" }}
          >
            <Swiper
              modules={[Navigation, Autoplay]}
              autoplay={{
                delay: 3000,
                loop: true,
                delayTimeout: 1000,
                disableOnInteraction: false, // Continue autoplay after user interactions
              }}
              spaceBetween={30}
              slidesPerView={5} // Adjust for responsiveness
              navigation={{
                prevEl: ".js-prev",
                nextEl: ".js-next",
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 4,
                },
                1440: {
                  slidesPerView: 5,
                },
              }}
            >
              {destinations.map((destination, index) => (
                <SwiperSlide key={index}>
                  <Link to="#." className="citiesCard -type-2">
                    <div className="citiesCard__image rounded-4 ratio ratio-1:1">
                      <img
                        className="img-ratio rounded-4 js-lazy"
                        data-src={destination.imgSrc}
                        src={destination.imgSrc}
                        alt={destination.city}
                      />
                    </div>
                    <div className="citiesCard__content mt-10">
                      <h4 className="text-18 lh-13 fw-500 text-dark-1">
                        {destination.city}
                      </h4>
                      <div className="text-14 text-light-1">
                        {destination.properties}
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="cardImage-slider__pagination js-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"></div>
            {/* Custom Navigation Buttons */}
            <div className="d-flex justify-center mt-30">
              <div className="cardImage-slider__nav -prev mx-4">
                <button
                  className="button -blue-1 bg-white size-50 rounded-full shadow-2 js-prev"
                  tabindex="0"
                  aria-label="Previous slide"
                >
                  <i className="icon-chevron-left text-10"></i>
                </button>
              </div>
              <div className="cardImage-slider__nav -next mx-4">
                <button
                  className="button -blue-1 bg-white size-50 rounded-full shadow-2 js-next"
                  tabindex="0"
                  aria-label="Next slide"
                >
                  <i className="icon-chevron-right text-10"></i>
                </button>
              </div>
            </div>
            {/* Custom Pagination Element */}
          </div>
        </div>
      </section>
      <section className="layout-pt-md layout-pb-md">
        <div className="container">
          <div className="row y-gap-30">
            <div className="col-xl-4 col-lg-5">
              <h2 className="text-30 fw-600">Why Choose Us </h2>
              <p className="mt-5">
                These popular destinations have a wealth of experiences to
                offer!
              </p>

              <p className="text-dark-1 mt-40 sm:mt-20">
                Experience the ultimate in luxury travel, where every journey is
                crafted to provide you with unforgettable moments. Enjoy
                stunning views and exceptional service that make your cruise
                truly special.
              </p>

              <div className="d-inline-block mt-40 sm:mt-20">
                <Link
                  to="#."
                  className="button -md -blue-1 bg-yellow-1 text-dark-1"
                >
                  Learn More <div className="icon-arrow-top-right ml-15"></div>
                </Link>
              </div>
            </div>

            <div className="col-xl-6 offset-xl-1 col-lg-7">
              <div className="row y-gap-60">
                <div className="col-sm-6">
                  <img
                    src="/titsolutions/img/featureIcons/3/1.svg"
                    alt="image"
                    className="size-60"
                  />
                  <h5 className="text-18 fw-500 mt-10">
                    Best Price Guarantee{" "}
                  </h5>
                  <p className="mt-10">
                    Lorem ipsum dolor sit , consectetur adipiscing elit.{" "}
                  </p>
                </div>

                <div className="col-sm-6">
                  <img
                    src="/titsolutions/img/featureIcons/3/2.svg"
                    alt="image"
                    className="size-60"
                  />
                  <h5 className="text-18 fw-500 mt-10">
                    Best Price Guarantee{" "}
                  </h5>
                  <p className="mt-10">
                    Lorem ipsum dolor sit , consectetur adipiscing elit.{" "}
                  </p>
                </div>

                <div className="col-sm-6">
                  <img
                    src="/titsolutions/img/featureIcons/3/3.svg"
                    alt="image"
                    className="size-60"
                  />
                  <h5 className="text-18 fw-500 mt-10">
                    Best Price Guarantee{" "}
                  </h5>
                  <p className="mt-10">
                    Lorem ipsum dolor sit , consectetur adipiscing elit.{" "}
                  </p>
                </div>

                <div className="col-sm-6">
                  <img
                    src="/titsolutions/img/featureIcons/3/4.svg"
                    alt="image"
                    className="size-60"
                  />
                  <h5 className="text-18 fw-500 mt-10">
                    Best Price Guarantee{" "}
                  </h5>
                  <p className="mt-10">
                    Lorem ipsum dolor sit , consectetur adipiscing elit.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="layout-pt-md layout-pb-lg">
        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">
                  Get Inspiration For your Next Trip{" "}
                </h2>
                <p className=" sectionTitle__text mt-5 sm:mt-0">
                  Interdum et malesuada fames{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="row y-gap-30 pt-40">
            <div className="col-lg-6">
              <Link to="#." className="row y-gap-20 items-center">
                <div className="col-auto">
                  <img
                    className="size-250 rounded-4"
                    src="titsolutions/img/blog/1.png"
                    alt=""
                  />
                </div>

                <div className="col">
                  <div className="text-15 text-light-1">April 06, 2022 </div>
                  <h4 className="text-22 fw-600 text-dark-1 mt-10">
                    10 European ski destinations should visit this winter
                  </h4>
                  <p className="mt-10">
                    Ut enim ad minim , quis nostrud exerc ullamco nisi ut
                    aliquip.{" "}
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-lg-6">
              <Link to="#." className="row y-gap-20 items-center">
                <div className="col-auto">
                  <img
                    className="size-250 rounded-4"
                    src="titsolutions/img/blog/2.png"
                    alt=""
                  />
                </div>

                <div className="col">
                  <div className="text-15 text-light-1">April 06, 2022 </div>
                  <h4 className="text-22 fw-600 text-dark-1 mt-10">
                    Booking travel during Corona: advice in an uncertain{" "}
                  </h4>
                  <p className="mt-10">
                    Ut enim ad minim , quis nostrud exerc ullamco nisi ut
                    aliquip.{" "}
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
