import React, { useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { colors, Slider } from "@mui/material";
import useGoogleMapsScript from "../components/useGoogleMapsScript";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import Counter from "../components/Counter";

const AboutUs = () => {
  return (
    <>
      {" "}
      <Helmet>
        <title>About Us</title>
        <style>
          {`.layout-pt-lg{
                padding-top: 200px;
                    padding-bottom: 150px;
          }
          `}
        </style>
      </Helmet>
      {/*banner */}
      <section class="section-bg layout-pt-lg layout-pb-lg">
        <div class="section-bg__item overlay col-12">
          <img src="about-bg.jpg" alt="banner" />
        </div>

        <div class="container">
          <div class="row justify-left text-start">
            <div class="col-xl-6 col-lg-8 col-md-10">
              <h1 class="text-40 md:text-25 fw-600 text-white">
                Looking To Be Partner?{" "}
              </h1>
              <div className="col-lg-3 mt-3">
                <Link
                  to="/become-partner"
                  className="button px-24 h-50 -dark-1 bg-blue-1 text-white"
                >
                  Join Us <div className="icon-arrow-top-right ps-2"></div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-flex items-center">
        <div className="container   py-20 border-bottom-light">
          <div className="row y-gap-10 items-center justify-between">
            <div className="col-auto">
              <div className="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div className="col-auto">
                  <div className="">
                    <Link to="/">Home</Link>{" "}
                  </div>
                </div>
                <div className="col-auto">
                  <div className="">{">"} </div>
                </div>
                <div className="col-auto">
                  <div className="">About Us </div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              Quick Contact:{" "}
              <Link to="tel:+201000972210" className="text-14 text-light-1">
                +(20) 10 00972210{" "}
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section class="layout-pt-md">
        <div class="container">
          <div class="row justify-content-between  align-items-center">
            <div class="col-lg-5">
              <h2 class="text-30 fw-600">About Booking Nile </h2>
              <p class="mt-5">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
              </p>

              <p class="text-dark-1 mt-30 lg:mt-30 md:mt-20">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ad,
                itaque ipsum corporis libero obcaecati voluptates ea, ratione
                temporibus eius corrupti dolorem atque unde voluptatibus illo
                autem cumque tempora esse!
              </p>
              <p class="text-dark-1 ">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ad,
                itaque ipsum corporis libero obcaecati voluptates ea, ratione
                temporibus eius corrupti dolorem atque unde voluptatibus illo
                autem cumque tempora esse!
              </p>
              <p class="text-dark-1 ">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ad,
                itaque ipsum corporis libero obcaecati voluptates ea, ratione
                temporibus eius corrupti dolorem atque unde voluptatibus illo
                autem cumque tempora esse!
              </p>
            </div>

            <div class="col-lg-6">
              <img
                src="titsolutions/img/pages/about/2.png"
                alt=""
                class="rounded-4"
              />
            </div>
          </div>
        </div>
      </section>
      <section class="pt-60">
        <div class="container">
          <div class="border-bottom-light pb-40">
            <div class="row y-gap-30 justify-center text-center">
              <div class="col-xl-3 col-6">
                <div class="text-40 lg:text-30 lh-13 fw-600">
                  {" "}
                  <Counter start={0} end="50" duration={2} />
                </div>
                <div class="text-14 lh-14 text-light-1 mt-5">Cruises </div>
              </div>

              <div class="col-xl-3 col-6">
                <div class="text-40 lg:text-30 lh-13 fw-600">
                  {" "}
                  <Counter start={0} end="2869" duration={500} />{" "}
                </div>
                <div class="text-14 lh-14 text-light-1 mt-5">
                  Total Properties{" "}
                </div>
              </div>

              <div class="col-xl-3 col-6">
                <div class="text-40 lg:text-30 lh-13 fw-600">
                  <Counter start={0} end="2" duration={1} /> M{" "}
                </div>
                <div class="text-14 lh-14 text-light-1 mt-5">
                  Happy customers{" "}
                </div>
              </div>

              <div class="col-xl-3 col-6">
                <div class="text-40 lg:text-30 lh-13 fw-600">
                  <Counter start={0} end="574974" duration={1000} />{" "}
                </div>
                <div class="text-14 lh-14 text-light-1 mt-5">
                  Our Volunteers{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="layout-pt-lg layout-pb-lg bg-blue-2">
        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">Why Choose Us </h2>
                <p className=" sectionTitle__text mt-5 sm:mt-0">
                  These popular destinations have _ lot to offer{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="row y-gap-40 justify-between pt-50">
            <div className="col-lg-3 col-sm-6">
              <div className="featureIcon -type-1 ">
                <div className="d-flex justify-center">
                  <img
                    src="titsolutions/img/featureIcons/1/1.svg"
                    data-src="titsolutions/img/featureIcons/1/1.svg"
                    alt=""
                    className="js-lazy"
                  />
                </div>

                <div className="text-center mt-30">
                  <h4 className="text-18 fw-500">Best Price Guarantee </h4>
                  <p className="text-15 mt-10">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="featureIcon -type-1 ">
                <div className="d-flex justify-center">
                  <img
                    src="titsolutions/img/featureIcons/1/2.svg"
                    data-src="titsolutions/img/featureIcons/1/2.svg"
                    alt=" "
                    className="js-lazy"
                  />
                </div>

                <div className="text-center mt-30">
                  <h4 className="text-18 fw-500">Easy & Quick Booking </h4>
                  <p className="text-15 mt-10">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="featureIcon -type-1 ">
                <div className="d-flex justify-center">
                  <img
                    src="titsolutions/img/featureIcons/1/3.svg"
                    data-src="titsolutions/img/featureIcons/1/3.svg"
                    alt=" "
                    className="js-lazy"
                  />
                </div>

                <div className="text-center mt-30">
                  <h4 className="text-18 fw-500">Customer Care 24/7 </h4>
                  <p className="text-15 mt-10">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
