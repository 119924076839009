import React, { useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { colors, Slider } from "@mui/material";
import useGoogleMapsScript from "../components/useGoogleMapsScript";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const HelpCenter = () => {
  const [activeFaq, setActiveFaq] = useState(null);

  // Function to toggle accordion item
  const toggleAccordion = (index) => {
    setActiveFaq(activeFaq === index ? null : index);
  };
  const faqItems = [
    {
      question: "What do I need to hire a car?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "How old do I have to be to rent a car?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Can I book a rental car for someone else?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "How do I find the cheapest car hire deal?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "What should I look for when choosing a car hire?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];
  return (
    <>
      {" "}
      <Helmet>
        <title>Help Center</title>{" "}
        <style>
          {`
            .header .header-menu .menu li a{
            color:#051036;
            }
            .header .header-menu .menu .subnav>li>a {
            color:#051036 !important;
            }
            .is-sticky.bg-dark-1{
            background-color:#fff !important;
            }
            // .is-sticky .menu li a{
            // color:#fff !important;
            // }
            .ratio-15\:9::before{
            padding-bottom:0 !important;}
            `}
        </style>
      </Helmet>
      {/*banner */}
      <section className="d-flex items-center  mt-90  ">
        <div className="container  mt-40 py-20 border-top-light">
          <div className="row y-gap-10 items-center justify-between">
            <div className="col-auto">
              <div className="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div className="col-auto">
                  <div className="">
                    <Link to="/">Home</Link>{" "}
                  </div>
                </div>
                <div className="col-auto">
                  <div className="">{">"} </div>
                </div>
                <div className="col-auto">
                  <div className="">Help Center </div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              Quick Contact:{" "}
              <Link to="tel:+201000972210" className="text-14 text-light-1">
                +(20) 10 00972210{" "}
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section class="layout-pt-md">
        <div class="container">
          <div class="row justify-center text-center">
            <div class="col-xl-6 col-lg-8 col-md-10">
              <div class="sectionTitle -md">
                <h2 class="sectionTitle__title">Welcome to the Help ______ </h2>
                <p class=" sectionTitle__text mt-5 sm:mt-0">
                  Lorem ipsum is placeholder ____ commonly used in site.{" "}
                </p>
              </div>

              <div class="single-field d-flex items-center justify-end x-gap-10 y-gap-20 pt-40">
                <div class="col-12">
                  <input
                    class="border-light bg-white h-68"
                    type="text"
                    placeholder="Cancellation, meeting point, etc."
                  />
                </div>

                <div class="absolute mr-4">
                  <button class="button -md -dark-1 h-60 bg-blue-1 text-white">
                    Search{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row y-gap-30 pt-60 lg:pt-40">
            <div class="col-lg-4 col-md-6">
              <div class="bg-blue-1-05 rounded-4 px-50 py-40">
                <div class="size-70 bg-white rounded-full flex-center">
                  <img
                    src="titsolutions/img/pages/help/icons/1.svg"
                    alt="icon"
                  />
                </div>

                <div class="mt-24">
                  <div class="text-18 fw-500">Booking your activity </div>
                  <p class="mt-5">
                    Lorem ipsum is placeholder ____ commonly used in site.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="bg-blue-1-05 rounded-4 px-50 py-40">
                <div class="size-70 bg-white rounded-full flex-center">
                  <img
                    src="titsolutions/img/pages/help/icons/2.svg"
                    alt="icon"
                  />
                </div>

                <div class="mt-24">
                  <div class="text-18 fw-500">Payment &amp; receipts </div>
                  <p class="mt-5">
                    Lorem ipsum is placeholder ____ commonly used in site.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="bg-blue-1-05 rounded-4 px-50 py-40">
                <div class="size-70 bg-white rounded-full flex-center">
                  <img
                    src="titsolutions/img/pages/help/icons/3.svg"
                    alt="icon"
                  />
                </div>

                <div class="mt-24">
                  <div class="text-18 fw-500">
                    Booking changes &amp; refunds{" "}
                  </div>
                  <p class="mt-5">
                    Lorem ipsum is placeholder ____ commonly used in site.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="bg-blue-1-05 rounded-4 px-50 py-40">
                <div class="size-70 bg-white rounded-full flex-center">
                  <img
                    src="titsolutions/img/pages/help/icons/4.svg"
                    alt="icon"
                  />
                </div>

                <div class="mt-24">
                  <div class="text-18 fw-500">Promo codes &amp; credits </div>
                  <p class="mt-5">
                    Lorem ipsum is placeholder ____ commonly used in site.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="bg-blue-1-05 rounded-4 px-50 py-40">
                <div class="size-70 bg-white rounded-full flex-center">
                  <img
                    src="titsolutions/img/pages/help/icons/5.svg"
                    alt="icon"
                  />
                </div>

                <div class="mt-24">
                  <div class="text-18 fw-500">On the participation day </div>
                  <p class="mt-5">
                    Lorem ipsum is placeholder ____ commonly used in site.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="bg-blue-1-05 rounded-4 px-50 py-40">
                <div class="size-70 bg-white rounded-full flex-center">
                  <img
                    src="titsolutions/img/pages/help/icons/6.svg"
                    alt="icon"
                  />
                </div>

                <div class="mt-24">
                  <div class="text-18 fw-500">Value Packs </div>
                  <p class="mt-5">
                    Lorem ipsum is placeholder ____ commonly used in site.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
      <section id="faq" className="pt-40 layout-pb-lg">
        <div className="container">
          <div className="pt-40  ">
            <div class="row justify-center text-center">
              <div class="col-auto">
                <div class="sectionTitle -md">
                  <h2 class="sectionTitle__title">
                    Frequently Asked Questions{" "}
                  </h2>
                  <p class=" sectionTitle__text mt-5 sm:mt-0">
                    Interdum et malesuada fames{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row y-gap-30 justify-center pt-40 sm:pt-20">
              <div className="col-xl-8 col-lg-10">
                <div className="accordion -simple row y-gap-20">
                  {faqItems.map((item, index) => (
                    <div className="col-12" key={index}>
                      <div className="accordion__item px-20 py-20 border-light rounded-4 ">
                        <div
                          className="accordion__button d-flex items-center "
                          onClick={() => toggleAccordion(index)}
                        >
                          <div className="accordion__icon size-40 flex-center bg-light-2 rounded-full mr-20">
                            <i
                              className={
                                activeFaq === index ? "icon-minus" : "icon-plus"
                              }
                            ></i>
                          </div>
                          <div className="button text-dark-1">
                            {item.question}
                          </div>
                        </div>
                        {activeFaq === index && (
                          <div
                            className="accordion__content"
                            style={{ maxHeight: "100%" }}
                          >
                            <div className="pt-20 pl-60">
                              <p className="text-15">{item.answer}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HelpCenter;
