import React, { useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { colors, Slider } from "@mui/material";
import useGoogleMapsScript from "../components/useGoogleMapsScript";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const Signin = () => {
  return (
    <>
      {" "}
      <Helmet>
        <title>Sign In</title>{" "}
        <style>
          {`
            .header .header-menu .menu li a{
            color:#051036;
            }
            .header .header-menu .menu .subnav>li>a {
            color:#051036 !important;
            }
            .is-sticky.bg-dark-1{
            background-color:#fff !important;
            }
            // .is-sticky .menu li a{
            // color:#fff !important;
            // }
            .ratio-15\:9::before{
            padding-bottom:0 !important;}
            `}
        </style>
      </Helmet>
      {/*banner */}
      <section className="d-flex items-center  mt-90  ">
        <div className="container  mt-40 py-20 border-top-light">
          <div className="row y-gap-10 items-center justify-between">
            <div className="col-auto">
              <div className="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div className="col-auto">
                  <div className="">
                    <Link to="/">Home</Link>{" "}
                  </div>
                </div>
                <div className="col-auto">
                  <div className="">{">"} </div>
                </div>
                <div className="col-auto">
                  <div className="">Sign In</div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              Quick Contact:{" "}
              <Link to="tel:+201000972210" className="text-14 text-light-1">
                +(20) 10 00972210{" "}
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section class="layout-pt-md layout-pb-md bg-blue-2">
        <div class="container">
          <div class="row justify-center">
            <div class="col-xl-6 col-lg-7 col-md-9">
              <div class="px-50 py-50 sm:px-20 sm:py-20 bg-white shadow-4 rounded-4">
                <div class="row y-gap-20">
                  <div class="col-12">
                    <h1 class="text-22 fw-500">Welcome back </h1>
                    <p class="mt-10">
                      Don't have an account ?{" "}
                      <Link to="/signup" class="text-blue-1">
                        Sign up for free{" "}
                      </Link>
                    </p>
                  </div>

                  <div class="col-12">
                    <div class="form-input ">
                      <input type="text" required="" />
                      <label class="lh-1 text-14 text-light-1">Email </label>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-input ">
                      <input type="password" required="" />
                      <label class="lh-1 text-14 text-light-1">Password </label>
                    </div>
                  </div>

                  <div class="col-12">
                    <Link to="#." class="text-14 fw-500 text-blue-1 underline">
                      Forgot your password?{" "}
                    </Link>
                  </div>

                  <div class="col-12">
                    <Link
                      to="/"
                      class="button py-20 -dark-1 bg-blue-1 text-white"
                    >
                      Sign In <div class="icon-arrow-top-right ml-15"></div>
                    </Link>
                  </div>
                </div>

                {/* <div class="row y-gap-20 pt-30">
                  <div class="col-12">
                    <div class="text-center">or sign in with </div>

                    <button class="button col-12 -outline-blue-1 text-blue-1 py-15 rounded-8 mt-10">
                      <i class="icon-apple text-15 mr-10"></i>
                      Facebook
                    </button>

                    <button class="button col-12 -outline-red-1 text-red-1 py-15 rounded-8 mt-15">
                      <i class="icon-apple text-15 mr-10"></i>
                      Google
                    </button>

                    <button class="button col-12 -outline-dark-2 text-dark-2 py-15 rounded-8 mt-15">
                      <i class="icon-apple text-15 mr-10"></i>
                      Apple
                    </button>
                  </div>

                  <div class="col-12">
                    <div class="text-center px-30">
                      By creating an account, agree to our Terms __ Service and
                      Privacy Statement.{" "}
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signin;
