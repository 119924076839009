import React, { useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { colors, Slider } from "@mui/material";
import useGoogleMapsScript from "../components/useGoogleMapsScript";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const ContactUs = () => {
  const mapRef = useRef(null);
  const googleMapsApiKey = "AIzaSyAAz77U5XQuEME6TpftaMdX0bBelQxXRlM"; // Replace with your actual API key

  useGoogleMapsScript(googleMapsApiKey); // Load the Google Maps script

  useEffect(() => {
    // Initialize the map once the script has loaded
    if (window.google && mapRef.current) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: -34.397, lng: 150.644 }, // Default center
        zoom: 8,
      });
    }
  }, []);
  return (
    <>
      {" "}
      <Helmet>
        <title>Contact Us</title>{" "}
        <style>
          {`
            .header .header-menu .menu li a{
            color:#051036;
            }
            .header .header-menu .menu .subnav>li>a {
            color:#051036 !important;
            }
            .is-sticky.bg-dark-1{
            background-color:#fff !important;
            }
            // .is-sticky .menu li a{
            // color:#fff !important;
            // }
            .ratio-15\:9::before{
            padding-bottom:0 !important;}
            `}
        </style>
      </Helmet>
      {/*banner */}
      <section className="d-flex items-center  mt-90  ">
        <div className="container  mt-40 py-20 border-top-light">
          <div className="row y-gap-10 items-center justify-between">
            <div className="col-auto">
              <div className="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div className="col-auto">
                  <div className="">
                    <Link to="/">Home</Link>{" "}
                  </div>
                </div>
                <div className="col-auto">
                  <div className="">{">"} </div>
                </div>
                <div className="col-auto">
                  <div className="">Contact Us </div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              Quick Contact:{" "}
              <Link to="tel:+201000972210" className="text-14 text-light-1">
                +(20) 10 00972210{" "}
              </Link>
            </div>
          </div>
        </div>
      </section>
      <div className="ratio ratio-16:9">
        <div className="map-ratio">
          {/* <div className="map js-map-single"></div> */}
          <div style={{ width: "100%", height: "90%" }}>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3127.63299949799!2d31.279055!3d29.975206!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458386385feeaf5%3A0x713c1e0d0ddc436a!2sT.I.T%20Solutions!5e1!3m2!1sen!2seg!4v1726881873089!5m2!1sen!2seg"
              style={{ width: "100%", height: "90%", border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      <section>
        <div className="relative container">
          <div className="row justify-end">
            <div className="col-xl-5 col-lg-7">
              <div className="map-form px-40 pt-40 pb-50 lg:px-30 lg:py-30 md:px-24 md:py-24 bg-white rounded-4 shadow-4">
                <div className="text-22 fw-500">Send a message</div>

                <div className="row y-gap-20 pt-20">
                  <div className="col-12">
                    <div className="form-input ">
                      <input type="text" required="" />
                      <label className="lh-1 text-16 text-light-1">
                        Full Name{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input ">
                      <input type="text" required="" />
                      <label className="lh-1 text-16 text-light-1">
                        Email{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input ">
                      <input type="text" required="" />
                      <label className="lh-1 text-16 text-light-1">
                        Subject{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input ">
                      <textarea required="" rows="4"></textarea>
                      <label className="lh-1 text-16 text-light-1">
                        Your Messages{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-auto">
                    <Link
                      to="#."
                      className="button px-24 h-50 -dark-1 bg-blue-1 text-white"
                    >
                      Send a Messsage{" "}
                      <div className="icon-arrow-top-right ml-15"></div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="layout-pb-lg">
        <div className="container">
          <div className="row x-gap-80 y-gap-20 justify-between">
            <div className="col-12">
              <div className="text-30 sm:text-24 fw-600">Contact Us </div>
            </div>

            <div className="col-lg-3">
              <div className="text-14 text-light-1">
                <FontAwesomeIcon
                  icon={faLocationDot}
                  style={{ color: "#3554D1" }}
                />{" "}
                Address{" "}
              </div>
              <div className="text-18 fw-500 mt-10">
                28 ElNasr St, New, Maadi, Cairo Governorate 11742.{" "}
              </div>
            </div>

            <div className="col-auto">
              <div className="text-14 text-light-1">
                <FontAwesomeIcon icon={faPhone} style={{ color: "#3554D1" }} />{" "}
                Toll Free Customer Care{" "}
              </div>
              <div className="text-18 fw-500 mt-10">+(20) 10 00972210</div>
            </div>

            <div className="col-auto">
              <div className="text-14 text-light-1">
                {" "}
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ color: "#3554D1" }}
                />{" "}
                Need live support?{" "}
              </div>
              <div className="text-18 fw-500 mt-10">info@bookingnile.com </div>
            </div>

            <div className="col-auto">
              <div className="text-14 text-light-1">Follow us on social </div>
              <div className="d-flex x-gap-20 items-center mt-10">
                <Link to="#.">
                  <i className="icon-facebook text-14"></i>
                </Link>
                <Link to="#.">
                  <i className="icon-twitter text-14"></i>
                </Link>
                <Link to="#.">
                  <i className="icon-instagram text-14"></i>
                </Link>
                <Link to="#.">
                  <i className="icon-linkedin text-14"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="layout-pt-lg layout-pb-lg bg-blue-2">
        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">Why Choose Us </h2>
                <p className=" sectionTitle__text mt-5 sm:mt-0">
                  These popular destinations have _ lot to offer{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="row y-gap-40 justify-between pt-50">
            <div className="col-lg-3 col-sm-6">
              <div className="featureIcon -type-1 ">
                <div className="d-flex justify-center">
                  <img
                    src="titsolutions/img/featureIcons/1/1.svg"
                    data-src="titsolutions/img/featureIcons/1/1.svg"
                    alt=""
                    className="js-lazy"
                  />
                </div>

                <div className="text-center mt-30">
                  <h4 className="text-18 fw-500">Best Price Guarantee </h4>
                  <p className="text-15 mt-10">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="featureIcon -type-1 ">
                <div className="d-flex justify-center">
                  <img
                    src="titsolutions/img/featureIcons/1/2.svg"
                    data-src="titsolutions/img/featureIcons/1/2.svg"
                    alt=" "
                    className="js-lazy"
                  />
                </div>

                <div className="text-center mt-30">
                  <h4 className="text-18 fw-500">Easy & Quick Booking </h4>
                  <p className="text-15 mt-10">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="featureIcon -type-1 ">
                <div className="d-flex justify-center">
                  <img
                    src="titsolutions/img/featureIcons/1/3.svg"
                    data-src="titsolutions/img/featureIcons/1/3.svg"
                    alt=" "
                    className="js-lazy"
                  />
                </div>

                <div className="text-center mt-30">
                  <h4 className="text-18 fw-500">Customer Care 24/7 </h4>
                  <p className="text-15 mt-10">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
