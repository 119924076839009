import React, { useEffect, useRef, useState } from "react";
import { PageFlip } from "page-flip";
import { Helmet } from "react-helmet";

const images = [
  {
    src: "https://steigenbergeraldauresort.com/images/uploads/Magazine/LS-01.jpg",
    alt: "Image 1",
  },
  {
    src: "https://steigenbergeraldauresort.com/images/uploads/Magazine/LS-02.jpg",
    alt: "Image 2",
  },
  {
    src: "https://steigenbergeraldauresort.com/images/uploads/Magazine/LS-03.jpg",
    alt: "Image 3",
  },
  {
    src: "https://steigenbergeraldauresort.com/images/uploads/Magazine/LS-04.jpg",
    alt: "Image 4",
  },
  {
    src: "https://steigenbergeraldauresort.com/images/uploads/Magazine/LS-05.jpg",
    alt: "Image 5",
  },
  {
    src: "https://steigenbergeraldauresort.com/images/uploads/Magazine/LS-06.jpg",
    alt: "Image 5",
  },
  {
    src: "https://steigenbergeraldauresort.com/images/uploads/Magazine/LS-07.jpg",
    alt: "Image 5",
  },
  {
    src: "https://steigenbergeraldauresort.com/images/uploads/Magazine/LS-08.jpg",
    alt: "Image 5",
  },
  {
    src: "https://steigenbergeraldauresort.com/images/uploads/Magazine/LS-09.jpg",
    alt: "Image 5",
  },
  {
    src: "https://steigenbergeraldauresort.com/images/uploads/Magazine/LS-10.jpg",
    alt: "Image 5",
  },
  {
    src: "https://steigenbergeraldauresort.com/images/uploads/Magazine/LS-11.jpg",
    alt: "Image 5",
  },
  {
    src: "https://steigenbergeraldauresort.com/images/uploads/Magazine/LS-12.jpg",
    alt: "Image 5",
  },
];

const MagazineViewer = () => {
  const bookRef = useRef(null);
  const pageFlipRef = useRef(null); // Store the PageFlip instance here
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    pageFlipRef.current = new PageFlip(bookRef.current, {
      width: 500,
      height: 600,

      minWidth: 315,
      maxWidth: 1000,
      minHeight: 420,
      maxHeight: 1350,
      maxShadowOpacity: 0.5,
      showCover: true,
      mobileScrollSupport: false,
    });

    // Load images into the flipbook
    pageFlipRef.current.loadFromHTML(document.querySelectorAll(".page"));
    setTotalPages(pageFlipRef.current.getPageCount());

    // Event listeners
    pageFlipRef.current.on("flip", (e) => {
      setCurrentPage(e.data + 1);
    });

    return () => {
      pageFlipRef.current.destroy(); // Cleanup on unmount
    };
  }, []);

  const flipNextPage = () => {
    pageFlipRef.current.flipNext(); // Accessing the pageFlip instance from ref
  };

  const flipPrevPage = () => {
    pageFlipRef.current.flipPrev(); // Accessing the pageFlip instance from ref
  };

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="MagazineViewer.css" />
      </Helmet>
      <div className="container">
        <div>
          <button type="button" className="btn-prev" onClick={flipPrevPage}>
            Previous
          </button>
          <span className="page-info">
            [<span className="page-current">{currentPage}</span> of{" "}
            <span className="page-total">{totalPages}</span>]
          </span>
          <button type="button" className="btn-next" onClick={flipNextPage}>
            Next
          </button>
        </div>

        <div className="flip-book-container" ref={bookRef}>
          <div className="flip-book" id="magazineBook">
            <div className="page page-cover page-cover-top" data-density="hard">
              <div className="page-content">
                <img
                  src="https://steigenbergeraldauresort.com/images/uploads/Magazine/LS-01.jpg"
                  className="page-image"
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>

            {images.map((image, index) => (
              <div className="page" key={index}>
                <div className="page-content">
                  <img src={image.src} alt={image.alt} className="page-image" />
                </div>
                <div className="page-footer">{index + 1}</div>
              </div>
            ))}

            <div
              className="page page-cover page-cover-bottom"
              data-density="hard"
            >
              <div className="page-content">
                <h2>THE END</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MagazineViewer;
