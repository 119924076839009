import React, { useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { colors, Slider } from "@mui/material";
import useGoogleMapsScript from "../components/useGoogleMapsScript";
import { useEffect } from "react";

const labels = {
  1: "Useless",
  2: "Poor",

  3: "Ok",

  4: "Good",
  5: "Excellent",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

function valuetext(value) {
  return `${value}°C`;
}
const ListingCruises = () => {
  const mapRef = useRef(null);
  const googleMapsApiKey = "YOUR_API_KEY"; // Replace with your actual API key

  useGoogleMapsScript(googleMapsApiKey); // Load the Google Maps script

  useEffect(() => {
    // Initialize the map once the script has loaded
    if (window.google && mapRef.current) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: -34.397, lng: 150.644 }, // Default center
        zoom: 8,
      });
    }
  }, []);
  const [isActiveMapFilter, setIsActiveMapFilter] = useState(false);
  const [valueStar, setValueStar] = React.useState(3);
  const [hover, setHover] = React.useState(-1);
  const [valuePrice, setValuePrice] = React.useState([20, 37]);

  const handlePriceChange = (event, newValue) => {
    setValuePrice(newValue);
  };
  const navigate = useNavigate();
  const locations = [
    { name: "London", description: "Greater London, United Kingdom" },
    { name: "New York", description: "New York State, United States" },
    { name: "Paris", description: "France" },
    { name: "Madrid", description: "Spain" },
    { name: "Santorini", description: "Greece" },
    { name: "Egypt", description: "Cairo, Egypt" },
  ];
  const [isActiveDestination, setIsActiveDestination] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const searchMenuRef = useRef(null);

  // Toggle the active state on click
  const handleDestinationClick = () => {
    setIsActiveDestination(!isActiveDestination);
  };

  // Handle blur event
  const handleBlur = (event) => {
    if (
      searchMenuRef.current &&
      !searchMenuRef.current.contains(event.relatedTarget)
    ) {
      setIsActiveDestination(false);
    }
  };

  // Handle selection of a location
  const handleLocationSelect = (location) => {
    setSelectedLocation(location.name); // Set the selected location
    setSearchTerm(""); // Clear the search term
    setIsActiveDestination(false); // Close the dropdown after selection
  };

  // Filter locations based on search term
  const filteredLocations = locations.filter((location) =>
    location.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [value, setValue] = useState([null, null]);

  const handleChange = (dates) => {
    setValue(dates);
  };

  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(1);
  const [rooms, setRooms] = useState(1);

  const handleIncrement = (setter) => {
    setter((prev) => prev + 1);
  };

  const handleDecrement = (setter) => {
    setter((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const [isActiveGuest, setIsActiveGuest] = useState(false);

  const GuestMenuRef = useRef(null);

  // Toggle the active state on click
  const handleGuestClick = () => {
    setIsActiveGuest(!isActiveGuest);
  };
  const handleGuestBlur = (event) => {
    if (
      GuestMenuRef.current &&
      !GuestMenuRef.current.contains(event.relatedTarget)
    ) {
      setIsActiveGuest(false);
    }
  };
  const formatDate = (date) => {
    if (!date) return "";
    return format(date, "EEE d MMM"); // e.g., "Wed 2 Mar"
  };
  const disablePastDates = (date) => {
    const today = new Date();
    return date < today.setHours(0, 0, 0, 0); // Disable past dates
  };
  return (
    <>
      {" "}
      <Helmet>
        <title>Booking Nile</title>
        <style>
          {`
            .header .header-menu .menu li a{
            color:#051036;
            }
            .header .header-menu .menu .subnav>li>a {
            color:#051036 !important;
            }
            .is-sticky.bg-dark-1{
            background-color:#fff !important;
            }
            // .is-sticky .menu li a{
            // color:#fff !important;
            // }
            .ratio-15\:9::before{
            padding-bottom:0 !important;}
            `}
        </style>
      </Helmet>
      {/*banner */}
      <section className="pt-90 pb-50 bg-light-2">
        <div className="container">
          <div className="row pt-90">
            <div className="col-12">
              <div className="text-center">
                <h1 className="text-30 fw-600" style={{ color: "#051036" }}>
                  Aswan: 11 Cruises found
                </h1>
              </div>

              <div className="tabs -underline mt-60 js-tabs is-in-view">
                {/*    <div className="tabs__controls d-flex x-gap-30 y-gap-20 justify-center sm:justify-start js-tabs-controls">
                 <div className="">
                    <button
                      className="tabs__button text-15 fw-500 text-white pb-4 js-tabs-button is-tab-el-active"
                      data-tab-target=".-tab-item-1"
                    >
                      Cruise
                    </button>
                  </div> 
                </div>*/}

                <div className="tabs__content mt-30 md:mt-20 js-tabs-content">
                  <div className="tabs__pane -tab-item-1 is-tab-el-active">
                    <div className="mainSearch -w-1000 bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 rounded-100">
                      <div className="button-grid items-center">
                        <div
                          ref={searchMenuRef}
                          className={`searchMenu-loc px-30 lg:py-20 lg:px-0 js-form-dd js-liverSearch  ${
                            isActiveDestination ? "-is-dd-wrap-active" : ""
                          }`}
                          onClick={handleDestinationClick}
                        >
                          <div
                            onBlur={handleBlur}
                            data-x-dd-click="searchMenu-loc"
                          >
                            <h4 className="text-15 fw-500 ls-2 lh-16">
                              Location
                            </h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                              <input
                                autoComplete="off"
                                type="search"
                                placeholder="Where are you going?"
                                className="js-search js-dd-focus"
                                value={selectedLocation} // Set the input value to the selected location
                                onChange={(e) => {
                                  setSelectedLocation(e.target.value);
                                  setSearchTerm(e.target.value); // Update search term to filter results
                                }}
                              />
                            </div>
                          </div>

                          <div
                            className={`searchMenu-loc__field shadow-2 js-popup-window ${
                              isActiveDestination ? "-is-active" : ""
                            }`}
                            data-x-dd="searchMenu-loc"
                            data-x-dd-toggle="-is-active"
                          >
                            <div className="bg-white px-30 py-30 sm:px-0 sm:py-15 rounded-4">
                              <div className="y-gap-5 js-results">
                                {filteredLocations.length > 0 ? (
                                  filteredLocations.map((location) => (
                                    <div key={location.name}>
                                      <button
                                        className="-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option"
                                        onClick={() =>
                                          handleLocationSelect(location)
                                        }
                                      >
                                        <div className="d-flex">
                                          <div className="icon-location-2 text-light-1 text-20 pt-4"></div>
                                          <div className="ml-10">
                                            <div className="text-15 lh-12 fw-500">
                                              {location.name}
                                            </div>
                                            <div className="text-14 lh-12 text-light-1 mt-5">
                                              {location.description}
                                            </div>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                  ))
                                ) : (
                                  <div className="text-center py-15">
                                    No results found
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="searchMenu-date px-10 lg:py-20 lg:px-0 -is-dd-wrap-active">
                          <div>
                            <h4 className="text-15 fw-500 ls-2 lh-16">
                              Check in - Check out
                            </h4>
                            <DateRangePicker
                              value={value}
                              onChange={handleChange}
                              placeholder="Select Date Range"
                              className="text-15 text-light-1 ls-2 lh-16"
                              disabledDate={disablePastDates} // Add this prop to disable past dates
                              renderValue={(dates) => {
                                if (!dates[0] || !dates[1])
                                  return "Select Date Range";
                                return `${formatDate(dates[0])} - ${formatDate(
                                  dates[1]
                                )}`;
                              }}
                            />
                          </div>
                        </div>

                        <div
                          ref={GuestMenuRef}
                          className={`searchMenu-guests px-30 lg:py-20 lg:px-0 js-form-dd js-form-counters ${
                            isActiveGuest ? "-is-dd-wrap-active" : ""
                          }`}
                          onBlur={handleGuestBlur}
                        >
                          <div
                            data-x-dd-click="searchMenu-guests"
                            onClick={handleGuestClick}
                          >
                            <h4 className="text-15 fw-500 ls-2 lh-16">Guest</h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                              <span className="js-count-adult">{adults}</span>{" "}
                              adults -
                              <span className="js-count-child">{children}</span>{" "}
                              children -
                              <span className="js-count-room">{rooms}</span>{" "}
                              room{rooms > 1 ? "s" : ""}
                            </div>
                          </div>

                          <div
                            className={`searchMenu-guests__field shadow-2  ${
                              isActiveGuest ? "-is-active" : ""
                            }`}
                          >
                            <div className="bg-white px-30 py-30 rounded-4">
                              <div className="row y-gap-10 justify-between items-center">
                                <div className="col-auto">
                                  <div className="text-15 fw-500">Adults</div>
                                </div>

                                <div className="col-auto">
                                  <div className="d-flex items-center js-counter">
                                    <button
                                      className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                                      onClick={() => handleDecrement(setAdults)}
                                    >
                                      <i className="icon-minus text-12"></i>
                                    </button>

                                    <div className="flex-center size-20 ml-15 mr-15">
                                      <div className="text-15">{adults}</div>
                                    </div>

                                    <button
                                      className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                                      onClick={() => handleIncrement(setAdults)}
                                    >
                                      <i className="icon-plus text-12"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="border-top-light mt-24 mb-24"></div>

                              <div className="row y-gap-10 justify-between items-center">
                                <div className="col-auto">
                                  <div className="text-15 lh-12 fw-500">
                                    Children
                                  </div>
                                  <div className="text-14 lh-12 text-light-1 mt-5">
                                    Ages 0 - 17
                                  </div>
                                </div>

                                <div className="col-auto">
                                  <div className="d-flex items-center js-counter">
                                    <button
                                      className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                                      onClick={() =>
                                        handleDecrement(setChildren)
                                      }
                                    >
                                      <i className="icon-minus text-12"></i>
                                    </button>

                                    <div className="flex-center size-20 ml-15 mr-15">
                                      <div className="text-15">{children}</div>
                                    </div>

                                    <button
                                      className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                                      onClick={() =>
                                        handleIncrement(setChildren)
                                      }
                                    >
                                      <i className="icon-plus text-12"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="border-top-light mt-24 mb-24"></div>

                              <div className="row y-gap-10 justify-between items-center">
                                <div className="col-auto">
                                  <div className="text-15 fw-500">Rooms</div>
                                </div>

                                <div className="col-auto">
                                  <div className="d-flex items-center js-counter">
                                    <button
                                      className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                                      onClick={() => handleDecrement(setRooms)}
                                    >
                                      <i className="icon-minus text-12"></i>
                                    </button>

                                    <div className="flex-center size-20 ml-15 mr-15">
                                      <div className="text-15">{rooms}</div>
                                    </div>

                                    <button
                                      className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                                      onClick={() => handleIncrement(setRooms)}
                                    >
                                      <i className="icon-plus text-12"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="button-item">
                          <button
                            className="mainSearch__submit button -dark-1 h-60 px-35 col-12 rounded-100 bg-blue-1 text-white"
                            onClick={() => navigate("/booking-cruises")}
                          >
                            <i className="icon-search text-20 mr-10"></i>
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="layout-pt-md layout-pb-lg">
        <div className="container">
          <div className="row y-gap-30">
            <div
              className="col-xl-3 h-100"
              style={{ boxShadow: "0 0 2px #ccc" }}
            >
              <aside className="sidebar y-gap-40">
                <div className="sidebar__item -no-border">
                  <h5 className="text-18 fw-500 mb-10">Search... </h5>
                  <div className="single-field relative d-flex items-center py-10">
                    <input
                      className="pl-50 border-light text-dark-1 h-50 rounded-8"
                      type="email"
                      placeholder="e.g. Best Western"
                    />
                    <button className="absolute d-flex items-center h-full">
                      <i className="icon-search text-20 px-15 text-dark-1"></i>
                    </button>
                  </div>
                </div>

                <div className="sidebar__item">
                  <h5 className="text-18 fw-500 mb-10">Deals </h5>
                  <div className="sidebar-checkbox">
                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox">
                            <input type="checkbox" id="free-cancellation" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>
                          <label
                            className="text-15 ml-10"
                            htmlFor="free-cancellation"
                          >
                            Free cancellation{" "}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox">
                            <input type="checkbox" id="reserve-now" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>
                          <label
                            className="text-15 ml-10"
                            htmlFor="reserve-now"
                          >
                            Reserve now, pay at ____{" "}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox">
                            <input type="checkbox" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>
                          <div className="text-15 ml-10">
                            Properties with special offers{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sidebar__item">
                  <h5 className="text-18 fw-500 mb-10">Popular Filters </h5>
                  <div className="sidebar-checkbox">
                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox">
                            <input type="checkbox" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>
                          <div className="text-15 ml-10">
                            Breakfast Included{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">92 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox">
                            <input type="checkbox" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>
                          <div className="text-15 ml-10">Romantic </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">45 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox">
                            <input type="checkbox" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>
                          <div className="text-15 ml-10">Airport Transfer </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">21 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox">
                            <input type="checkbox" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>
                          <div className="text-15 ml-10">WiFi Included </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">78 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox">
                            <input type="checkbox" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>
                          <div className="text-15 ml-10">5 Star </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">679 </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sidebar__item pb-30">
                  <h5 className="text-18 fw-500 mb-10">Nightly Price </h5>
                  <div className="row x-gap-10 y-gap-30">
                    <div className="col-12">
                      <div className="js-price-rangeSlider">
                        <div className="text-14 fw-500"></div>

                        <div className="d-flex justify-between mb-20">
                          <div
                            className="text-15 text-dark-1"
                            style={{ color: "#103682" }}
                          >
                            {valuePrice.join("$ - ")}$
                          </div>
                        </div>

                        <div className="px-5">
                          <Box sx={{ width: "100%" }}>
                            <Slider
                              getAriaLabel={() => "Price range"}
                              value={valuePrice}
                              onChange={handlePriceChange}
                              valueLabelDisplay="auto"
                              getAriaValueText={valuetext}
                            />
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sidebar__item">
                  <h5 className="text-18 fw-500 mb-10">Amenities </h5>
                  <div className="sidebar-checkbox">
                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox ">
                            <input type="checkbox" name="name" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>

                          <div className="text-15 ml-10">
                            Breakfast Included{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">92 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox ">
                            <input type="checkbox" name="name" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>

                          <div className="text-15 ml-10">WiFi Included </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">45 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox ">
                            <input type="checkbox" name="name" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>

                          <div className="text-15 ml-10">Pool </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">21 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox ">
                            <input type="checkbox" name="name" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>

                          <div className="text-15 ml-10">Restaurant </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">78 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox ">
                            <input type="checkbox" name="name" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>

                          <div className="text-15 ml-10">Air conditioning </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">679 </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sidebar__item">
                  <h5 className="text-18 fw-500 mb-10">Star Rating </h5>
                  <div className="row x-gap-10 y-gap-10 pt-10">
                    <div className="col-auto">
                      <Box
                        sx={{
                          width: 200,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Rating
                          name="hover-feedback"
                          value={valueStar}
                          precision={1}
                          getLabelText={getLabelText}
                          onChange={(event, newValue) => {
                            setValueStar(newValue);
                          }}
                          onChangeActive={(event, newHover) => {
                            setHover(newHover);
                          }}
                          emptyIcon={
                            <StarIcon
                              style={{ opacity: 0.55 }}
                              fontSize="inherit"
                            />
                          }
                        />
                        {valueStar !== null && (
                          <Box sx={{ ml: 2 }}>
                            {labels[hover !== -1 ? hover : valueStar]}
                          </Box>
                        )}
                      </Box>
                    </div>
                  </div>
                </div>

                <div className="sidebar__item">
                  <h5 className="text-18 fw-500 mb-10">Guest Rating </h5>
                  <div className="sidebar-checkbox">
                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="form-radio d-flex items-center ">
                          <div className="radio">
                            <input type="radio" name="name" />
                            <div className="radio__mark">
                              <div className="radio__icon"></div>
                            </div>
                          </div>
                          <div className="ml-10">Any </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">92 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="form-radio d-flex items-center ">
                          <div className="radio">
                            <input type="radio" name="name" />
                            <div className="radio__mark">
                              <div className="radio__icon"></div>
                            </div>
                          </div>
                          <div className="ml-10">Wonderful 4.5+ </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">45 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="form-radio d-flex items-center ">
                          <div className="radio">
                            <input type="radio" name="name" />
                            <div className="radio__mark">
                              <div className="radio__icon"></div>
                            </div>
                          </div>
                          <div className="ml-10">Very good 4+ </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">21 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="form-radio d-flex items-center ">
                          <div className="radio">
                            <input type="radio" name="name" />
                            <div className="radio__mark">
                              <div className="radio__icon"></div>
                            </div>
                          </div>
                          <div className="ml-10">Good 3.5+ </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">78 </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sidebar__item">
                  <h5 className="text-18 fw-500 mb-10">Style </h5>
                  <div className="sidebar-checkbox">
                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox ">
                            <input type="checkbox" name="name" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>

                          <div className="text-15 ml-10">Budget </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">92 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox ">
                            <input type="checkbox" name="name" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>

                          <div className="text-15 ml-10">Mid-range </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">45 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox ">
                            <input type="checkbox" name="name" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>

                          <div className="text-15 ml-10">Luxury </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">21 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox ">
                            <input type="checkbox" name="name" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>

                          <div className="text-15 ml-10">Family-friendly </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">78 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox ">
                            <input type="checkbox" name="name" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>

                          <div className="text-15 ml-10">Business </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">679 </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sidebar__item">
                  <h5 className="text-18 fw-500 mb-10">Neighborhood </h5>
                  <div className="sidebar-checkbox">
                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox ">
                            <input type="checkbox" name="name" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>

                          <div className="text-15 ml-10">Central London </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">92 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox ">
                            <input type="checkbox" name="name" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>

                          <div className="text-15 ml-10">
                            Guests&#39; favourite area{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">45 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox ">
                            <input type="checkbox" name="name" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>

                          <div className="text-15 ml-10">
                            Westminster Borough{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">21 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox ">
                            <input type="checkbox" name="name" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>

                          <div className="text-15 ml-10">
                            Kensington and Chelsea{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">78 </div>
                      </div>
                    </div>

                    <div className="row y-gap-10 items-center justify-between">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="form-checkbox ">
                            <input type="checkbox" name="name" />
                            <div className="form-checkbox__mark">
                              <div className="form-checkbox__icon icon-check"></div>
                            </div>
                          </div>

                          <div className="text-15 ml-10">Oxford Street </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="text-15 text-light-1">679 </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sidebar__item  ">
                  <div
                    className="flex-center ratio ratio-4:1 js-lazy"
                    data-bg="titsolutions/img/general/map.png"
                  >
                    <button
                      data-x-click="mapFilter"
                      onClick={() => setIsActiveMapFilter(true)}
                      className="button py-15 px-24 -blue-1 bg-white text-dark-1 absolute"
                    >
                      <i className="icon-destination text-22 mr-10"></i>
                      Show on map
                    </button>
                  </div>
                </div>
              </aside>
            </div>

            <div className="col-xl-9">
              <div className="row y-gap-10 items-center justify-between">
                <div className="col-auto">
                  <div className="text-18">
                    <span className="fw-500">3,269 properties </span> in Europe{" "}
                  </div>
                </div>

                <div className="col-auto">
                  <div className="row x-gap-20 y-gap-20">
                    <div className="col-auto">
                      <button className="button -blue-1 h-40 px-20 rounded-100 bg-blue-1-05 text-15 text-blue-1">
                        <i className="icon-up-down text-14 mr-10"></i>
                        Top picks ___ your search
                      </button>
                    </div>

                    <div className="col-auto d-none lg:d-block">
                      <button
                        data-x-click="filterPopup"
                        className="button -blue-1 h-40 px-20 rounded-100 bg-blue-1-05 text-15 text-blue-1"
                      >
                        <i className="icon-up-down text-14 mr-10"></i>
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="filterPopup bg-white"
                data-x="filterPopup"
                data-x-toggle="-is-active"
              >
                <aside className="sidebar -mobile-filter">
                  <div data-x-click="filterPopup" className="-icon-close">
                    <i className="icon-close"></i>
                  </div>

                  <div className="sidebar__item">
                    <div
                      className="flex-center ratio ratio-15:9 js-lazy"
                      data-bg="titsolutions/img/general/map.png"
                    >
                      <button
                        className="button py-15 px-24 -blue-1 bg-white text-dark-1 absolute"
                        data-x-click="mapFilter"
                      >
                        <i className="icon-destination text-22 mr-10"></i>
                        Show on ___
                      </button>
                    </div>
                  </div>

                  <div className="sidebar__item">
                    <h5 className="text-18 fw-500 mb-10">
                      Search by property name{" "}
                    </h5>
                    <div className="single-field relative d-flex items-center py-10">
                      <input
                        className="pl-50 border-light text-dark-1 h-50 rounded-8"
                        type="email"
                        placeholder="e.g. Best Western"
                      />
                      <button className="absolute d-flex items-center h-full">
                        <i className="icon-search text-20 px-15 text-dark-1"></i>
                      </button>
                    </div>
                  </div>

                  <div className="sidebar__item">
                    <h5 className="text-18 fw-500 mb-10">Deals </h5>
                    <div className="sidebar-checkbox">
                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox">
                              <input type="checkbox" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>
                            <div className="text-15 ml-10">
                              Free cancellation{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox">
                              <input type="checkbox" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>
                            <div className="text-15 ml-10">
                              Reserve now, pay at ____{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox">
                              <input type="checkbox" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>
                            <div className="text-15 ml-10">
                              Properties with special offers{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sidebar__item">
                    <h5 className="text-18 fw-500 mb-10">Popular Filters </h5>
                    <div className="sidebar-checkbox">
                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox">
                              <input type="checkbox" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>
                            <div className="text-15 ml-10">
                              Breakfast Included{" "}
                            </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">92 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox">
                              <input type="checkbox" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>
                            <div className="text-15 ml-10">Romantic </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">45 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox">
                              <input type="checkbox" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>
                            <div className="text-15 ml-10">
                              Airport Transfer{" "}
                            </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">21 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox">
                              <input type="checkbox" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>
                            <div className="text-15 ml-10">WiFi Included </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">78 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox">
                              <input type="checkbox" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>
                            <div className="text-15 ml-10">5 Star </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">679 </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sidebar__item pb-30">
                    <h5 className="text-18 fw-500 mb-10">Nightly Price </h5>
                    <div className="row x-gap-10 y-gap-30">
                      <div className="col-12">
                        <div className="js-price-rangeSlider">
                          <div className="text-14 fw-500"></div>

                          <div className="d-flex justify-between mb-20">
                            <div
                              className="text-15 text-dark-1"
                              style={{ color: "#103682" }}
                            >
                              {valuePrice.join("$ - ")}$
                            </div>
                          </div>

                          <div className="px-5">
                            <Box sx={{ width: "100%" }}>
                              <Slider
                                getAriaLabel={() => "Price range"}
                                value={valuePrice}
                                onChange={handlePriceChange}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                              />
                            </Box>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sidebar__item">
                    <h5 className="text-18 fw-500 mb-10">Amenities </h5>
                    <div className="sidebar-checkbox">
                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>

                            <div className="text-15 ml-10">
                              Breakfast Included{" "}
                            </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">92 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>

                            <div className="text-15 ml-10">WiFi Included </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">45 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>

                            <div className="text-15 ml-10">Pool </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">21 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>

                            <div className="text-15 ml-10">Restaurant </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">78 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>

                            <div className="text-15 ml-10">
                              Air conditioning{" "}
                            </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">679 </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sidebar__item">
                    <h5 className="text-18 fw-500 mb-10">Star Rating </h5>
                    <div className="row y-gap-10 x-gap-10 pt-10">
                      <div className="col-auto">
                        <Link
                          to="#."
                          className="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100"
                        >
                          1{" "}
                        </Link>
                      </div>

                      <div className="col-auto">
                        <Link
                          to="#."
                          className="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100"
                        >
                          2{" "}
                        </Link>
                      </div>

                      <div className="col-auto">
                        <Link
                          to="#."
                          className="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100"
                        >
                          3{" "}
                        </Link>
                      </div>

                      <div className="col-auto">
                        <Link
                          to="#."
                          className="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100"
                        >
                          4{" "}
                        </Link>
                      </div>

                      <div className="col-auto">
                        <Link
                          to="#."
                          className="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100"
                        >
                          5{" "}
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="sidebar__item">
                    <h5 className="text-18 fw-500 mb-10">Guest Rating </h5>
                    <div className="sidebar-checkbox">
                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="form-radio d-flex items-center ">
                            <div className="radio">
                              <input type="radio" name="name" />
                              <div className="radio__mark">
                                <div className="radio__icon"></div>
                              </div>
                            </div>
                            <div className="ml-10">Any </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">92 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="form-radio d-flex items-center ">
                            <div className="radio">
                              <input type="radio" name="name" />
                              <div className="radio__mark">
                                <div className="radio__icon"></div>
                              </div>
                            </div>
                            <div className="ml-10">Wonderful 4.5+ </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">45 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="form-radio d-flex items-center ">
                            <div className="radio">
                              <input type="radio" name="name" />
                              <div className="radio__mark">
                                <div className="radio__icon"></div>
                              </div>
                            </div>
                            <div className="ml-10">Very good 4+ </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">21 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="form-radio d-flex items-center ">
                            <div className="radio">
                              <input type="radio" name="name" />
                              <div className="radio__mark">
                                <div className="radio__icon"></div>
                              </div>
                            </div>
                            <div className="ml-10">Good 3.5+ </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">78 </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sidebar__item">
                    <h5 className="text-18 fw-500 mb-10">Style </h5>
                    <div className="sidebar-checkbox">
                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>

                            <div className="text-15 ml-10">Budget </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">92 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>

                            <div className="text-15 ml-10">Mid-range </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">45 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>

                            <div className="text-15 ml-10">Luxury </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">21 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>

                            <div className="text-15 ml-10">
                              Family-friendly{" "}
                            </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">78 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>

                            <div className="text-15 ml-10">Business </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">679 </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sidebar__item">
                    <h5 className="text-18 fw-500 mb-10">Neighborhood </h5>
                    <div className="sidebar-checkbox">
                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>

                            <div className="text-15 ml-10">Central London </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">92 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>

                            <div className="text-15 ml-10">
                              Guests&#39; favourite area{" "}
                            </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">45 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>

                            <div className="text-15 ml-10">
                              Westminster Borough{" "}
                            </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">21 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>

                            <div className="text-15 ml-10">
                              Kensington and Chelsea{" "}
                            </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">78 </div>
                        </div>
                      </div>

                      <div className="row items-center justify-between">
                        <div className="col-auto">
                          <div className="d-flex items-center">
                            <div className="form-checkbox ">
                              <input type="checkbox" name="name" />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                            </div>

                            <div className="text-15 ml-10">Oxford Street </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="text-15 text-light-1">679 </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>

              <div className="mt-30"></div>

              <div className="row y-gap-30">
                <div className="col-12">
                  <div className="border-top-light pt-30">
                    <div className="row x-gap-20 y-gap-20">
                      <div className="col-md-auto cruiseCard">
                        <div className="cardImage ratio ratio-1:1 w-250 md:w-1/1 rounded-4">
                          <div className="cardImage__content">
                            <Swiper
                              modules={[Pagination, Navigation, Autoplay]}
                              autoplay={{
                                delay: 3000,
                                loop: false,
                                delayTimeout: 1000,
                                disableOnInteraction: false, // Continue autoplay after user interactions
                              }}
                              spaceBetween={50}
                              slidesPerView={1}
                              navigation={{
                                prevEl: ".js-prev", // Custom previous button class
                                nextEl: ".js-next", // Custom next button class
                              }} // Enable custom navigation
                              pagination={{
                                clickable: true,
                                el: ".js-pagination", // Target custom pagination class
                                renderBullet: (index, className) => {
                                  return `<div className="pagination__item ${className}" tabindex="0" role="button" aria-label="Go to slide ${
                                    index + 1
                                  }"></div>`;
                                },
                              }}
                            >
                              <SwiperSlide>
                                <img
                                  className="col-12"
                                  src="titsolutions/img/lists/hotel/1/1.png"
                                  alt="image"
                                />
                              </SwiperSlide>
                              <SwiperSlide>
                                <img
                                  className="col-12"
                                  src="titsolutions/img/lists/hotel/1/1.png"
                                  alt="image"
                                />
                              </SwiperSlide>
                              <SwiperSlide>
                                <img
                                  className="col-12"
                                  src="titsolutions/img/lists/hotel/1/1.png"
                                  alt="image"
                                />
                              </SwiperSlide>
                            </Swiper>
                            {/* Custom Pagination Element */}
                            <div className="cardImage-slider__pagination js-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"></div>

                            {/* Custom Navigation Buttons */}
                            <div className="cardImage-slider__nav -prev">
                              <button
                                className="button -blue-1 bg-white size-30 rounded-full shadow-2 js-prev"
                                tabindex="0"
                                aria-label="Previous slide"
                              >
                                <i className="icon-chevron-left text-10"></i>
                              </button>
                            </div>
                            <div className="cardImage-slider__nav -next">
                              <button
                                className="button -blue-1 bg-white size-30 rounded-full shadow-2 js-next"
                                tabindex="0"
                                aria-label="Next slide"
                              >
                                <i className="icon-chevron-right text-10"></i>
                              </button>
                            </div>
                          </div>

                          <div className="cardImage__wishlist">
                            <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                              <i className="icon-heart text-12"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-md">
                        <h3 className="text-18 lh-16 fw-500">
                          _____ Northern Hotel, a Tribute _________{" "}
                          <br className="lg:d-none" /> Hotel, London
                          <div className="d-inline-block ml-10">
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                          </div>
                        </h3>

                        <div className="row x-gap-10 y-gap-10 items-center pt-10">
                          <div className="col-auto">
                            <p className="text-14">
                              Westminster Borough, London{" "}
                            </p>
                          </div>

                          <div className="col-auto">
                            <button
                              onClick={() => setIsActiveMapFilter(true)}
                              className="d-block text-14 text-blue-1 underline"
                            >
                              Show on map{" "}
                            </button>
                          </div>

                          <div className="col-auto">
                            <div className="size-3 rounded-full bg-light-1"></div>
                          </div>

                          <div className="col-auto">
                            <p className="text-14">2 km to city ______ </p>
                          </div>
                        </div>

                        <div className="text-14 lh-15 mt-20">
                          <div className="fw-500">King Room </div>
                          <div className="text-light-1">
                            1 extra-large double bed{" "}
                          </div>
                        </div>

                        <div className="text-14 text-green-2 lh-15 mt-10">
                          <div className="fw-500">Free cancellation </div>
                          <div className="">
                            You can cancel later, __ lock in this great _____
                            today.{" "}
                          </div>
                        </div>

                        <div className="row x-gap-10 y-gap-10 pt-20">
                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Breakfast{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              WiFi{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Spa{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Bar{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-auto text-right md:text-left">
                        <div className="row x-gap-10 y-gap-10 justify-end items-center md:justify-start">
                          <div className="col-auto">
                            <div className="text-14 lh-14 fw-500">
                              Exceptional{" "}
                            </div>
                            <div className="text-14 lh-14 text-light-1">
                              3,014 reviews{" "}
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="flex-center text-white fw-600 text-14 size-40 rounded-4 bg-blue-1">
                              4.8{" "}
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <div className="text-14 text-light-1 mt-50 md:mt-20">
                            8 nights, 2 adult{" "}
                          </div>
                          <div className="text-22 lh-12 fw-600 mt-5">
                            US$72{" "}
                          </div>
                          <div className="text-14 text-light-1 mt-5">
                            +US$828 taxes and charges{" "}
                          </div>

                          <Link
                            to="/cruises-details"
                            className="button -md -dark-1 bg-blue-1 text-white mt-24"
                          >
                            See Availability{" "}
                            <div className="icon-arrow-top-right ml-15"></div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="border-top-light pt-30">
                    <div className="row x-gap-20 y-gap-20">
                      <div className="col-md-auto">
                        <div className="cardImage ratio ratio-1:1 w-250 md:w-1/1 rounded-4">
                          <div className="cardImage__content">
                            <div className="cardImage-slider rounded-4 overflow-hidden js-cardImage-slider">
                              <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                  <img
                                    className="col-12"
                                    src="/titsolutions/img/lists/hotel/1/2.png"
                                    alt="image"
                                  />
                                </div>

                                <div className="swiper-slide">
                                  <img
                                    className="col-12"
                                    src="/titsolutions/img/lists/hotel/1/1.png"
                                    alt="image"
                                  />
                                </div>

                                <div className="swiper-slide">
                                  <img
                                    className="col-12"
                                    src="/titsolutions/img/lists/hotel/1/3.png"
                                    alt="image"
                                  />
                                </div>
                              </div>

                              <div className="cardImage-slider__pagination js-pagination"></div>

                              <div className="cardImage-slider__nav -prev">
                                <button className="button -blue-1 bg-white size-30 rounded-full shadow-2 js-prev">
                                  <i className="icon-chevron-left text-10"></i>
                                </button>
                              </div>

                              <div className="cardImage-slider__nav -next">
                                <button className="button -blue-1 bg-white size-30 rounded-full shadow-2 js-next">
                                  <i className="icon-chevron-right text-10"></i>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="cardImage__wishlist">
                            <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                              <i className="icon-heart text-12"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-md">
                        <h3 className="text-18 lh-16 fw-500">
                          _____ Northern Hotel, a Tribute _________{" "}
                          <br className="lg:d-none" /> Hotel, London
                          <div className="d-inline-block ml-10">
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                          </div>
                        </h3>

                        <div className="row x-gap-10 y-gap-10 items-center pt-10">
                          <div className="col-auto">
                            <p className="text-14">
                              Westminster Borough, London{" "}
                            </p>
                          </div>

                          <div className="col-auto">
                            <button
                              onClick={() => setIsActiveMapFilter(true)}
                              className="d-block text-14 text-blue-1 underline"
                            >
                              Show on map{" "}
                            </button>
                          </div>

                          <div className="col-auto">
                            <div className="size-3 rounded-full bg-light-1"></div>
                          </div>

                          <div className="col-auto">
                            <p className="text-14">2 km to city ______ </p>
                          </div>
                        </div>

                        <div className="text-14 lh-15 mt-20">
                          <div className="fw-500">King Room </div>
                          <div className="text-light-1">
                            1 extra-large double bed{" "}
                          </div>
                        </div>

                        <div className="text-14 text-green-2 lh-15 mt-10">
                          <div className="fw-500">Free cancellation </div>
                          <div className="">
                            You can cancel later, __ lock in this great _____
                            today.{" "}
                          </div>
                        </div>

                        <div className="row x-gap-10 y-gap-10 pt-20">
                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Breakfast{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              WiFi{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Spa{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Bar{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-auto text-right md:text-left">
                        <div className="row x-gap-10 y-gap-10 justify-end items-center md:justify-start">
                          <div className="col-auto">
                            <div className="text-14 lh-14 fw-500">
                              Exceptional{" "}
                            </div>
                            <div className="text-14 lh-14 text-light-1">
                              3,014 reviews{" "}
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="flex-center text-white fw-600 text-14 size-40 rounded-4 bg-blue-1">
                              4.8{" "}
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <div className="text-14 text-light-1 mt-50 md:mt-20">
                            8 nights, 2 adult{" "}
                          </div>
                          <div className="text-22 lh-12 fw-600 mt-5">
                            US$72{" "}
                          </div>
                          <div className="text-14 text-light-1 mt-5">
                            +US$828 taxes and charges{" "}
                          </div>

                          <Link
                            to="/cruises-details"
                            className="button -md -dark-1 bg-blue-1 text-white mt-24"
                          >
                            See Availability{" "}
                            <div className="icon-arrow-top-right ml-15"></div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="border-top-light pt-30">
                    <div className="row x-gap-20 y-gap-20">
                      <div className="col-md-auto">
                        <div className="cardImage ratio ratio-1:1 w-250 md:w-1/1 rounded-4">
                          <div className="cardImage__content">
                            <img
                              className="rounded-4 col-12"
                              src="/titsolutions/img/lists/hotel/1/3.png"
                              alt="image"
                            />
                          </div>

                          <div className="cardImage__wishlist">
                            <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                              <i className="icon-heart text-12"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-md">
                        <h3 className="text-18 lh-16 fw-500">
                          _____ Northern Hotel, a Tribute _________{" "}
                          <br className="lg:d-none" /> Hotel, London
                          <div className="d-inline-block ml-10">
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                          </div>
                        </h3>

                        <div className="row x-gap-10 y-gap-10 items-center pt-10">
                          <div className="col-auto">
                            <p className="text-14">
                              Westminster Borough, London{" "}
                            </p>
                          </div>

                          <div className="col-auto">
                            <button
                              onClick={() => setIsActiveMapFilter(true)}
                              className="d-block text-14 text-blue-1 underline"
                            >
                              Show on map{" "}
                            </button>
                          </div>

                          <div className="col-auto">
                            <div className="size-3 rounded-full bg-light-1"></div>
                          </div>

                          <div className="col-auto">
                            <p className="text-14">2 km to city ______ </p>
                          </div>
                        </div>

                        <div className="text-14 lh-15 mt-20">
                          <div className="fw-500">King Room </div>
                          <div className="text-light-1">
                            1 extra-large double bed{" "}
                          </div>
                        </div>

                        <div className="text-14 text-green-2 lh-15 mt-10">
                          <div className="fw-500">Free cancellation </div>
                          <div className="">
                            You can cancel later, __ lock in this great _____
                            today.{" "}
                          </div>
                        </div>

                        <div className="row x-gap-10 y-gap-10 pt-20">
                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Breakfast{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              WiFi{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Spa{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Bar{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-auto text-right md:text-left">
                        <div className="row x-gap-10 y-gap-10 justify-end items-center md:justify-start">
                          <div className="col-auto">
                            <div className="text-14 lh-14 fw-500">
                              Exceptional{" "}
                            </div>
                            <div className="text-14 lh-14 text-light-1">
                              3,014 reviews{" "}
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="flex-center text-white fw-600 text-14 size-40 rounded-4 bg-blue-1">
                              4.8{" "}
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <div className="text-14 text-light-1 mt-50 md:mt-20">
                            8 nights, 2 adult{" "}
                          </div>
                          <div className="text-22 lh-12 fw-600 mt-5">
                            US$72{" "}
                          </div>
                          <div className="text-14 text-light-1 mt-5">
                            +US$828 taxes and charges{" "}
                          </div>

                          <Link
                            to="/cruises-details"
                            className="button -md -dark-1 bg-blue-1 text-white mt-24"
                          >
                            See Availability{" "}
                            <div className="icon-arrow-top-right ml-15"></div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="border-top-light pt-30">
                    <div className="row x-gap-20 y-gap-20">
                      <div className="col-md-auto">
                        <div className="cardImage ratio ratio-1:1 w-250 md:w-1/1 rounded-4">
                          <div className="cardImage__content">
                            <img
                              className="rounded-4 col-12"
                              src="/titsolutions/img/lists/hotel/1/4.png"
                              alt="image"
                            />
                          </div>

                          <div className="cardImage__wishlist">
                            <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                              <i className="icon-heart text-12"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-md">
                        <h3 className="text-18 lh-16 fw-500">
                          _____ Northern Hotel, a Tribute _________{" "}
                          <br className="lg:d-none" /> Hotel, London
                          <div className="d-inline-block ml-10">
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                          </div>
                        </h3>

                        <div className="row x-gap-10 y-gap-10 items-center pt-10">
                          <div className="col-auto">
                            <p className="text-14">
                              Westminster Borough, London{" "}
                            </p>
                          </div>

                          <div className="col-auto">
                            <button
                              onClick={() => setIsActiveMapFilter(true)}
                              className="d-block text-14 text-blue-1 underline"
                            >
                              Show on map{" "}
                            </button>
                          </div>

                          <div className="col-auto">
                            <div className="size-3 rounded-full bg-light-1"></div>
                          </div>

                          <div className="col-auto">
                            <p className="text-14">2 km to city ______ </p>
                          </div>
                        </div>

                        <div className="text-14 lh-15 mt-20">
                          <div className="fw-500">King Room </div>
                          <div className="text-light-1">
                            1 extra-large double bed{" "}
                          </div>
                        </div>

                        <div className="text-14 text-green-2 lh-15 mt-10">
                          <div className="fw-500">Free cancellation </div>
                          <div className="">
                            You can cancel later, __ lock in this great _____
                            today.{" "}
                          </div>
                        </div>

                        <div className="row x-gap-10 y-gap-10 pt-20">
                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Breakfast{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              WiFi{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Spa{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Bar{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-auto text-right md:text-left">
                        <div className="row x-gap-10 y-gap-10 justify-end items-center md:justify-start">
                          <div className="col-auto">
                            <div className="text-14 lh-14 fw-500">
                              Exceptional{" "}
                            </div>
                            <div className="text-14 lh-14 text-light-1">
                              3,014 reviews{" "}
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="flex-center text-white fw-600 text-14 size-40 rounded-4 bg-blue-1">
                              4.8{" "}
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <div className="text-14 text-light-1 mt-50 md:mt-20">
                            8 nights, 2 adult{" "}
                          </div>
                          <div className="text-22 lh-12 fw-600 mt-5">
                            US$72{" "}
                          </div>
                          <div className="text-14 text-light-1 mt-5">
                            +US$828 taxes and charges{" "}
                          </div>

                          <Link
                            to="/cruises-details"
                            className="button -md -dark-1 bg-blue-1 text-white mt-24"
                          >
                            See Availability{" "}
                            <div className="icon-arrow-top-right ml-15"></div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="border-top-light pt-30">
                    <div className="row x-gap-20 y-gap-20">
                      <div className="col-md-auto">
                        <div className="cardImage ratio ratio-1:1 w-250 md:w-1/1 rounded-4">
                          <div className="cardImage__content">
                            <img
                              className="rounded-4 col-12"
                              src="/titsolutions/img/lists/hotel/1/5.png"
                              alt="image"
                            />
                          </div>

                          <div className="cardImage__wishlist">
                            <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                              <i className="icon-heart text-12"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-md">
                        <h3 className="text-18 lh-16 fw-500">
                          _____ Northern Hotel, a Tribute _________{" "}
                          <br className="lg:d-none" /> Hotel, London
                          <div className="d-inline-block ml-10">
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                          </div>
                        </h3>

                        <div className="row x-gap-10 y-gap-10 items-center pt-10">
                          <div className="col-auto">
                            <p className="text-14">
                              Westminster Borough, London{" "}
                            </p>
                          </div>

                          <div className="col-auto">
                            <button
                              onClick={() => setIsActiveMapFilter(true)}
                              className="d-block text-14 text-blue-1 underline"
                            >
                              Show on map{" "}
                            </button>
                          </div>

                          <div className="col-auto">
                            <div className="size-3 rounded-full bg-light-1"></div>
                          </div>

                          <div className="col-auto">
                            <p className="text-14">2 km to city ______ </p>
                          </div>
                        </div>

                        <div className="text-14 lh-15 mt-20">
                          <div className="fw-500">King Room </div>
                          <div className="text-light-1">
                            1 extra-large double bed{" "}
                          </div>
                        </div>

                        <div className="text-14 text-green-2 lh-15 mt-10">
                          <div className="fw-500">Free cancellation </div>
                          <div className="">
                            You can cancel later, __ lock in this great _____
                            today.{" "}
                          </div>
                        </div>

                        <div className="row x-gap-10 y-gap-10 pt-20">
                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Breakfast{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              WiFi{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Spa{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Bar{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-auto text-right md:text-left">
                        <div className="row x-gap-10 y-gap-10 justify-end items-center md:justify-start">
                          <div className="col-auto">
                            <div className="text-14 lh-14 fw-500">
                              Exceptional{" "}
                            </div>
                            <div className="text-14 lh-14 text-light-1">
                              3,014 reviews{" "}
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="flex-center text-white fw-600 text-14 size-40 rounded-4 bg-blue-1">
                              4.8{" "}
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <div className="text-14 text-light-1 mt-50 md:mt-20">
                            8 nights, 2 adult{" "}
                          </div>
                          <div className="text-22 lh-12 fw-600 mt-5">
                            US$72{" "}
                          </div>
                          <div className="text-14 text-light-1 mt-5">
                            +US$828 taxes and charges{" "}
                          </div>

                          <Link
                            to="/cruises-details"
                            className="button -md -dark-1 bg-blue-1 text-white mt-24"
                          >
                            See Availability{" "}
                            <div className="icon-arrow-top-right ml-15"></div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="border-top-light pt-30">
                    <div className="row x-gap-20 y-gap-20">
                      <div className="col-md-auto">
                        <div className="cardImage ratio ratio-1:1 w-250 md:w-1/1 rounded-4">
                          <div className="cardImage__content">
                            <img
                              className="rounded-4 col-12"
                              src="/titsolutions/img/lists/hotel/1/6.png"
                              alt="image"
                            />
                          </div>

                          <div className="cardImage__wishlist">
                            <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                              <i className="icon-heart text-12"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-md">
                        <h3 className="text-18 lh-16 fw-500">
                          _____ Northern Hotel, a Tribute _________{" "}
                          <br className="lg:d-none" /> Hotel, London
                          <div className="d-inline-block ml-10">
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                          </div>
                        </h3>

                        <div className="row x-gap-10 y-gap-10 items-center pt-10">
                          <div className="col-auto">
                            <p className="text-14">
                              Westminster Borough, London{" "}
                            </p>
                          </div>

                          <div className="col-auto">
                            <button
                              onClick={() => setIsActiveMapFilter(true)}
                              className="d-block text-14 text-blue-1 underline"
                            >
                              Show on map{" "}
                            </button>
                          </div>

                          <div className="col-auto">
                            <div className="size-3 rounded-full bg-light-1"></div>
                          </div>

                          <div className="col-auto">
                            <p className="text-14">2 km to city ______ </p>
                          </div>
                        </div>

                        <div className="text-14 lh-15 mt-20">
                          <div className="fw-500">King Room </div>
                          <div className="text-light-1">
                            1 extra-large double bed{" "}
                          </div>
                        </div>

                        <div className="text-14 text-green-2 lh-15 mt-10">
                          <div className="fw-500">Free cancellation </div>
                          <div className="">
                            You can cancel later, __ lock in this great _____
                            today.{" "}
                          </div>
                        </div>

                        <div className="row x-gap-10 y-gap-10 pt-20">
                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Breakfast{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              WiFi{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Spa{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Bar{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-auto text-right md:text-left">
                        <div className="row x-gap-10 y-gap-10 justify-end items-center md:justify-start">
                          <div className="col-auto">
                            <div className="text-14 lh-14 fw-500">
                              Exceptional{" "}
                            </div>
                            <div className="text-14 lh-14 text-light-1">
                              3,014 reviews{" "}
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="flex-center text-white fw-600 text-14 size-40 rounded-4 bg-blue-1">
                              4.8{" "}
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <div className="text-14 text-light-1 mt-50 md:mt-20">
                            8 nights, 2 adult{" "}
                          </div>
                          <div className="text-22 lh-12 fw-600 mt-5">
                            US$72{" "}
                          </div>
                          <div className="text-14 text-light-1 mt-5">
                            +US$828 taxes and charges{" "}
                          </div>

                          <Link
                            to="/cruises-details"
                            className="button -md -dark-1 bg-blue-1 text-white mt-24"
                          >
                            See Availability{" "}
                            <div className="icon-arrow-top-right ml-15"></div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="border-top-light pt-30">
                    <div className="row x-gap-20 y-gap-20">
                      <div className="col-md-auto">
                        <div className="cardImage ratio ratio-1:1 w-250 md:w-1/1 rounded-4">
                          <div className="cardImage__content">
                            <img
                              className="rounded-4 col-12"
                              src="/titsolutions/img/lists/hotel/1/7.png"
                              alt="image"
                            />
                          </div>

                          <div className="cardImage__wishlist">
                            <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                              <i className="icon-heart text-12"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-md">
                        <h3 className="text-18 lh-16 fw-500">
                          _____ Northern Hotel, a Tribute _________{" "}
                          <br className="lg:d-none" /> Hotel, London
                          <div className="d-inline-block ml-10">
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                          </div>
                        </h3>

                        <div className="row x-gap-10 y-gap-10 items-center pt-10">
                          <div className="col-auto">
                            <p className="text-14">
                              Westminster Borough, London{" "}
                            </p>
                          </div>

                          <div className="col-auto">
                            <button
                              onClick={() => setIsActiveMapFilter(true)}
                              className="d-block text-14 text-blue-1 underline"
                            >
                              Show on map{" "}
                            </button>
                          </div>

                          <div className="col-auto">
                            <div className="size-3 rounded-full bg-light-1"></div>
                          </div>

                          <div className="col-auto">
                            <p className="text-14">2 km to city ______ </p>
                          </div>
                        </div>

                        <div className="text-14 lh-15 mt-20">
                          <div className="fw-500">King Room </div>
                          <div className="text-light-1">
                            1 extra-large double bed{" "}
                          </div>
                        </div>

                        <div className="text-14 text-green-2 lh-15 mt-10">
                          <div className="fw-500">Free cancellation </div>
                          <div className="">
                            You can cancel later, __ lock in this great _____
                            today.{" "}
                          </div>
                        </div>

                        <div className="row x-gap-10 y-gap-10 pt-20">
                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Breakfast{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              WiFi{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Spa{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Bar{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-auto text-right md:text-left">
                        <div className="row x-gap-10 y-gap-10 justify-end items-center md:justify-start">
                          <div className="col-auto">
                            <div className="text-14 lh-14 fw-500">
                              Exceptional{" "}
                            </div>
                            <div className="text-14 lh-14 text-light-1">
                              3,014 reviews{" "}
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="flex-center text-white fw-600 text-14 size-40 rounded-4 bg-blue-1">
                              4.8{" "}
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <div className="text-14 text-light-1 mt-50 md:mt-20">
                            8 nights, 2 adult{" "}
                          </div>
                          <div className="text-22 lh-12 fw-600 mt-5">
                            US$72{" "}
                          </div>
                          <div className="text-14 text-light-1 mt-5">
                            +US$828 taxes and charges{" "}
                          </div>

                          <Link
                            to="/cruises-details"
                            className="button -md -dark-1 bg-blue-1 text-white mt-24"
                          >
                            See Availability{" "}
                            <div className="icon-arrow-top-right ml-15"></div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="border-top-light pt-30">
                    <div className="row x-gap-20 y-gap-20">
                      <div className="col-md-auto">
                        <div className="cardImage ratio ratio-1:1 w-250 md:w-1/1 rounded-4">
                          <div className="cardImage__content">
                            <img
                              className="rounded-4 col-12"
                              src="/titsolutions/img/lists/hotel/1/8.png"
                              alt="image"
                            />
                          </div>

                          <div className="cardImage__wishlist">
                            <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                              <i className="icon-heart text-12"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-md">
                        <h3 className="text-18 lh-16 fw-500">
                          _____ Northern Hotel, a Tribute _________{" "}
                          <br className="lg:d-none" /> Hotel, London
                          <div className="d-inline-block ml-10">
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                            <i className="icon-star text-10 text-yellow-2"></i>
                          </div>
                        </h3>

                        <div className="row x-gap-10 y-gap-10 items-center pt-10">
                          <div className="col-auto">
                            <p className="text-14">
                              Westminster Borough, London{" "}
                            </p>
                          </div>

                          <div className="col-auto">
                            <button
                              onClick={() => setIsActiveMapFilter(true)}
                              className="d-block text-14 text-blue-1 underline"
                            >
                              Show on map{" "}
                            </button>
                          </div>

                          <div className="col-auto">
                            <div className="size-3 rounded-full bg-light-1"></div>
                          </div>

                          <div className="col-auto">
                            <p className="text-14">2 km to city ______ </p>
                          </div>
                        </div>

                        <div className="text-14 lh-15 mt-20">
                          <div className="fw-500">King Room </div>
                          <div className="text-light-1">
                            1 extra-large double bed{" "}
                          </div>
                        </div>

                        <div className="text-14 text-green-2 lh-15 mt-10">
                          <div className="fw-500">Free cancellation </div>
                          <div className="">
                            You can cancel later, __ lock in this great _____
                            today.{" "}
                          </div>
                        </div>

                        <div className="row x-gap-10 y-gap-10 pt-20">
                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Breakfast{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              WiFi{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Spa{" "}
                            </div>
                          </div>

                          <div className="col-auto">
                            <div className="border-light rounded-100 py-5 px-20 text-14 lh-14">
                              Bar{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-auto text-right md:text-left">
                        <div className="row x-gap-10 y-gap-10 justify-end items-center md:justify-start">
                          <div className="col-auto">
                            <div className="text-14 lh-14 fw-500">
                              Exceptional{" "}
                            </div>
                            <div className="text-14 lh-14 text-light-1">
                              3,014 reviews{" "}
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="flex-center text-white fw-600 text-14 size-40 rounded-4 bg-blue-1">
                              4.8{" "}
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <div className="text-14 text-light-1 mt-50 md:mt-20">
                            8 nights, 2 adult{" "}
                          </div>
                          <div className="text-22 lh-12 fw-600 mt-5">
                            US$72{" "}
                          </div>
                          <div className="text-14 text-light-1 mt-5">
                            +US$828 taxes and charges{" "}
                          </div>

                          <Link
                            to="/cruises-details"
                            className="button -md -dark-1 bg-blue-1 text-white mt-24"
                          >
                            See Availability{" "}
                            <div className="icon-arrow-top-right ml-15"></div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-top-light mt-30 pt-30">
                <div className="row x-gap-10 y-gap-20 justify-between md:justify-center">
                  <div className="col-auto md:order-1">
                    <button className="button -blue-1 size-40 rounded-full border-light">
                      <i className="icon-chevron-left text-12"></i>
                    </button>
                  </div>

                  <div className="col-md-auto md:order-3">
                    <div className="row x-gap-20 y-gap-20 items-center md:d-none">
                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full">
                          1{" "}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full bg-dark-1 text-white">
                          2{" "}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full">
                          3{" "}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full bg-light-2">
                          4{" "}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full">
                          5{" "}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full">
                          ...{" "}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full">
                          20{" "}
                        </div>
                      </div>
                    </div>

                    <div className="row x-gap-10 y-gap-20 justify-center items-center d-none md:d-flex">
                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full">
                          1{" "}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full bg-dark-1 text-white">
                          2{" "}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="size-40 flex-center rounded-full">
                          3{" "}
                        </div>
                      </div>
                    </div>

                    <div className="text-center mt-30 md:mt-10">
                      <div className="text-14 text-light-1">
                        1 – 20 of 300+ properties found{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-auto md:order-2">
                    <button className="button -blue-1 size-40 rounded-full border-light">
                      <i className="icon-chevron-right text-12"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* filter  */}
      <div className={`mapFilter ${isActiveMapFilter && "-is-active"}`}>
        <div
          className="mapFilter__overlay"
          onClick={() => setIsActiveMapFilter(false)}
        ></div>

        <div className="mapFilter__close">
          <button
            className="button -blue-1 size-40 bg-white shadow-2"
            onClick={() => setIsActiveMapFilter(false)}
          >
            <i className="icon-close text-15"></i>
          </button>
        </div>

        <div
          className="mapFilter__grid"
          data-x="mapFilter__grid"
          data-x-toggle="-filters-hidden"
        >
          <div className="mapFilter-filter scroll-bar-1">
            <div className="px-20 py-20 md:px-15 md:py-15">
              <div className="d-flex items-center justify-between">
                <div className="text-18 fw-500">Filters </div>

                <button
                  className="size-40 flex-center rounded-full bg-blue-1"
                  data-x-click="mapFilter__grid"
                >
                  <i className="icon-chevron-left text-12 text-white"></i>
                </button>
              </div>

              <div className="mapFilter-filter__item">
                <h5 className="text-18 fw-500 mb-10">Popular Filters </h5>
                <div className="sidebar-checkbox">
                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox">
                          <input type="checkbox" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>
                        <div className="text-15 ml-10">Breakfast Included </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">92 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox">
                          <input type="checkbox" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>
                        <div className="text-15 ml-10">Romantic </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">45 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox">
                          <input type="checkbox" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>
                        <div className="text-15 ml-10">Airport Transfer </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">21 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox">
                          <input type="checkbox" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>
                        <div className="text-15 ml-10">WiFi Included </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">78 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox">
                          <input type="checkbox" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>
                        <div className="text-15 ml-10">5 Star </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">679 </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mapFilter-filter__item">
                <h5 className="text-18 fw-500 mb-10">Nightly Price </h5>
                <div className="row x-gap-10 y-gap-30">
                  <div className="col-12">
                    <div className="js-price-rangeSlider">
                      <div className="text-14 fw-500"></div>

                      <div className="d-flex justify-between mb-20">
                        <div
                          className="text-15 text-dark-1"
                          style={{ color: "#103682" }}
                        >
                          {valuePrice.join("$ - ")}$
                        </div>
                      </div>

                      <div className="px-5">
                        <Box sx={{ width: "100%" }}>
                          <Slider
                            getAriaLabel={() => "Price range"}
                            value={valuePrice}
                            onChange={handlePriceChange}
                            valueLabelDisplay="auto"
                            getAriaValueText={valuetext}
                          />
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mapFilter-filter__item">
                <h5 className="text-18 fw-500 mb-10">Amenities </h5>
                <div className="sidebar-checkbox">
                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox ">
                          <input type="checkbox" name="name" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>

                        <div className="text-15 ml-10">Breakfast Included </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">92 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox ">
                          <input type="checkbox" name="name" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>

                        <div className="text-15 ml-10">WiFi Included </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">45 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox ">
                          <input type="checkbox" name="name" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>

                        <div className="text-15 ml-10">Pool </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">21 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox ">
                          <input type="checkbox" name="name" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>

                        <div className="text-15 ml-10">Restaurant </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">78 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox ">
                          <input type="checkbox" name="name" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>

                        <div className="text-15 ml-10">Air conditioning </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">679 </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mapFilter-filter__item">
                <h5 className="text-18 fw-500 mb-10">Star Rating </h5>
                <div className="row x-gap-10 y-gap-10 pt-10">
                  <div className="col-auto">
                    <Link
                      href="#"
                      className="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100"
                    >
                      1{" "}
                    </Link>
                  </div>

                  <div className="col-auto">
                    <Link
                      href="#"
                      className="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100"
                    >
                      2{" "}
                    </Link>
                  </div>

                  <div className="col-auto">
                    <Link
                      href="#"
                      className="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100"
                    >
                      3{" "}
                    </Link>
                  </div>

                  <div className="col-auto">
                    <Link
                      href="#"
                      className="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100"
                    >
                      4{" "}
                    </Link>
                  </div>

                  <div className="col-auto">
                    <Link
                      href="#"
                      className="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100"
                    >
                      5{" "}
                    </Link>
                  </div>
                </div>
              </div>

              <div className="mapFilter-filter__item">
                <h5 className="text-18 fw-500 mb-10">Guest Rating </h5>
                <div className="sidebar-checkbox">
                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="form-radio d-flex items-center ">
                        <div className="radio">
                          <input type="radio" name="name" />
                          <div className="radio__mark">
                            <div className="radio__icon"></div>
                          </div>
                        </div>
                        <div className="ml-10">Any </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">92 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="form-radio d-flex items-center ">
                        <div className="radio">
                          <input type="radio" name="name" />
                          <div className="radio__mark">
                            <div className="radio__icon"></div>
                          </div>
                        </div>
                        <div className="ml-10">Wonderful 4.5+ </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">45 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="form-radio d-flex items-center ">
                        <div className="radio">
                          <input type="radio" name="name" />
                          <div className="radio__mark">
                            <div className="radio__icon"></div>
                          </div>
                        </div>
                        <div className="ml-10">Very good 4+ </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">21 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="form-radio d-flex items-center ">
                        <div className="radio">
                          <input type="radio" name="name" />
                          <div className="radio__mark">
                            <div className="radio__icon"></div>
                          </div>
                        </div>
                        <div className="ml-10">Good 3.5+ </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">78 </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mapFilter-filter__item">
                <h5 className="text-18 fw-500 mb-10">Style </h5>
                <div className="sidebar-checkbox">
                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox ">
                          <input type="checkbox" name="name" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>

                        <div className="text-15 ml-10">Budget </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">92 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox ">
                          <input type="checkbox" name="name" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>

                        <div className="text-15 ml-10">Mid-range </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">45 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox ">
                          <input type="checkbox" name="name" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>

                        <div className="text-15 ml-10">Luxury </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">21 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox ">
                          <input type="checkbox" name="name" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>

                        <div className="text-15 ml-10">Family-friendly </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">78 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox ">
                          <input type="checkbox" name="name" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>

                        <div className="text-15 ml-10">Business </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">679 </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mapFilter-filter__item">
                <h5 className="text-18 fw-500 mb-10">Neighborhood </h5>
                <div className="sidebar-checkbox">
                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox ">
                          <input type="checkbox" name="name" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>

                        <div className="text-15 ml-10">Central London </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">92 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox ">
                          <input type="checkbox" name="name" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>

                        <div className="text-15 ml-10">
                          Guests&#39; favourite area{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">45 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox ">
                          <input type="checkbox" name="name" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>

                        <div className="text-15 ml-10">
                          Westminster Borough{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">21 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox ">
                          <input type="checkbox" name="name" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>

                        <div className="text-15 ml-10">
                          Kensington and Chelsea{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">78 </div>
                    </div>
                  </div>

                  <div className="row y-gap-10 items-center justify-between">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="form-checkbox ">
                          <input type="checkbox" name="name" />
                          <div className="form-checkbox__mark">
                            <div className="form-checkbox__icon icon-check"></div>
                          </div>
                        </div>

                        <div className="text-15 ml-10">Oxford Street </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-15 text-light-1">679 </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mapFilter-results scroll-bar-1">
            <div className="px-20 py-20 md:px-15 md:py-15">
              <div className="row y-gap-10 justify-between">
                <div className="col-auto">
                  <div className="text-14 text-light-1">
                    <span className="text-dark-1 text-15 fw-500">
                      3,269 properties{" "}
                    </span>
                    in Europe
                  </div>
                </div>

                <div className="col-auto">
                  <button className="button -blue-1 h-40 px-20 md:px-5 text-blue-1 bg-blue-1-05 rounded-100">
                    <i className="icon-up-down mr-10"></i>
                    Top picks for ____ search
                  </button>
                </div>
              </div>

              <div className="mapFilter-results__item">
                <div className="row x-gap-20 y-gap-20">
                  <div className="col-md-auto">
                    <div className="ratio ratio-1:1 size-120">
                      <img
                        src="titsolutions/img/hotels/1.png"
                        alt="image"
                        className="img-ratio rounded-4"
                      />
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="row x-gap-20 y-gap-10 justify-between">
                      <div className="col-lg">
                        <h4 className="text-16 lh-17 fw-500">
                          Great Northern _____, a Tribute Portfolio Hotel,
                          ______
                          <span className="text-10 text-yellow-2">
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                          </span>
                        </h4>
                      </div>

                      <div className="col-auto">
                        <button className="button -blue-1 size-30 flex-center bg-light-2 rounded-full">
                          <i className="icon-heart text-12"></i>
                        </button>
                      </div>
                    </div>

                    <div className="row y-gap-10 justify-between items-end pt-24 lg:pt-15">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="size-38 rounded-4 flex-center bg-blue-1">
                            <span className="text-14 fw-600 text-white">
                              4.8{" "}
                            </span>
                          </div>

                          <div className="ml-10">
                            <div className="text-13 lh-14 fw-500">
                              Exceptional{" "}
                            </div>
                            <div className="text-12 lh-14 text-light-1">
                              3,014 reviews{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="text-14 text-light-1 mr-10">
                            8 nights{" "}
                          </div>
                          <div className="fw-500">US$72 </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mapFilter-results__item">
                <div className="row x-gap-20 y-gap-20">
                  <div className="col-md-auto">
                    <div className="ratio ratio-1:1 size-120">
                      <img
                        src="titsolutions/img/hotels/1.png"
                        alt="image"
                        className="img-ratio rounded-4"
                      />
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="row x-gap-20 y-gap-10 justify-between">
                      <div className="col-lg">
                        <h4 className="text-16 lh-17 fw-500">
                          Great Northern _____, a Tribute Portfolio Hotel,
                          ______
                          <span className="text-10 text-yellow-2">
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                          </span>
                        </h4>
                      </div>

                      <div className="col-auto">
                        <button className="button -blue-1 size-30 flex-center bg-light-2 rounded-full">
                          <i className="icon-heart text-12"></i>
                        </button>
                      </div>
                    </div>

                    <div className="row y-gap-10 justify-between items-end pt-24 lg:pt-15">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="size-38 rounded-4 flex-center bg-blue-1">
                            <span className="text-14 fw-600 text-white">
                              4.8{" "}
                            </span>
                          </div>

                          <div className="ml-10">
                            <div className="text-13 lh-14 fw-500">
                              Exceptional{" "}
                            </div>
                            <div className="text-12 lh-14 text-light-1">
                              3,014 reviews{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="text-14 text-light-1 mr-10">
                            8 nights{" "}
                          </div>
                          <div className="fw-500">US$72 </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mapFilter-results__item">
                <div className="row x-gap-20 y-gap-20">
                  <div className="col-md-auto">
                    <div className="ratio ratio-1:1 size-120">
                      <img
                        src="titsolutions/img/hotels/1.png"
                        alt="image"
                        className="img-ratio rounded-4"
                      />
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="row x-gap-20 y-gap-10 justify-between">
                      <div className="col-lg">
                        <h4 className="text-16 lh-17 fw-500">
                          Great Northern _____, a Tribute Portfolio Hotel,
                          ______
                          <span className="text-10 text-yellow-2">
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                          </span>
                        </h4>
                      </div>

                      <div className="col-auto">
                        <button className="button -blue-1 size-30 flex-center bg-light-2 rounded-full">
                          <i className="icon-heart text-12"></i>
                        </button>
                      </div>
                    </div>

                    <div className="row y-gap-10 justify-between items-end pt-24 lg:pt-15">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="size-38 rounded-4 flex-center bg-blue-1">
                            <span className="text-14 fw-600 text-white">
                              4.8{" "}
                            </span>
                          </div>

                          <div className="ml-10">
                            <div className="text-13 lh-14 fw-500">
                              Exceptional{" "}
                            </div>
                            <div className="text-12 lh-14 text-light-1">
                              3,014 reviews{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="text-14 text-light-1 mr-10">
                            8 nights{" "}
                          </div>
                          <div className="fw-500">US$72 </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mapFilter-results__item">
                <div className="row x-gap-20 y-gap-20">
                  <div className="col-md-auto">
                    <div className="ratio ratio-1:1 size-120">
                      <img
                        src="titsolutions/img/hotels/1.png"
                        alt="image"
                        className="img-ratio rounded-4"
                      />
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="row x-gap-20 y-gap-10 justify-between">
                      <div className="col-lg">
                        <h4 className="text-16 lh-17 fw-500">
                          Great Northern _____, a Tribute Portfolio Hotel,
                          ______
                          <span className="text-10 text-yellow-2">
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                          </span>
                        </h4>
                      </div>

                      <div className="col-auto">
                        <button className="button -blue-1 size-30 flex-center bg-light-2 rounded-full">
                          <i className="icon-heart text-12"></i>
                        </button>
                      </div>
                    </div>

                    <div className="row y-gap-10 justify-between items-end pt-24 lg:pt-15">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="size-38 rounded-4 flex-center bg-blue-1">
                            <span className="text-14 fw-600 text-white">
                              4.8{" "}
                            </span>
                          </div>

                          <div className="ml-10">
                            <div className="text-13 lh-14 fw-500">
                              Exceptional{" "}
                            </div>
                            <div className="text-12 lh-14 text-light-1">
                              3,014 reviews{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="text-14 text-light-1 mr-10">
                            8 nights{" "}
                          </div>
                          <div className="fw-500">US$72 </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mapFilter-results__item">
                <div className="row x-gap-20 y-gap-20">
                  <div className="col-md-auto">
                    <div className="ratio ratio-1:1 size-120">
                      <img
                        src="titsolutions/img/hotels/1.png"
                        alt="image"
                        className="img-ratio rounded-4"
                      />
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="row x-gap-20 y-gap-10 justify-between">
                      <div className="col-lg">
                        <h4 className="text-16 lh-17 fw-500">
                          Great Northern _____, a Tribute Portfolio Hotel,
                          ______
                          <span className="text-10 text-yellow-2">
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                          </span>
                        </h4>
                      </div>

                      <div className="col-auto">
                        <button className="button -blue-1 size-30 flex-center bg-light-2 rounded-full">
                          <i className="icon-heart text-12"></i>
                        </button>
                      </div>
                    </div>

                    <div className="row y-gap-10 justify-between items-end pt-24 lg:pt-15">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="size-38 rounded-4 flex-center bg-blue-1">
                            <span className="text-14 fw-600 text-white">
                              4.8{" "}
                            </span>
                          </div>

                          <div className="ml-10">
                            <div className="text-13 lh-14 fw-500">
                              Exceptional{" "}
                            </div>
                            <div className="text-12 lh-14 text-light-1">
                              3,014 reviews{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="text-14 text-light-1 mr-10">
                            8 nights{" "}
                          </div>
                          <div className="fw-500">US$72 </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mapFilter-results__item">
                <div className="row x-gap-20 y-gap-20">
                  <div className="col-md-auto">
                    <div className="ratio ratio-1:1 size-120">
                      <img
                        src="titsolutions/img/hotels/1.png"
                        alt="image"
                        className="img-ratio rounded-4"
                      />
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="row x-gap-20 y-gap-10 justify-between">
                      <div className="col-lg">
                        <h4 className="text-16 lh-17 fw-500">
                          Great Northern _____, a Tribute Portfolio Hotel,
                          ______
                          <span className="text-10 text-yellow-2">
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                          </span>
                        </h4>
                      </div>

                      <div className="col-auto">
                        <button className="button -blue-1 size-30 flex-center bg-light-2 rounded-full">
                          <i className="icon-heart text-12"></i>
                        </button>
                      </div>
                    </div>

                    <div className="row y-gap-10 justify-between items-end pt-24 lg:pt-15">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="size-38 rounded-4 flex-center bg-blue-1">
                            <span className="text-14 fw-600 text-white">
                              4.8{" "}
                            </span>
                          </div>

                          <div className="ml-10">
                            <div className="text-13 lh-14 fw-500">
                              Exceptional{" "}
                            </div>
                            <div className="text-12 lh-14 text-light-1">
                              3,014 reviews{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="text-14 text-light-1 mr-10">
                            8 nights{" "}
                          </div>
                          <div className="fw-500">US$72 </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mapFilter-results__item">
                <div className="row x-gap-20 y-gap-20">
                  <div className="col-md-auto">
                    <div className="ratio ratio-1:1 size-120">
                      <img
                        src="titsolutions/img/hotels/1.png"
                        alt="image"
                        className="img-ratio rounded-4"
                      />
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="row x-gap-20 y-gap-10 justify-between">
                      <div className="col-lg">
                        <h4 className="text-16 lh-17 fw-500">
                          Great Northern _____, a Tribute Portfolio Hotel,
                          ______
                          <span className="text-10 text-yellow-2">
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                          </span>
                        </h4>
                      </div>

                      <div className="col-auto">
                        <button className="button -blue-1 size-30 flex-center bg-light-2 rounded-full">
                          <i className="icon-heart text-12"></i>
                        </button>
                      </div>
                    </div>

                    <div className="row y-gap-10 justify-between items-end pt-24 lg:pt-15">
                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="size-38 rounded-4 flex-center bg-blue-1">
                            <span className="text-14 fw-600 text-white">
                              4.8{" "}
                            </span>
                          </div>

                          <div className="ml-10">
                            <div className="text-13 lh-14 fw-500">
                              Exceptional{" "}
                            </div>
                            <div className="text-12 lh-14 text-light-1">
                              3,014 reviews{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="d-flex items-center">
                          <div className="text-14 text-light-1 mr-10">
                            8 nights{" "}
                          </div>
                          <div className="fw-500">US$72 </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mapFilter-map">
            {/* <div className="map js-map"></div> */}
            {/* <div
              ref={mapRef}
              className="map js-map"
              style={{ width: "100%", height: "100%" }}
            ></div> */}
            <img alt="" src="map-7days-NC.jpg" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingCruises;
